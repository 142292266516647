import {BasicPopout} from '@eitje/web_components'
import {Text} from 'common/components'
import {ManageTrainingButton, NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'

export const SendTrainingElement = () => {
	return (
		<Text
			t="send_training"
			name={NAMESPACE}
			popoutTitle={`${NAMESPACE}.send_training`}
			popoutBody={t(`${NAMESPACE}.send_training_explanation`)}
			PopoutComponent={SendTrainingPopout}
		/>
	)
}

const SendTrainingPopout = props => {
	return <BasicPopout buttons={<ManageTrainingButton />} {...props} />
}
