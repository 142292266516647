import {useQueryParams} from 'hooks'
import {Message} from 'common/components'
import {FilterActiveMessage} from 'cores/planning'
import {useOnboardingActive} from 'cores/onboarding'

export const PublishMessage = () => {
	const {filter_active} = useQueryParams()
	const onboardingActive = useOnboardingActive({role: 'manager'})

	if (onboardingActive) return <Message t="planning.publish_shifts.onboarding_message" />

	return <FilterActiveMessage active={filter_active} />
}
