import {BasicTable as _BasicTable} from 'common/components'
import {Link} from 'components/routing'
import {t} from 'initializers/i18n'
import {useQueryParams} from 'hooks'
import {columns} from './columns'
import {usePlaceholderProps} from './placeholder'
import {EmploymentType} from 'models'

export const BasicTable = ({users, isActive, kind, date, ...rest}) => {
	const {out_of_service} = useQueryParams()
	const empTypes = EmploymentType.all()
	const hasLeave = empTypes.some(e => e.hasLeave())
	const placeholderProps = usePlaceholderProps({kind, hasLeave})
	const extraName = out_of_service ? 'out_of_service' : 'in_service'

	const data = !hasLeave && kind == 'leave' ? [] : users

	return (
		<_BasicTable
			RowWrapper={({item, ...rest}) => <Link to={item.id} {...rest} />}
			layoutProps={props => props.item.active == 3 && {colorSet: 'disabled'}}
			tooltip={props => props.item.active == 3 && t('basic_tables.balance_index.inactive', {user: props.item})}
			date={date}
			endOfContentPlaceholder
			defaultSortField="active"
			placeholderProps={placeholderProps}
			{...rest} // name is passed as null (i think layout does that)
			name={[`balance_index.${kind}`, `balance_index.${extraName}`, 'balance_index']}
			data={isActive ? data : []}
			columns={columns}
		/>
	)
}
