import {Layout, goToNext} from 'common/components'
import {useModalContext} from 'components/common/index'
import {ModalForm} from 'components/form/index'
import {FormRow} from 'components/ui'
import {Switch} from '@eitje/form-fields-web'

export const ConnectionSettings = props => {
	const {item} = useModalContext()
	return (
		<ModalForm
			modalProps={{confirmSubmit: true}}
			transNamespace="integ_hr_settings"
			onSubmit={item.update}
			initialValues={item}
			afterSubmit={() => goToNext(props)}
		>
			<Layout width="full" direction="vertical">
				<FormRow>
					<Switch tooltip field="auto_remove_users" />
				</FormRow>
			</Layout>
		</ModalForm>
	)
}
