import {ListPicker} from 'common/components'
import {NewModalForm} from 'components/form/index'
import {NewFormInputNumber} from 'components/form/input_number'
import {Switch, FormRow} from '@eitje/form-fields-web'
import {listPickerPrettyPrint, prettyPrintArray} from 'helpers'
import {useTaxonomy} from 'hooks'
import {Allowance} from 'models'
import {useParams} from 'react-router-dom'
import {AllowanceMessage} from './message'
import {PayrollComponentPicker} from './components'
import {t} from 'initializers/i18n'
import utils from '@eitje/utils'

export const HolidayForm = ({allowances}) => {
	const allowance = allowances.find(a => a.kind == 'holiday') || {}
	const {environment_id} = useParams()

	return (
		<NewModalForm
			fieldProps={{tableName: 'allowances'}}
			onSubmit={data => (allowance.id ? allowance.update(data) : Allowance.create({...data, environment_id, kind: 'holiday'}))}
			name="allowances"
			initialValues={allowance}
		>
			<AllowanceMessage item={allowance} kind="holiday" />
			<Body allowance={allowance} />
		</NewModalForm>
	)
}

const Body = () => {
	const allowedHolidays = useTaxonomy('holidays')
	const holidayItems = allowedHolidays.map(holiday => ({
		id: holiday,
		name: t(`holidays.${utils.camelToSnake(holiday.toLowerCase())}`, holiday),
	}))
	return (
		<>
			<ListPicker minWidth={200} required noSort title={listPickerPrettyPrint} dropdown multiline field="holidays" items={holidayItems} />
			<FormRow>
				<NewFormInputNumber field="amount" max={5000} maxDigits={0} required />
				<Switch field="active" />
			</FormRow>
			<PayrollComponentPicker kind="allowances" />
		</>
	)
}
