import {ListPicker} from 'common/components'
import User from 'models/user'
import {Input, DatePicker, Switch, Checkbox, TimePicker, FormRow} from '@eitje/form-fields-web'
import {ModalWithNewForm} from 'components/form/index'

const Form = () => {
	return (
		<ModalWithNewForm name="test" onSubmit={data => window.alert('broertjee')}>
			<Inner />
		</ModalWithNewForm>
	)
}

const Inner = () => {
	const users = User.all()
	return (
		<>
			<Input required field="input" />
			<Input textarea required field="textarea" />
			<FormRow>
				<DatePicker field="date" />
				<ListPicker dropdown field="user_id" items={users} labelField="full_name" />
			</FormRow>
			<FormRow>
				<TimePicker field="time" />
				<TimePicker disabled label="disabled" field="time" />
				<TimePicker readOnly label="read only" field="time" tooltip="hihi" />
			</FormRow>
			<FormRow>
				<Input field="test2" />
				<Checkbox field="checkbox" />
				<Switch field="switch" />
			</FormRow>
		</>
	)
}

export default Form
