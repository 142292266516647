import {BasicTable as _BasicTable, Title} from 'common/components'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'
import {useColumns} from './columns'
import {RowWrapper} from './row_dropdown'
import {createResourceButtons} from '../create_resource_buttons'

export const ResourceBasicTable = () => {
	const columns = useColumns()
	const {title, resources, hasRole} = useTopicContext()

	const placeholderProps = hasRole && {
		title: {key: `placeholders.${NAMESPACE}.topic_no_articles.title`, opts: {title}},
		subtitle: false,
		animation: 'notebook',
		children: createResourceButtons,
	}

	const sectionHeader = hasRole && {
		left: <Title t="knowledge.resources" />,
		right: createResourceButtons,
	}

	const i18nOpts = {title}

	return (
		<_BasicTable
			name="topic_resources"
			RowWrapper={RowWrapper}
			placeholderProps={placeholderProps}
			data={resources}
			columns={columns}
			sectionHeader={sectionHeader}
			i18nOpts={i18nOpts}
			height="content"
		/>
	)
}
