import {AttachmentButton, EitjeButton, Title, PageHeader as _PageHeader} from 'common/components'
import {history} from 'index'
import {useTrainingContext, NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'

export const PageHeader = () => {
	const {title, activeResource, selectedAnswer, handleNext, testRun} = useTrainingContext()

	const isQuiz = activeResource.tableName === 'quizItems'
	const nextDisabled = isQuiz && selectedAnswer == null && t(`${NAMESPACE}.no_answer`)

	const attachments = activeResource.attachments?.map(({name, url}) => ({
		title: name,
		url,
	}))

	return (
		<_PageHeader
			className="training-header"
			type="floating"
			left={<Title>{title}</Title>}
			borderBottom={isQuiz}
			right={[
				testRun && (
					<EitjeButton
						name={NAMESPACE}
						t="exit_dry_run"
						iconLeft="arrow-undo"
						colorSet="color-bordered-fill"
						baseColor="orange"
						onClick={history.goBack}
					/>
				),
				<AttachmentButton attachments={attachments} />,
				<EitjeButton t="common.next" iconLeft="arrow-right" colorSet="solid" onClick={handleNext} disabled={nextDisabled} />,
			]}
		/>
	)
}
