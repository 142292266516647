// Idle detection configuration
// comment to test usage spike

const IDLE_THRESHOLD = 10 * 60 * 2000 // 20 minutes of inactivity
let idleTimer
let userIsIdle = false

// Version checking configuration
const VERSION_CHECK_INTERVAL = 10 * 60 * 2000 // Check every 20 minutes
const randomOffset = Math.floor(Math.random() * 60 * 10000) // up to 10 min offset

let currentVersion = process.env.REACT_APP_VERSION
let newVersionAvailable = false

function isAllowed(importantVersion) {
	if (importantVersion && currentVersion < importantVersion) return true
	return isAllowedTime()
}

function isAllowedTime() {
	const timeZone = 'Europe/Amsterdam'
	const now = new Date()
	const hour = Number(new Intl.DateTimeFormat('en-GB', {hour: 'numeric', hour12: false, timeZone}).format(now))
	const isAllowed = hour < 8 || hour >= 19
	return isAllowed
}

// Set up idle detection using event-driven approach
function resetIdleTimer() {
	clearTimeout(idleTimer)
	userIsIdle = false
	idleTimer = setTimeout(() => {
		userIsIdle = true
		// If user is idle and a new version is available, reload the page
		if (newVersionAvailable) {
			window.location.reload()
		}
	}, IDLE_THRESHOLD)
}

const throttledReset = _.throttle(resetIdleTimer, 5000)

// Listen to user activity events
document.addEventListener('mousemove', throttledReset, {passive: true})
document.addEventListener('keypress', throttledReset, {passive: true})
document.addEventListener('touchstart', throttledReset, {passive: true})

// Start the initial idle timer
resetIdleTimer()

// Function to check for a new version
async function checkForNewVersion() {
	try {
		const response = await fetch('/version.json', {cache: 'no-cache'})

		if (response.ok) {
			const data = await response.json()
			const fetchedVersion = data.version
			if (fetchedVersion && fetchedVersion > currentVersion && isAllowed(data.important_version)) {
				// New version is available
				newVersionAvailable = true
				// If the user is already idle when we detect a new version, reload immediately
				if (userIsIdle) {
					window.location.reload()
				}
			}
		}
	} catch (error) {
		console.error('Error fetching version.json:', error)
	}
}

setTimeout(() => {
	checkForNewVersion()
	setInterval(() => checkForNewVersion(), VERSION_CHECK_INTERVAL)
}, randomOffset)
