import {useTaxonomy} from 'hooks'
import {buildField} from '@eitje/form-fields-web'
import {PopoutCard} from '@eitje/web_components'
import {Layout} from 'common/components'

const inlineStyles = {borderRadius: '50%'}

const Field = ({value, formData, onVisibleChange, ...rest}) => {
	const initialValue = formData.color
	const color = value || initialValue

	// Set backgroundColor vs. initialBackground to maintain the team colour on hover.
	return (
		<PopoutCard onVisibleChange={onVisibleChange} trigger="click" body={<ColorOptionsPopout {...rest} />}>
			<Layout width={40} height={40} border borderRadius style={{...inlineStyles, backgroundColor: color}} />
		</PopoutCard>
	)
}

const ColorOptionsPopout = ({onChange}) => {
	const colors = useTaxonomy('team_colors')

	// Set backgroundColor vs. initialBackground to maintain the team colour on hover.
	return (
		<Layout gap={8}>
			{colors.map(color => (
				<Layout width={40} height={40} colorSet border borderRadius onClick={() => onChange(color)} style={{backgroundColor: color}} />
			))}
		</Layout>
	)
}

export const TeamColorPicker = buildField(Field, {className: 'eitje-team-color-picker', clearIcon: false, inputPosition: 'right'})
