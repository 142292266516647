import {t} from 'initializers/i18n'
import {EitjeButton, SubmitPopout} from 'common/components'
import {useTopicContext} from 'cores/knowledge'

export const PublishButton = () => {
	const {published, infos, environment_ids, ...topic} = useTopicContext()

	if (published) return null

	const unplublishedInfos = infos.filter(info => !info.published)
	const handlePublish = async () => await topic.publish()

	const buttonProps = {
		t: 'publish',
		iconLeft: 'eye-open',
		colorSet: 'color-bordered-fill',
		enableRole: 'manager',
		roleEnv: environment_ids,
	}

	if (!unplublishedInfos.length) return <EitjeButton {...buttonProps} onClick={handlePublish} />

	const handlePublishAll = async () => await topic.update({publish_articles: true, published: true})

	return (
		<SubmitPopout
			title="everything"
			text="publishAllTopicItems"
			onSubmit={handlePublishAll}
			submitText={t('everything')}
			cancelText={t('onlyTopic')}
			backButton={false}
			buttons={<EitjeButton t="onlyTopic" iconLeft="eye-open" onClick={handlePublish} />}
			placement="bottom-start"
			submitButtonProps={{iconLeft: 'eye-open'}}
		>
			<EitjeButton {...buttonProps} />
		</SubmitPopout>
	)
}
