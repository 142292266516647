import {BasicTable, Title} from 'common/components'
import {ManageTrainingButton, NAMESPACE, useTopicContext} from 'cores/knowledge'
import {TrainingOptionsButton} from './training_options_button'
import {columns} from './columns'
import {RowWrapper} from './row_dropdown'
import {useData} from './data'
import {usePlaceholder} from './placeholder'

export const TrainingBasicTable = () => {
	const {title, trainable} = useTopicContext()
	const placeholderProps = usePlaceholder()
	const data = useData()

	const sectionHeader = {
		left: <Title name={NAMESPACE} t="training_results" />,
		right: trainable && [<ManageTrainingButton colorSet="grey-outlined" />, <TrainingOptionsButton />],
	}

	const i18nOpts = {title}

	return (
		<BasicTable
			name="training_results"
			RowWrapper={RowWrapper}
			placeholderProps={placeholderProps}
			defaultSortField="user"
			data={data}
			columns={columns}
			sectionHeader={sectionHeader}
			i18nOpts={i18nOpts}
			height="content"
		/>
	)
}
