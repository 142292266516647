import {all, backend, API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {getRecords} from 'actions/launch'
import {navigateModal} from 'actions/routing'
import {navigate} from 'components/routing'
import {ConfigProvider} from 'antd'
import en from 'antd/lib/locale-provider/en_GB'
import nl from 'antd/lib/locale-provider/nl_NL'
import {ApplicationHeaders} from 'common/components'
import {randomTip} from 'constants/onboarding'
import {OneSignalConnector} from 'hooks/use_one_signal'
import {history} from 'index'
import {ENV} from 'initializers/api'
import I18n, {t} from 'initializers/i18n'
import initPusher from 'lib/pusher'
import configureSentry from 'lib/sentry'
import React, {Component, Fragment} from 'react'
import ReactGA from 'react-ga'
import {connect, useSelector} from 'react-redux'
import {envSelector} from 'selectors/entities'
import {legacyRolesSelector} from 'selectors/legacy'
import {roleSelector} from 'selectors/new_settings'
import {currentOrgSelector, inEnv} from 'selectors/records'
import {getEnvSetting} from 'selectors/settings'
import 'styles/legacy/loading.css'
import {Expired} from 'cores/billing/components'
import * as Sentry from '@sentry/browser'
import useOrgLocked from 'cores/billing/hooks/use_org_locked'
import {StreamChatProvider} from 'cores/chat'
import IntercomRegistrar from 'lib/intercom'
import HeapRegistrar from 'lib/heap'
import {CannyRegistrar} from 'lib/canny'
import {useLocation} from 'react-router-dom'
import {useBodyHotkey, useFilter, setFilter, useArbitrary} from 'hooks'
import {setArbitrary} from 'actions'
import {isProd} from 'constants/general'
import {getUserSetting} from 'selectors/new_settings'
import {changeLocale} from 'actions/settings'
import {isMobile} from 'helpers'
import MobileBlocker from 'pages/system/mobile_blocker'
import {DevFooter} from 'cores/dev/dev_footer'
const isMob = isMobile()
import {NoEnvironment} from 'cores/auth/pages/no_environment/index.js'

const NO_APPLICATION_HEADERS_URL = ['/user_info', '/no_environment', '/billing/expired', '/no_internet_connection', '/server_down']

class AuthProvider extends Component {
	constructor(props) {
		super(props)
		this.state = {
			done: this.done(),
			onbDrawerV: false,
			noEnv: false,
			randomItem: randomTip(),
		}
	}

	componentDidMount() {
		const {user, env, inrichtingModus} = this.props
		if (user.id) {
			this.getStatus()
			ENV === 'production' && ReactGA.set({userId: user.id})
			this.setWindowVars()
		}
	}

	setWindowVars = () => {
		const {users, envId} = this.props
		window.envId = envId
	}

	componentDidUpdate = async (prevProps, prevState) => {
		const {environments, user, envTeams, envUsers, roles, orgLocked} = this.props
		const authed = !prevProps.user.id && this.props.user.id
		const newTrial = !prevProps.createdTrialEnvironment && this.props.createdTrialEnvironment
		if (prevProps.user.id && !this.props.user.id) {
			this.setState({done: false, noEnv: false})
		}
		if (authed || newTrial) {
			this.getStatus()
			this.setWindowVars()
			ENV === 'production' && ReactGA.set({userId: user.id})
		}
		if (prevProps.envId && this.props.envId && prevProps.envId != this.props.envId) {
			this.setState({done: false, randomItem: randomTip()})

			// this.initEnv()
			// await this.fetchData({ wait: true }) // we need roles & settings be4 rendering
			await this.initApp()
			this.setState({done: true})
		}
	}

	done = () => {
		const {envs = [], teams = []} = this.props
		if (!this.state) return false
		return this.state.noEnv || (this.state.done && envs.length > 0 && teams.length > 0)
	}

	envCheck = async () => {
		const {envs} = this.props

		const res = await API.index('environments') // we need envs for rest

		if (res.status <= 304) {
			this.proceed(envs)
		} else if (res.status > 401) {
			utils.errNotif(t('oops'), t('somethingWentWrong'))
			this.setState({error: true})
		}
	}

	getStatus = async () => {
		this.setState({userStatus: null})

		const res = await backend.get(`/users/${this.props.user.id}/user_status`)
		const serverDown = !res.data && res.problem === 'NETWORK_ERROR'

		if (serverDown) {
			return navigate('/server_down')
		}

		if (!res.data) {
			const prettyResponse = JSON.stringify(res, null, 2)
			Sentry.captureMessage(`Res.data can't be destructured. Problem: ${res.problem}. Res: ${prettyResponse}.`)
			return navigate('/server_down')
		}

		if (res.status <= 304) {
			const {user_status} = res.data
			this.setState({userStatus: user_status})
			if (user_status === 'authed') {
				this.envCheck()
			} else if (user_status === 'deleted') {
				this.setState({noEnv: true})
				return history.push(`/no_environment`)
			} else {
				return history.replace('/create_environment')
			}
		} else if (res.status > 401) {
			utils.errNotif(t('oops'), t('somethingWentWrong'))
			this.setState({error: true})
		}
	}

	proceed = async prevEnvs => {
		const {envs, envId, dispatch, user} = this.props
		const envIds = envs.map(x => x.id)
		const prevEnvIds = prevEnvs.map(x => x.id)
		if (!envId || !envIds.includes(envId)) {
			await dispatch({type: 'SET_ACTIVE_ENV', env: envIds[0], envSwitch: true})
			await setTimeout(() => {}, 20)
		}

		if (!_.isEqual(envIds, prevEnvIds)) {
			dispatch({type: 'RESET_RECORDS', keep: ['environments']})
			await setTimeout(() => {}, 20)
		} // uve been deleted or added to an env, time for a reset

		if (!isMob) {
			this.initApp()
		}
	}

	initApp = async () => {
		const {user, users, dispatch, loading} = this.props
		await this.fetchData()
		if (loading) {
			dispatch({type: 'STOP_LOADING'})
		}
		this.initEnv()
	}

	fetchData = async () => {
		await getRecords()
		const {envId, roles, envTeams, user, org} = this.props
		initPusher(envId, roles, envTeams, user.id, org.id)
		this.finishLoading()
	}

	finishLoading() {
		const {envTeams, envId, roles, needsLightspeedMigration, orgLocked} = this.props
		this.setState({done: true})
	}

	initEnv = async () => {
		const {onboardingSteps, hasResetFilter, inrichtingModus, org, planningFilter, env, redUrl, user, envId} = this.props
		const allVals = _.values(onboardingSteps)
		configureSentry(user, envId)
		if (redUrl) {
			history.push(redUrl)
			this.props.dispatch({type: 'CLEAR_REDIRECTED'})
		}

		if (!hasResetFilter && env?.id && org.active_environment_ids?.length > 1 && !utils.exists(planningFilter)) {
			const name = `planningEnvs-${env.organisation_id}`
			setFilter(name, [env.id])
			setArbitrary(name, true)
		}
	}

	renderChildren() {
		const {onbDrawerV, noEnv} = this.state
		const {children, roles, env = {}, isManager, onboardingSteps = {}} = this.props
		const allVals = _.values(onboardingSteps)
		const {pathname} = window.location
		const showAppHeaders = !NO_APPLICATION_HEADERS_URL.includes(pathname)

		return (
			<Fragment>
				<StreamChatProvider>
					<OneSignalConnector />
					{showAppHeaders && !noEnv && <ApplicationHeaders />}
					<div>
						{noEnv ? <NoEnvironment skipRedirect={true} /> : children}
						{!isProd && <DevFooter />}
					</div>
				</StreamChatProvider>
				<Expired />
			</Fragment>
		)
	}

	render() {
		const {children, location, env, user, userSettings = {}, skills} = this.props
		const {locale} = userSettings
		const {randomItem, userStatus} = this.state
		const done = this.done()
		const isAuth = !!user.id && (userStatus == 'authed' || userStatus == 'deleted')
		const isModal = location.state?.background
		const className = utils.makeCns(isModal && 'modal-active')
		const blockMobile = env?.id && isMob

		return (
			<ConfigProvider locale={locale === 'en' ? en : nl}>
				<div className={className} id={done || !isAuth ? 'currently_loaded_page' : 'currently_loading_page'}>
					{blockMobile ? (
						<MobileBlocker />
					) : isAuth && done && children ? (
						this.renderChildren()
					) : (
						isAuth && <LoadingScreen randomItem={randomItem} locale={locale} />
					)}
				</div>
				<IntercomRegistrar />
				<HeapRegistrar />
				<CannyRegistrar />
				{!isProd && <TranslationHotkeyManager />}
			</ConfigProvider>
		)
	}
}

const lngs = ['en', 'nl']

const LoadingScreen = ({randomItem, locale}) => {
	return (
		<div className="fCol aCen jCen" style={{height: '100vh'}}>
			<img style={{position: 'absolute', left: 0, bottom: 0, height: '50vh'}} src="/images/blueLoading.png" />
			<img style={{position: 'absolute', right: 0, top: 0, height: '100vh'}} src="/images/yellowLoading.png" />

			<p className="loadingTip loading" style={{color: '#2b2b2b'}}>
				loading
			</p>

			<p className="loadingTitle">
				{t('tipHeader1')}
				<br />
				{t('tipHeader2')}
			</p>
			<p className="loadingTip">{randomItem.title}</p>

			<a
				href={
					locale === 'en' ? 'https://intercom.help/eitje/nl/' + randomItem.linkEN : 'https://intercom.help/eitje/nl/' + randomItem.linkNL
				}
				target="blank"
			>
				<div className="fRow aCen jCen" style={{backgroundColor: '#2b2b2b', width: 80, height: 80, borderRadius: '50%'}}>
					<img style={{width: 28}} src="/images/learnMore.png" />
				</div>
			</a>
			<p className="loadingMore">{t('tipLearnMore')}</p>
		</div>
	)
}

const TranslationHotkeyManager = () => {
	const locale = useSelector(state => getUserSetting(state, 'locale')) || I18n?.language
	const nextLocale = lngs.find(l => locale != l)
	useBodyHotkey({ctrl_shift_l: () => changeLocale(nextLocale)})
	return null
}

AuthProvider = connect(state => ({
	envs: all(state, 'environments'),
	envTeams: inEnv(state, 'teams'),
	envUsers: inEnv(state, 'users'),
	teams: all(state, 'teams'),
	user: state.auth.user,
	envId: state.environment.active,
	users: all(state, 'users'),
	redUrl: state.environment.redUrl,
	userSettings: state.settings.user,
	skills: inEnv(state, 'skills'),
	roles: legacyRolesSelector(state),
	env: envSelector(state),
	inrichtingModus: getEnvSetting(state, 'onboarding', 'active'),
	onboardingSteps: state.settings.taxonomy?.onboarding_steps,
	loading: state.general.loading,
	isManager: roleSelector(state, 'manager'),
	org: currentOrgSelector(state),
	createdTrialEnvironment: state.arbitrary.createdTrialEnvironment,
}))(AuthProvider)

const withOrgLocked = Component => props => {
	const orgLocked = useOrgLocked()
	const loc = useLocation()
	const env = useSelector(envSelector)
	const filterName = `planningEnvs-${env?.organisation_id}`
	const hasResetFilter = useArbitrary(filterName)
	const planningFilter = useFilter(filterName)
	return <Component planningFilter={planningFilter} hasResetFilter={hasResetFilter} orgLocked={orgLocked} location={loc} {...props} />
}

export default withOrgLocked(AuthProvider)
