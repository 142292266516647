import {EitjeButton, EitjeDropdown, Text, DeletePopout} from 'common/components'
import {ModalLink, navigate} from 'components/routing'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'

export const OptionsButton = () => {
	const {id, published, publish, unpublish, destroy} = useTopicContext()

	const onDelete = async () => {
		const res = await destroy()
		if (res.ok) navigate('/topics')
	}

	const elements = [
		{
			icon: 'pencil',
			element: <ModalLink to={`/topics/${id}/edit`} t={{key: 'common.edit_type', type: `${NAMESPACE}.topic`}} />,
		},
		{
			icon: published ? 'eye-closed' : 'eye-open',
			element: <Text t={published ? 'unpublish' : 'publish'} />,
			onClick: published ? unpublish : publish,
		},
		{
			Popout: DeletePopout,
			popoutProps: {onDelete, placement: 'right-start', trigger: 'hover'},
			trigger: {
				icon: 'trash',
				element: <Text t="delete" />,
			},
		},
	]

	return (
		<EitjeDropdown elements={elements} trigger="click" placement="bottom-start">
			<EitjeButton t="common.options" iconLeft="three-sliders" />
		</EitjeDropdown>
	)
}
