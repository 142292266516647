import {Layout, ListPicker, Message, goToNext} from 'common/components'
import {useModalContext} from 'components/common/index'
import {CompositeField, ModalForm} from 'components/form/index'
import {FormRow} from 'components/ui'
import {useFormData, useShared} from 'hooks'
import {t} from 'initializers/i18n'
import {ExtEnvironment} from 'models'

const MapEnvs = props => {
	const {item} = useModalContext()
	const {orgEnvs} = useShared()
	const extEnvs = ExtEnvironment.where(item.ext_environment_ids)
	const sharedProps = {
		items: extEnvs.map(e => ({...e, name: e.name || t('common.external_environment')})),
		envs: orgEnvs,
		title: t('modals.setup_hr.map_envs.ext_name'),
	}

	let initial = orgEnvs.map(e => ({
		id: e.id,
		integration_ext_environment_ids: extEnvs.filter(e2 => e2.environment_ids.includes(e.id))._map('id'),
	}))

	const inactiveIds = extEnvs._filter('inactive')._map('id')
	const submit = data => {
		const {inactive_ids = [], environments} = data
		const envs = environments.map(e => ({...e, integration_ext_environment_ids: [e.integration_ext_environment_ids].flat()}))
		const items = extEnvs.map(e => ({
			id: e.id,
			inactive: inactive_ids.includes(e.id),
			name: e.name,
			environment_ids: envs.filter(env => env?.integration_ext_environment_ids?.includes(e.id))._map('id'),
		}))

		return ExtEnvironment.multiUpdate(items)
	}

	return (
		<ModalForm
			onSubmit={submit}
			afterSubmit={() => goToNext(props)}
			submitInitialValues
			transNamespace="integ_hr_map_envs"
			initialValues={{environments: initial, inactive_ids: inactiveIds}}
			modalProps={props.modalProps}
		>
			<Layout direction="vertical" name="modals.setup_hr.map_envs" width="full">
				<Message t={{key: 'message', integ_name: item.name}} />
				<CompositeField fields={['integration_ext_environment_ids', 'id']} compositeName="environments">
					<CompositeDropdownListSelect item={item} {...sharedProps} />
				</CompositeField>
				<FormRow>
					<InactivePicker {...sharedProps} />
				</FormRow>
			</Layout>
		</ModalForm>
	)
}

const InactivePicker = props => {
	const {environments} = useFormData()
	const mappedIds = environments._map('integration_ext_environment_ids').flat()
	const alreadyIntegratedMsg = t('modals.setup_hr.map_envs.already_integrated')
	const itemDisabled = item => mappedIds.includes(item.id) && alreadyIntegratedMsg
	return <ListPicker dropdown field="inactive_ids" itemDisabled={itemDisabled} label={t('common.inactive')} {...props} />
}

const CompositeDropdownListSelect = ({number, item, envs, ...props}) => {
	const alreadyIntegratedMsg = t('modals.setup_hr.map_envs.already_integrated')

	const {inactive_ids = [], environments} = useFormData()
	const env = envs[number - 1]
	const otherEnvs = environments.filter(r => number != environments.indexOf(r) + 1)
	const otherIds = otherEnvs._map('integration_ext_environment_ids').flat()
	const itemDisabled = item => {
		if (inactive_ids.includes(item.id)) return alreadyIntegratedMsg
		if (otherIds.includes(item.id)) return alreadyIntegratedMsg
	}

	const single = item.envs?.single
	return (
		<ListPicker
			dropdown
			single={single}
			label={env.naam}
			{...props}
			hideClearIcon={!single}
			itemDisabled={itemDisabled}
			field={`integration_ext_environment_ids-${number}`}
		/>
	)
}

export default MapEnvs
