import ActiveRecord from 'models/active_record/base'
import {API, backend} from '@eitje/easy_api'
import webUtils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {store} from 'index'
import {NAMESPACE} from 'cores/knowledge'

class Topic extends ActiveRecord {
	static tableName = 'topics'

	static async trainingOverviewIndex() {
		const res = await backend.get('topics/training_overview')

		const {items = []} = res.data || {}
		const topics = store.getState().records.topics || []
		const existingItems = items.filter(item => topics.some(topic => item.id === topic.id))
		API.createMultiLocal('topics', existingItems)
	}

	static async removeTopicResource(topicResourceId) {
		return await API.destroy('topic_resources', topicResourceId)
	}

	async publish() {
		return await super.update({published: true})
	}

	async unpublish() {
		return await super.update({published: false})
	}

	async fetchTrainingOverview() {
		await this.resourceReq('training_overview', {method: 'get'})
	}

	async createTopicPrompt(user) {
		const res = await API.create('user_topic_prompt', {topic_id: this.id, user_id: user.id}, {extraParams: {skipSuccessNotif: true}})

		if (res.ok) {
			await this.fetchTrainingOverview()
			webUtils.successNotif({
				title: t(`${NAMESPACE}.topic_prompt_success_message`, {name: user.full_name}),
				position: 'center',
			})
		}

		return res
	}

	async _moveResource(index, resources, direction) {
		const newIndex = index + direction
		if (newIndex < 0 || newIndex >= resources.length) return

		const newResources = _.clone(resources)
		;[newResources[index], newResources[newIndex]] = [newResources[newIndex], newResources[index]]
		const items = newResources.map((r, i) => ({id: r.topicResourceId, order: i}))
		return await API.updateMulti('topic_resources', items)
	}

	async moveResourceUp(index, resources) {
		return this._moveResource(index, resources, -1)
	}

	async moveResourceDown(index, resources) {
		return this._moveResource(index, resources, 1)
	}

	trainingType() {
		const {always_trainable, valid_untill, days_to_start} = this

		if (always_trainable) return 'always_trainable'
		if (valid_untill) return 'valid_untill'
		if (days_to_start != null) return 'days_to_start'

		return null
	}
}

export default Topic
