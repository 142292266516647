import {useState, useEffect} from 'react'

export const useHeaderHeight = (ref, enabled) => {
	const [height, setHeight] = useState(0)

	useEffect(() => {
		if (!ref.current || !enabled) return

		const updateHeight = () => {
			setHeight(ref.current.getBoundingClientRect().height)
		}

		updateHeight()

		// adding a resize observer, since the header height can change due to user input
		// for example when viewing a future date in the balance pages.
		const resizeObserver = new ResizeObserver(updateHeight)
		resizeObserver.observe(ref.current)

		return () => {
			resizeObserver.disconnect()
		}
	}, [ref, enabled])

	return height
}
