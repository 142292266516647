import ActiveRecord from 'models/active_record/base'

class RevenueTeam extends ActiveRecord {
	static tableName = 'omzetTeams'
	static defaultJoins = ['omzetGroepen', 'teams']

	actualFract() {
		if (!this.team.revenue_active) return 0
		return this.fract
	}
}

export default RevenueTeam
