import {makeCnVariants} from 'helpers'
import {BasicPopout} from '@eitje/web_components'
import {Layout, InfoPopout} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'
import {isFunction, stopLink} from 'helpers'
import {BooleanCell, DateCell, Cell} from './cells'

const getCell = cell => {
	if (isFunction(cell)) return cell

	switch (cell) {
		case 'boolean':
			return BooleanCell
		case 'date':
			return DateCell
		default:
			return Cell
	}
}

const inlineStyles = {flex: 1, minWidth: 0}

export const BasicTableCell = ({item, column, itemIndex}) => {
	const {withBorders, ...rest} = useBasicTableContext()
	const classNames = makeCnVariants('basic-table-row-cell', withBorders && 'with-borders')
	const {cell, accessorKey, accessorFn, popoutPropsFn} = column
	const value = accessorKey ? item[accessorKey] : accessorFn(item, itemIndex)

	const Cell = getCell(cell)
	const popoutProps = popoutPropsFn?.(item, rest)

	return (
		<Layout height="100%" style={inlineStyles} padding="0 16" className={classNames}>
			<Cell {...rest} column={column} item={item} value={value} />
			{popoutProps && <InfoPopout onClick={stopLink} PopoutComponent={BasicPopout} {...popoutProps} />}
		</Layout>
	)
}
