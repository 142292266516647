import {prettyPrintArray} from 'helpers'

const groupByEnvironmentId = array => {
	const grouped = _.groupBy(array, 'environment_id')

	return Object.values(grouped).map(teams => {
		// using first team for env info, since all teams in group have same env
		const {id, naam} = teams[0]?.environment || {}

		return {
			id,
			name: naam,
			teams: teams._map('naam'),
		}
	})
}

export const prettyPrintTeamArray = ({teams, group}) => {
	const prettyPrintProps = {quotes: false, unique: true, joinWord: '&', sort: true}

	if (!group) {
		return prettyPrintArray({
			array: teams.map(({naam}) => naam.capitalize()),
			...prettyPrintProps,
		})
	}

	const environmentGroups = groupByEnvironmentId(teams)
	const sortedEnvGroups = _.sortBy(environmentGroups, 'name')

	return sortedEnvGroups
		.map(({name, teams}) => {
			const capitalizedName = name.capitalize()
			const joinedTeams = prettyPrintArray({array: teams.map(team => team.capitalize()), ...prettyPrintProps})

			return `${capitalizedName}: ${joinedTeams}`
		})
		.join('; ')
}
