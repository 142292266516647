import {Input} from '@eitje/form-fields-web'
import {ListPicker} from 'common/components'
import {NewModalForm} from 'components/form/new_modal_form'
import {AvatarPicker} from 'lib/form/fields/avatar_picker'
import {t} from 'initializers/i18n'
import {useActiveUsersWithoutMe} from 'cores/chat'

export const GroupChatForm = props => {
	const users = useActiveUsersWithoutMe()

	return (
		<NewModalForm {...props}>
			<Input field="name" required />
			<ListPicker title={t('common.team_members')} field="user_ids" items={users} dropdown required labelField="full_name" />
			<AvatarPicker field="remote_avatar_url" />
		</NewModalForm>
	)
}
