import {useState, useCallback} from 'react'
import {Icon} from '@eitje/web_components'
import {Layout} from 'common/components'
import {t} from 'initializers/i18n'

const iconSize = 16

export const SearchInput = ({onChange, onClear, value, placeholder = t('search')}) => {
	const [hasFocus, setHasFocus] = useState()

	const handleFocus = useCallback(() => setHasFocus(true), [])
	const handleBlur = useCallback(() => setHasFocus(false), [])

	const isActive = hasFocus || value

	return (
		<Layout className="search-input" active={isActive} colorSet="grey-bordered" hasHover border borderRadius grow>
			<Icon name="magnifying-glass" size={iconSize} />
			<input
				onChange={onChange}
				onBlur={handleBlur}
				onFocus={handleFocus}
				autoComplete="off"
				value={value || ''}
				placeholder={placeholder}
			/>
			{value && <Icon onClick={onClear} name="cross-circled" size={iconSize} />}
		</Layout>
	)
}
