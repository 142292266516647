import {DocsPopout, EitjeButton} from 'common/components'
import {KNOWLEDGE_DOCS, NAMESPACE} from 'cores/knowledge'

export const TypePopout = props => {
	return <DocsPopout docsId={KNOWLEDGE_DOCS.write_article} {...props} />
}

export const TopicPopout = props => {
	const buttons = [<EitjeButton iconLeft="external-link" t={`${NAMESPACE}.view_topics`} link="/topics" />]
	return <DocsPopout docsId={KNOWLEDGE_DOCS.create_topic} buttons={buttons} {...props} />
}
