import {useEffect} from 'react'
import {RichTextEditor, PdfViewer} from 'common/components'
import {useArticleContext} from 'cores/knowledge'

export const Article = ({onSave, dropdownItems = []}) => {
	const {article, editDisabled, isPDF, isRTE} = useArticleContext()
	const {id, content, pdf_content, updated_at, updateReadCount} = article

	useEffect(() => {
		updateReadCount()
	}, [id])

	if (isRTE) {
		return (
			<RichTextEditor
				key={`${id}-${editDisabled}`} // using key to force a rerender when the route changes
				initialContent={content}
				readOnly={!!editDisabled}
				onSave={onSave}
				updatedAt={updated_at}
			/>
		)
	}

	if (isPDF) {
		return <PdfViewer pdf={pdf_content} dropdownItems={dropdownItems} />
	}
}
