import {createContext, useContext} from 'react'
import {useParams, useRoleTeams} from 'hooks'
import {navigate} from 'components/routing'
import {useTopicWithResources} from 'cores/knowledge'

const TopicContext = createContext()
const {Provider} = TopicContext

export const TopicProvider = ({children}) => {
	const {id} = useParams()
	const topic = useTopicWithResources(id)
	const roleTeams = useRoleTeams('manager')
	const teamIds = roleTeams.ids()

	if (!topic) return navigate('/topics')

	const {team_ids, createTopicPrompt: topicCreateTopicPrompt} = topic || {}
	const hasRole = team_ids?.some(teamId => teamIds.includes(teamId))
	const createTopicPrompt = topicCreateTopicPrompt && _.throttle(topicCreateTopicPrompt, 400)
	const value = {hasRole, team_ids, createTopicPrompt, ...topic}

	return <Provider value={value} children={children} />
}

export const useTopicContext = () => useContext(TopicContext)
