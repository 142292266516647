import {ProtectedRoute} from 'components/private_route'
import {ContractSettings} from './pages/contract_settings'

import {TimeSettings} from 'cores/settings/pages/index'
import {Roles} from './pages/roles'
import {MealsIndex} from './pages/meals'
import {NewMeal} from './pages/meals/new'
import {ContractTypes, EmploymentTypes} from './pages/employment_types'

const settingsBackgroundRoutes = [
	<ProtectedRoute path="/settings/time" role="admin" component={TimeSettings} />,
	<ProtectedRoute path="/settings/contract" role="admin" component={ContractSettings} />,
	<ProtectedRoute path="/settings/roles" roleInAnyEnv="admin" component={Roles} />,
	<ProtectedRoute path="/settings/meals/new" roleInAnyEnv="admin" component={NewMeal} />,
	<ProtectedRoute path="/settings/meals" roleInAnyEnv="admin" component={MealsIndex} />,
	<ProtectedRoute path="/environments/:environment_id/employment_types" roleInAnyEnv="admin" component={EmploymentTypes} />,
]

export {settingsBackgroundRoutes}
