import {useRef} from 'react'
import {Layout, EitjeVirtuoso, Placeholder} from 'common/components'
import {UserListItem} from './user_list_item'

const layoutProps = {
	direction: 'vertical',
	gap: 0,
	width: 'full',
}

const VirtualUserList = ({users, onItemClick}) => {
	const ref = useRef()

	return (
		<Layout {...layoutProps} height={400}>
			<EitjeVirtuoso
				ref={ref}
				data={users}
				style={{width: '100%'}}
				itemContent={(index, user) => (
					<UserListItem key={user.id} user={user} borderBottom={index !== users.length - 1} onClick={onItemClick} />
				)}
			/>
		</Layout>
	)
}

export const UserList = ({users, onItemClick}) => {
	const usersLength = users.length
	const virtual = usersLength > 20

	if (!usersLength)
		return (
			<Layout horizontal="center">
				<Placeholder animation="waving-people" title="noAvailableUsers" subtitle={false} />
			</Layout>
		)

	if (virtual) return <VirtualUserList users={users} onItemClick={onItemClick} />

	return (
		<Layout {...layoutProps}>
			{users.map((user, index) => (
				<UserListItem key={user.id} user={user} borderBottom={index !== usersLength - 1} onClick={onItemClick} />
			))}
		</Layout>
	)
}
