import {Text} from 'common/components'
import {useParams, useRoleEnvs} from 'hooks'
import {EnvRouteSelect} from 'shared/components/env_route_select'
import {useLocation} from 'react-router-dom'

export const ModalEnvHeader = ({role = 'admin'}) => {
	const {environment_id} = useParams()
	const loc = useLocation()
	const urlFormat = loc.pathname.replace(environment_id, ':envId')
	const envs = useRoleEnvs(role)
	if (envs.length < 2) return null
	return (
		<>
			<Text gap={0} className="unset-text-transform" fontSize={18} bold>
				in:
			</Text>
			<EnvRouteSelect modal urlFormat={urlFormat} shouldTranslate={false} envId={environment_id} role="admin" />
		</>
	)
}
