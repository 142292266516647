import {useOnboardingStatus} from '.'
import {useShared, useHasRole} from 'hooks'
import {date} from 'initializers/date'
import {onboardingRole} from 'cores/onboarding'

const DAYS_IN_ONBOARDING = 30

// Use this hook to determine what the landing page for the user is, and whether to show the prominent onboarding header
// item or not.

export const useOnboardingActive = ({role} = {}) => {
	const {percentageCompleted} = useOnboardingStatus()
	const {org} = useShared()
	const createdAt = date(org.created_at)
	const thresholdDate = date().subtract(DAYS_IN_ONBOARDING, 'days')
	const isProspect = createdAt.isAfter(thresholdDate)
	const hasRole = useHasRole(role || onboardingRole)

	// Not every user is able to complete the steps, since many actions depend on roles. Thus disable onboarding.
	if (!hasRole) return false

	// Always prefer the onboarding page when the organisation is still in its onboarding (first 28 days).
	if (isProspect) return true

	// When the onboarding steps haven't been completed, we always want to prompt them... Unless people simply don't
	// use the time registration (below 80% completion), because they opted for our cheapest subscription.
	if (percentageCompleted < 80) return true

	return false
}
