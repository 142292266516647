import {BasicTable, useFilterFooterContext, usePageContext} from 'common/components'
import {Link} from 'components/routing'

const RowWrapper = ({item, ...rest}) => <Link to={item.id} {...rest} />

export const KnowledgeBasicTable = props => {
	const {name} = usePageContext()
	const {filteredItems} = useFilterFooterContext()

	return <BasicTable name={`${name}_index`} RowWrapper={RowWrapper} data={filteredItems} defaultSortField="title" {...props} />
}
