import {useMemo} from 'react'
import {CreateButton} from 'common/components'
import {NAMESPACE, KNOWLEDGE_DOCS} from 'cores/knowledge'
import {KnowledgePage} from '../components'
import {Info} from 'models'
import {useHasRole} from 'hooks'
import {useColumns} from './columns'
import {prettyPrintArray} from 'helpers'

export const ArticlesPage = () => {
	const hasManagerRole = useHasRole('manager')
	const columns = useColumns()
	Info.doIndex() // ensure fresh infos are in the store on page load
	const infos = Info.all({joins: ['topics']})

	const items = useMemo(
		() =>
			infos.map(({topics = [], ...article}) => {
				const topicTitles = topics._map('title')
				const topicArray = prettyPrintArray({array: topicTitles, joinWord: '&', quotes: false}) || '-'

				return {...article, topics: topicArray}
			}),
		[infos],
	)

	const placeholderProps = {
		name: `${NAMESPACE}.no_articles`,
		animation: 'notebook',
		subtitle: hasManagerRole,
		...(hasManagerRole && {
			docsId: KNOWLEDGE_DOCS.collection,
			children: <CreateButton modalLink="/articles/new" name={NAMESPACE} t="create_article.title" />,
		}),
	}

	return (
		<KnowledgePage
			name="articles"
			items={items}
			createLabel={`${NAMESPACE}.create_article.title`}
			columns={columns}
			placeholderProps={placeholderProps}
		/>
	)
}
