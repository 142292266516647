import {CreateButton, NewSideMenuModal} from 'common/components'
import {useGet, useParams} from 'hooks'
import {RegularForm} from './regular'
import {IrregularForm} from './irregular'
import {HolidayForm} from './holiday'
import {Allowance} from 'models'
import {useModalContext} from 'components/ui'
import {Placeholder} from 'common/components'
import {ModalEnvHeader} from 'components/common/modal/env_header'
import {Audits} from 'components/shared'
import {useLocation} from 'react-router-dom'
import {t} from 'initializers/i18n'
import utils from '@eitje/utils'

export const AllowanceIndex = () => {
	const {environment_id} = useParams()
	const allowances = Allowance.where({environment_id})
	const regularAllowances = allowances.filter(a => a.isFunction())
	const irregularAllowances = allowances.filter(a => a.isIrregular())

	const loc = useLocation()
	let allowance

	if (loc.pathname.includes('holiday')) {
		allowance = allowances.find(a => a.kind == 'holiday')
	} else {
		allowance = allowances.find(a => a.id == loc.pathname.split('/').filter(Boolean).last())
	}

	const routes = [
		{
			name: 'regular',
			component: regularAllowances.length == 0 && RegularPlaceholder,
			subRoutes: recordMap(regularAllowances, {component: RegularForm}),
		},
		{
			name: 'irregular',
			popoutTitle: t(`modals.allowances.sidemenu.irregular`),
			popoutBody: t(`modals.allowances.sidemenu.irregular_popout_body`),
			component: irregularAllowances.length == 0 && IrregularPlaceholder,
			subRoutes: recordMap(irregularAllowances, {component: IrregularForm}),
		},
		{
			name: 'holiday',
			allowances,
			key: environment_id,
			component: HolidayForm,
		},
	]

	return (
		<NewSideMenuModal
			contextProps={{allowance, environment_id}}
			LeftFooter={LeftFooter}
			LeftHeader={ModalEnvHeader}
			width={650}
			height={500}
			name="allowances"
			routes={routes}
		/>
	)
}

const recordMap = (items, opts) => {
	// make generic?
	return items.map(item => ({
		item,
		name: item.name || item.shortName(),
		path: item.id,
		...opts,
	}))
}

const RegularPlaceholder = () => <FormPlaceholder kind="regular" />
const IrregularPlaceholder = () => <FormPlaceholder kind="irregular" />

const mapDayValues = ({keyName, value}) => {
	if (keyName == 'relevant_days') {
		const arrayVal = _.isArray(value) ? value : value.split(', ')

		return arrayVal.map(num => t(utils.weekdays[num])).join(', ')
	}
}

const LeftFooter = () => {
	const {allowance} = useModalContext()
	const loc = useLocation()
	const {data, loading} = useGet(`allowances/${allowance?.id}/audits`, {
		skip: !allowance?.id,
		watch: `${allowance?.updated_at}-${loc.pathname}`,
	})
	if (loading) return null

	return <Audits getAuditValue={mapDayValues} tableName="allowances" audits={data.audits || []} placement="top" />
}

const FormPlaceholder = ({kind}) => {
	const {environment_id} = useModalContext()

	return (
		<Placeholder animation="money-with-arrows-up" name={`allowances.${kind}`}>
			<CreateButton
				blockNamespace
				modalLink={`/allowances/new?kind=${kind}&environment_id=${environment_id}`}
				t={`records.allowances.${kind}.name`}
			/>
		</Placeholder>
	)
}
