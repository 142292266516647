import {Layout} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'

export const BasicTableSectionHeader = () => {
	const {sectionHeader = {}} = useBasicTableContext()
	const {left, right} = sectionHeader

	if (!left && !right) return null

	return (
		<Layout horizontal="spaceBetween" minHeight={40} padding="12 - - -">
			<Layout>{left}</Layout>
			<Layout>{right}</Layout>
		</Layout>
	)
}
