import {createContext, useContext} from 'react'
import {useDateCounts} from 'cores/planning'
import {useQueryParams} from 'hooks'

const PublishShiftsContext = createContext({})
const {Provider} = PublishShiftsContext

const getTeamsWithShifts = (shiftCounts = {}, teamIds) => {
	const teamsWithShifts = new Set()

	Object.values(shiftCounts).forEach(({team_count}) => {
		const teamIds = Object.keys(team_count)
		teamIds.forEach(teamId => {
			teamsWithShifts.add(Number(teamId))
		})
	})

	return teamIds.filter(teamId => teamsWithShifts.has(teamId))
}

export const PublishShiftsProvider = ({children}) => {
	const {team_ids} = useQueryParams()
	const {data, periods} = useDateCounts({team_ids})
	const {week, month} = periods
	const {year_weeks, year_months} = data

	const value = {
		week: {data: year_weeks, periods: week, teamIds: getTeamsWithShifts(year_weeks, team_ids)},
		month: {data: year_months, periods: month, teamIds: getTeamsWithShifts(year_months, team_ids)},
	}

	return <Provider value={value}>{children}</Provider>
}

export const usePublishShiftsContext = periodType => {
	const context = useContext(PublishShiftsContext)
	if (!periodType) return context
	return context[periodType]
}
