import {PageHeader as _PageHeader, EitjeButton} from 'common/components'
import {useShared} from 'hooks'
import {KnowledgeHelpButton, NAMESPACE, useTopicContext} from 'cores/knowledge'
import {RouteSelect} from './route_select'
import {PublishButton} from './publish_button'
import {OptionsButton} from './options_button'
import {TeamButton} from './team_button'

export const PageHeader = () => {
	const {hasRole} = useTopicContext()
	const rightButtons = useRightButtons()

	return <_PageHeader className="topic-header" type="floating" borderBottom={hasRole} left={<RouteSelect />} right={rightButtons} />
}

const useRightButtons = () => {
	const {me} = useShared()
	const {finished, trainable, resources, hasRole} = useTopicContext()

	if (hasRole) return [<PublishButton />, <TeamButton />, <OptionsButton />, <KnowledgeHelpButton type="topics" />]
	const helpButton = <KnowledgeHelpButton />
	if (!trainable || !resources.length) return helpButton

	const hasFinished = finished?.includes(me.id)
	const trainingButton = hasFinished ? (
		<EitjeButton iconLeft="comment-with-text" modalLink="train/results" name={NAMESPACE} t="view_my_answers" />
	) : (
		<EitjeButton name={NAMESPACE} t="train" link="train" colorSet="solid" iconLeft="gym-barbell" />
	)

	return [trainingButton, helpButton]
}
