import utils from '@eitje/utils'
import {FilterFooterProvider, Message, PageLayout, useFilterFooterContext} from 'common/components'
import {BasicPopout} from '@eitje/web_components'
import SearchProvider from 'contexts/search'
import {useBalanceStartDate} from 'cores/balance'
import {useGet, useLastUpdated, useOrgEnvSetting, useQueryParams, useRoleOrgEnvsUsersWithDel, useUpdateArbitrary} from 'hooks'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import {BalanceAdjustment, PlanningShift, TimeRegistrationShift} from 'models'
import {useState} from 'react'
import {PageHeader} from './page_header'
import {BasicTable} from './basic_table'
import {LabelContainer} from './label_container'
import {useLastUpdatedCallback} from 'hooks/use_last_updated'

export const Inner = ({kind, Labels, _date, setDate}) => {
	const {filteredItems, FilterFooter} = useFilterFooterContext()
	const isActive = useOrgEnvSetting('balance', 'active').some(Boolean)
	const isFuture = date(_date).startOf('day') >= date().startOf('day')

	return (
		<PageLayout
			bodyWidth={1200}
			state={{date: _date, kind}}
			name="balance_index"
			horizontal="center"
			headers={[
				<PageHeader isActive={isActive} setDate={setDate} kind={kind} date={_date} />,
				isActive && <LabelContainer Labels={Labels} />,
				isFuture && <FutureWarning />,
			]}
			footers={<FilterFooter />}
			unsetPagePadding
			floating
		>
			<BasicTable isActive={isActive} kind={kind} date={_date} users={filteredItems} />
		</PageLayout>
	)
}

const FutureWarning = () => (
	<BasicPopout displayContents title={t('common.warning')} body={t('balance.index.future_warning.popout_body')}>
		<Message width="full" baseColor="red" margin={0} colorSet="color-bordered-outline" t="balance.index.future_warning.message" />
	</BasicPopout>
)

const activeMap = {1: 'yes', 2: 'no', 3: 'never'}

const filterFunction = (item, filterValue) => {
	if (!utils.exists(filterValue)) return true
	if (!_.isArray(filterValue)) return true // should never happen, but was a single filter before it went live, so to prevent our own crashes
	return filterValue.some(v => v == activeMap[item.active])
}

const useAllLastUpdated = retry => {
	const items = [useLastUpdated(TimeRegistrationShift), useLastUpdated(PlanningShift), useLastUpdated(BalanceAdjustment)]
	return useLastUpdatedCallback({items, callback: retry})
}

export const BalanceIndex = props => {
	const activeItems = ['yes', 'no', 'never'].map(i => ({id: i, name: t(`common.${i}`)}))

	const activeFilterObject = {
		name: 'balance.has_balance',
		items: activeItems,
		filterFunction,
	}

	const {kind} = props
	const {date: queryDate, out_of_service} = useQueryParams()
	const _queryDate = queryDate || date().subtract(1, 'day')

	const [_date, setDate] = useState(date(_queryDate).format())
	const url = `balance/${kind}_by_date?date=${_date}&out_of_service=${out_of_service}`

	const dates = useBalanceStartDate()
		.map(d => d.format())
		.join('-')

	const watch = `${url}-${dates}`

	const {data, retry} = useGet(url, {watch, reduxLoad: true})

	useAllLastUpdated(retry)

	useUpdateArbitrary(`${kind}_balance`, data.balance, 'user_id')

	const users = useEnrichedUsers(data.balance, {kind, _date, out_of_service})

	const filters = ['environment', activeFilterObject]
	return (
		<SearchProvider>
			<FilterFooterProvider role="uren_accorderen" searchField="full_name" items={users} filters={filters} id="balance_index_no">
				<Inner {...props} _date={_date} setDate={setDate} />
			</FilterFooterProvider>
		</SearchProvider>
	)
}

const opts = {joins: ['workSchedules', 'contractHolders', {userEmploymentTypes: ['employmentTypes']}]}

const useEnrichedUsers = (balanceData = [], {kind, out_of_service, _date}) => {
	const ids = balanceData._map('user_id')
	const users = useRoleOrgEnvsUsersWithDel('uren_accorderen', opts)
	return users
		.filter(u => ids.includes(u.id))
		.map(u => {
			const userEmpType = u.userEmploymentType(_date)
			const balanceUser = balanceData.find(u2 => u2.user_id == u.id) || {}

			const balanceActive = balanceUser.user_id && !out_of_service && userEmpType?.employmentType.hasBalance(kind)

			return {...u, ...balanceUser, active: balanceUser.balance == null ? 3 : balanceActive ? 1 : 2}
		})
}
