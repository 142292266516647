import utils from '@eitje/web_utils'
import {Layout} from 'common/components'
import {formatCssVars} from 'helpers'
import {createContext, Fragment, useContext, useEffect, useRef} from 'react'
import './styles/index.less'
import {useTopOffset} from './use_top_offset'
import {useHeaderHeight} from './use_header_height'

const PageContext = createContext({})
const {Provider} = PageContext

export const PageLayout = ({
	children,
	state,
	name,
	footers,
	headers,
	bodyLayoutProps,
	bodyBottomPadding = 48,
	BodyComponent = Layout,
	unsetPagePadding,
	bodyWidth,
	className,
	floating,
	...rest
}) => {
	const id = `${name}-page`
	const pageRef = useRef()
	const headerRef = useRef()
	const topOffset = useTopOffset(pageRef)
	const headerHeight = useHeaderHeight(headerRef, floating)
	const bodyHeight = `calc(100vh - ${topOffset}px)`
	const layoutClassName = name && _.kebabCase(`${name}-page-layout`)
	const classNames = utils.makeCns(
		'page-layout',
		layoutClassName,
		className,
		unsetPagePadding && 'unset-page-padding',
		floating && 'floating',
	)
	const value = {...state, bodyWidth, floating, headerHeight}
	const styles = formatCssVars({bodyBottomPadding, 'page-top-offset': topOffset})
	const InnerBodyWrapper = bodyWidth ? Layout : Fragment
	const outerBodyProps = bodyWidth ? {} : bodyLayoutProps
	const innerBodyProps = bodyWidth ? bodyLayoutProps : {}

	// all bodyWidth ternary's can potentially be removed by creating two page layout variants (full width and fixed width)
	const headerWidth = bodyWidth || '100%'
	const maxWidth = 'calc(100vw - 48px)'
	const minWidth = 700

	return (
		<Provider value={value}>
			<Layout id={id} ref={pageRef} className={classNames} direction="vertical" horizontal="center" height={bodyHeight} gap={0}>
				{headers && (
					<Layout
						ref={headerRef}
						direction="vertical"
						className="page-headers"
						width={headerWidth}
						gap={0}
						maxWidth={bodyWidth && maxWidth}
						minWidth={bodyWidth && minWidth}
					>
						{headers}
					</Layout>
				)}
				<BodyComponent
					grow
					className="page-body"
					direction="vertical"
					horizontal="center"
					gap={0}
					maxWidth="100vw"
					width="full"
					relative
					style={styles}
					{...outerBodyProps}
					{...rest}
				>
					<InnerBodyWrapper
						className="centered-page-body-content"
						width={bodyWidth}
						maxWidth={maxWidth}
						minWidth={minWidth}
						height="full"
						direction="vertical"
						horizontal="center"
						{...innerBodyProps}
					>
						{children}
					</InnerBodyWrapper>
				</BodyComponent>
				{footers && (
					<Layout className="page-footers" width="full" gap={0}>
						{footers}
					</Layout>
				)}
				{state && <StateSaver state={state} />}
			</Layout>
		</Provider>
	)
}

export const usePageContext = () => {
	return useContext(PageContext)
}

const StateSaver = ({state = {}}) => {
	useEffect(() => {
		if (!window.local) window.local = {}
		window.local = {...window.local, ...state}

		return () => {
			window.local = _.omit(window.local, Object.keys(state))
		}
	}, [state])

	return null
}
