import {useForm} from '@eitje/form'
import {ListPicker} from 'common/components'
import {useFormData, useShared} from 'hooks'
import {prettyPrintTeamArray, useManagerTeams} from 'cores/planning'
import {usePublishShiftsContext} from '../context'
import {useEffect} from 'react'

export const TeamField = () => {
	const {orgEnvs, multiEnvOrg} = useShared()
	const {setValues} = useForm()
	const {periodType} = useFormData()
	const {teamIds} = usePublishShiftsContext(periodType)
	const managerTeams = useManagerTeams(teamIds)

	useEffect(() => {
		// prefill team field if there is only one option
		if (teamIds.length === 1) {
			setValues({team_ids: teamIds})
		}
	}, [teamIds])

	const multipleTeamEnvs = managerTeams._map('environment_id').uniq().length > 1
	const groupedProps = multiEnvOrg && {
		groupField: 'environment_id',
		groupItems: orgEnvs,
		groupLabelField: 'naam',
		groupReadOnly: true,
		collapseGroups: multipleTeamEnvs,
	}

	const sortedManagerTeams = _.orderBy(managerTeams, 'rooster_order', 'desc')

	return (
		<ListPicker
			dropdown
			required
			field="team_ids"
			labelField="naam"
			showToggleSelectAll
			alwaysShowToggleSelectAll={managerTeams.length > 2}
			hideEasyRemovePopout
			items={sortedManagerTeams}
			multiline
			minWidth={1} // adding this to prevent the popout to grow with the LP trigger
			title={({activeItems}) => prettyPrintTeamArray({teams: activeItems, group: multiEnvOrg})}
			{...groupedProps}
			noSort
		/>
	)
}
