import {Message} from 'common/components'
import {useModalContext} from 'components/ui'
import {useFormData} from 'hooks'
import {t} from 'initializers/i18n'
import {BaseConnection} from 'models'

export const AllowanceMessage = ({item, kind = item?.kind}) => {
	const {namespace, environment_id} = useModalContext()
	const {integration_payroll_component_id} = useFormData()
	const connection = BaseConnection.find({environment_ids: [environment_id], hr: true})
	const key = integration_payroll_component_id ? 'integrated' : 'unintegrated'
	return (
		<Message form>
			{t(`${namespace}.messages.${kind}`)}
			<br />
			<br />
			{t(`${namespace}.messages.wage_cost`)}.&nbsp;
			{connection?.hasExportOption?.('allowances') && t(`${namespace}.messages.hr.${key}`, {name: connection.name})}
		</Message>
	)
}
