import {useHasRole} from 'hooks'
import date from 'initializers/date'
import {Link} from 'components/routing'
import {ftime} from 'helpers'
import {Text} from 'common/components'
import {t} from 'initializers/i18n'
import {NAMESPACE, KNOWLEDGE_DOCS} from 'cores/knowledge'

const titleColumn = {
	accessorKey: 'title',
}

const trainingColumn = {
	id: 'training',
	accessorFn: item => {
		const {always_trainable, valid_untill, days_to_start} = item

		if (always_trainable) return t('common.always')
		if (valid_untill) return `${t('common.up_untill')} ${ftime(valid_untill, 'dayMonthYear')}`
		if (_.isInteger(days_to_start)) return t('knowledge.from_working_day', {day: days_to_start + 1})

		return t(`${NAMESPACE}.no_training`)
	},
	sortingFn: ({always_trainable, valid_untill, days_to_start}) => {
		if (always_trainable) return -Infinity // ensure to always show first
		if (_.isInteger(days_to_start)) return days_to_start + 1 // prevent 0 days, since 0 always ends up on top
		if (valid_untill) return 1 / date(valid_untill).valueOf()
		return Infinity // ensure to always show last
	},
}

const publishedColumn = {
	accessorKey: 'published',
	cell: 'boolean',
	docsId: KNOWLEDGE_DOCS.create_topic,
	defaultValue: false,
}

const memberColumn = {
	id: 'members',
	accessorFn: item => {
		const {finishedUserCount, teamUserCount, trainingType} = item
		if (!trainingType) return null
		return `${finishedUserCount} / ${teamUserCount}`
	},
	docsId: KNOWLEDGE_DOCS.activate_training,
	sortingFn: [({finishedUserCount}) => -finishedUserCount, ({teamUserCount}) => -teamUserCount], // sort on finished user count first, and on total team size (largest first)
}

const trainedColumn = {
	accessorKey: 'trained',
	cell: ({item}) => {
		const {id, trained} = item
		if (trained) return <Text t="yes" />
		return (
			<Link to={`/topics/${id}/train`} styleType="hyperlink" t="start_training" styles={{width: '100%', textDecoration: 'underline'}} />
		)
	},
}

export const useColumns = () => {
	const hasManagerRole = useHasRole('manager')

	if (hasManagerRole) {
		const managerTitleColumn = {...titleColumn, docsId: KNOWLEDGE_DOCS.create_topic}
		const managerTrainingColumn = {...trainingColumn, docsId: KNOWLEDGE_DOCS.activate_training}
		return [managerTitleColumn, publishedColumn, managerTrainingColumn, memberColumn]
	}

	return [titleColumn, trainingColumn, trainedColumn]
}
