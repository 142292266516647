import {useSort} from 'hooks'

const isBooleanArray = (array, field) => {
	const values = array.map(item => item[field]).filter(val => val != null)
	return values.every(val => typeof val === 'boolean')
}

const getValue = (item, {sortField}) => {
	const val = _.get(item, sortField)
	if (_.isString(val)) return val.toLowerCase()
	return val
}

const booleanSort = (item, props) => {
	const value = getValue(item, props)
	if (value) return 0
	if (value === null) return 2
	return 1
}

const basicTableSort = (items, props = {}) => {
	const {sortField, asc, sortingFn} = props

	if (!sortField) return items

	const order = asc ? 'asc' : 'desc'
	if (sortingFn) return _.orderBy(items, sortingFn, order)
	const isBoolean = isBooleanArray(items, sortField)

	const orderIterater = isBoolean ? booleanSort : getValue
	return _.orderBy(items, item => orderIterater(item, props), order)
}

export const useBasicTableSort = ({data, columns, defaultSortField}) => {
	const sortingFns = columns.reduce((acc, {id, sortingFn}) => ((acc[id] = sortingFn), acc), {})

	return useSort({
		items: data,
		defSortField: defaultSortField,
		sortingFns,
		sortMethod: basicTableSort,
	})
}
