import {NewSideMenuModal, EitjeButton} from 'common/components'
import {NAMESPACE, KnowledgeHelpButton} from 'cores/knowledge'
import {history} from 'index'
import {useParams} from 'hooks'
import {Topic} from 'models'
import {pathActive} from 'helpers'
import {TopicForm} from './topic_form'
import {TrainingForm} from './training_form'

const routes = [
	{name: 'topic', component: TopicForm},
	{name: 'training', component: TrainingForm},
]

export const EditTopic = () => {
	const {id} = useParams()
	const {title, destroy} = Topic.find(id)
	const isTrainingForm = pathActive('training', false)

	const handleDelete = async () => {
		const res = await destroy()
		if (res.ok) history.replace('/topics')
	}

	const buttons = [
		isTrainingForm && (
			<EitjeButton name={NAMESPACE} t="training_results" iconLeft="histogram" modalLink={`/topics/${id}/training_results`} />
		),
		<KnowledgeHelpButton type="topics" />,
	]

	return (
		<NewSideMenuModal
			title={title}
			name="edit"
			width={550}
			height={514}
			routes={routes}
			hideCancel
			onDel={!isTrainingForm && handleDelete}
			buttons={buttons}
		/>
	)
}
