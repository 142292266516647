import _ from 'lodash'
import {t as baseT} from 'initializers/i18n'
import {useModalContext} from 'components/common/index'
import {DocsButton} from 'common/components'
import './styles/header.less'
import {Icon} from 'common/components'

export const ModalHeader = props => {
	const {RightHeader = Cross, RightHeaderProps = {}} = props
	return (
		<div className="eitje-modal-2-header">
			<div className="eitje-modal-2-header-left">
				<ModalLeftHeader {...props} />
			</div>
			<div className="eitje-modal-2-header-right">{RightHeader && <RightHeader {...props} children={null} {...RightHeaderProps} />}</div>
		</div>
	)
}

const Cross = () => {
	const {onCancel} = useModalContext()
	return <Icon name="cross" onClick={onCancel} />
}

const ModalLeftHeader = props => {
	let {title, LeftHeader, subtitle, docsUrl} = props
	const {t} = useModalContext()
	const subText = subtitle == true ? t('subtitle') : subtitle

	const _title = title || t('title')

	return (
		<>
			<div className="eitje-modal-2-left-header-titles">
				<h3 className="eitje-modal-2-title">{_title}</h3>
				{LeftHeader && <LeftHeader />}
				{!!subtitle && <h5 className="eitje-modal-2-subtitle"> {subText} </h5>}
			</div>

			{docsUrl && (
				<DocsButton height="mini" to={docsUrl}>
					{t('docs_title', baseT('learnMore'))}
				</DocsButton>
			)}
		</>
	)
}
