import {BasicTable as _BasicTable, usePageContext} from 'common/components'
import {ModalLink} from 'components/routing'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import {columns} from './columns'
import {textRows} from './rows'
import {useData} from './data'

export const BasicTable = () => {
	const {user} = usePageContext()
	const data = useData()

	return (
		<_BasicTable
			textRows={textRows}
			tooltip={props => props.item.terminatedTooltip && t('basic_tables.balance_show.initial_disabled')}
			layoutProps={props => props.item.terminatedTooltip && {colorSet: 'disabled'}}
			RowWrapper={({item, ...rest}) => <ModalLink to={`periods/${item.periodType}/${item.period}`} {...rest} />}
			date={date}
			withBorders
			endOfContentPlaceholder
			name="balance_show"
			data={data}
			columns={columns}
			user={user}
		/>
	)
}
