import {EitjeButton, EitjeDropdown, UserListPopout} from 'common/components'
import {Link, ModalLink} from 'components/routing'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'
import {User} from 'models'
import {useRoleTeams} from 'hooks'
import {SendTrainingElement} from './send_training_element'

export const TrainingOptionsButton = () => {
	const elements = [
		{
			Popout: SendTrainingUserList,
			popoutProps: {trigger: 'click'},
			trigger: {
				icon: 'ping-pong',
				element: <SendTrainingElement />,
			},
		},
		{
			icon: 'test-tube',
			element: <Link to="train?test_run=true" name={NAMESPACE} t="test_training" />,
		},
		{
			icon: 'gym-barbell',
			element: <Link to="train" name={NAMESPACE} t="start_training" />,
		},
		{
			icon: 'comment-with-text',
			element: <ModalLink to="train/results" name={NAMESPACE} t="view_my_answers" />,
		},
		{
			icon: 'histogram',
			element: <ModalLink to="training_results" name={NAMESPACE} t="view_answers" />,
		},
	]

	return (
		<EitjeDropdown elements={elements} trigger="click" placement="bottom-start" t="common.options">
			<EitjeButton t="common.options" dropdown />
		</EitjeDropdown>
	)
}

const SendTrainingUserList = props => {
	const {createTopicPrompt} = useTopicContext()
	const managerTeams = useRoleTeams('manager')
	const users = User.all({team_ids: managerTeams.ids()}) // allow sending training to all users in envs where user is manager

	const handleClick = userId => createTopicPrompt(users.find(({id}) => id === userId))

	return <UserListPopout users={users} onItemClick={handleClick} hideTitle showSearch {...props} />
}
