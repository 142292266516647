import {FilterFooterProvider as _FilterFooterProvider} from 'common/components'
import {LeaveRequest} from 'models'
import {useFilteredLeave, useLeaveDates} from 'cores/leave'
import {t} from 'initializers/i18n'

// TMP duplicate until we deploy the filters/master branch.
const STATUS_NAMES = ['pending', 'accepted', 'denied']

const LEAVE_STATUS_FILTER = {
	name: 'status',
	items: STATUS_NAMES,
	labelT: label => t(`leave.statusses.${label}`),
	filterFunction: (leaveRequest, statusses) => {
		return _.isEmpty(statusses) || statusses.includes(leaveRequest.status())
	},
}

const FILTERS = ['environment', LEAVE_STATUS_FILTER]

export const FilterFooterProvider = ({...rest}) => {
	const {items} = useFilteredLeave()
	return <_FilterFooterProvider {...rest} id="leave" items={items} filters={FILTERS} role="verlof" />
}
