import utils from '@eitje/web_utils'
import React, {useContext, useEffect, useState} from 'react'
import {isEqual} from 'lodash'
import {JSONTree} from 'react-json-tree'
import {usePrevious} from 'hooks/index'
import {isProd} from 'constants/general'
import {useBoolState} from '@eitje/react-hooks'
import './styles.less'

const DebugContext = React.createContext({})
const Provider = DebugContext.Provider

export const DebugProvider = ({children}) => {
	const [showPanel, toggleShowPanel] = useBoolState()
	const [debugObject, setDebugObject] = useState()

	const classNames = utils.makeCns('debug-container', showPanel && 'debug-container-open')
	const emptyMessage = <div className="grey-text">Please add your first value to the debug object...</div>

	if (isProd) return children

	return (
		<Provider
			value={{
				debugObject,
				setDebugObject,
			}}
		>
			{children}
			<div className={classNames}>
				<h2>🍳 Eitje Debugger</h2>
				{debugObject ? <JSONTree data={debugObject} theme={theme} hideRoot invertTheme sortObjectKeys /> : emptyMessage}
			</div>
		</Provider>
	)
}

export const useDebugContext = () => {
	return useContext(DebugContext)
}

export const useDebug = debugObject => {
	const {setDebugObject} = useDebugContext()
	const prevObj = usePrevious(debugObject)

	useEffect(() => {
		if (!isEqual(prevObj, debugObject) || !prevObj) {
			setDebugObject(prev => {
				const newObject = {...prev}
				prevObj && Object.keys(prevObj).forEach(key => delete newObject[key])
				return {...newObject, ...debugObject}
			})
		}
	}, [debugObject])
}

const theme = {
	base00: '#272822',
	base01: '#383830',
	base02: '#49483e',
	base03: '#75715e',
	base04: '#a59f85',
	base05: '#f8f8f2',
	base06: '#f5f4f1',
	base07: '#f9f8f5',
	base08: '#f92672',
	base09: '#fd971f',
	base0A: '#f4bf75',
	base0B: '#a6e22e',
	base0C: '#a1efe4',
	base0D: '#66d9ef',
	base0E: '#ae81ff',
	base0F: '#cc6633',
}

export default useDebugContext
