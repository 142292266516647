import {Layout} from 'common/components'

export const FloatingPageHeader = ({className, left, center, right, ...rest}) => {
	return (
		<Layout className={className} childrenGrow padding="24 0 4 0" {...rest}>
			<Layout horizontal="start" children={left} />
			{center && <Layout horizontal="center" children={center} />}
			<Layout horizontal="end" children={right} />
		</Layout>
	)
}
