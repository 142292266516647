import {API, find} from '@eitje/easy_api'
import {DeleteButton, Layout} from 'common/components'
import useList from 'components/general/list'
import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'

const SettingsItem = ({item, isLast, isFirst, isActive}) => {
	const {start_date, end_date} = item
	const empType = useSelector(state => find(state, 'employmentTypes', item.employment_type_id))
	return (
		<div className="settings-item">
			<Layout className="work-week-row" horizontal="spaceBetween">
				<div>
					<p className="eitje-text-secondary"> {t('fromTill', {from: start_date, till: end_date, kind: 'userEmploymentType'})} </p>
					<p>{empType.name}</p>
				</div>
				{isActive && isFirst && !isLast && (
					<DeleteButton onDel={() => API.destroyMutation('userEmploymentTypes', item.id)}>{t('deleteMutation')}</DeleteButton>
				)}
			</Layout>
		</div>
	)
}

const Settings = ({fullContract}) => {
	let {userEmpTypes} = fullContract
	const {list} = useList({items: userEmpTypes, def: userEmpTypes[0]?.id, ListItem: SettingsItem})

	return <div className={'list-item-active-bg'}>{list}</div>
}

export default Settings
