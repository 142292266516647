import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {matchPath, useLocation} from 'react-router-dom'
import {PopoutCard, BasicPopout} from '@eitje/web_components'
import {makePath, Link} from 'components/routing'
import useSubRoutes from 'hooks/use_sub_routes'
import useSearch from 'hooks/use_search'
import {InfoPopout, Layout, Text} from 'common/components'
import {Redirect} from 'components/routing'
import {navigateModal, closeModal} from 'actions/routing'
import './styles/index.less'
import {useEffect, useRef} from 'react'

export const DefaultSideMenuItem = ({text}) => (
	<Text truncate className="side-menu-item-text">
		{text}
	</Text>
)

const replaceParams = (route, path) => {
	// this actually is a bit bad.. it's because we've coupled sidemenu/route so frigging tightly that you can't have links that don't have their own routes..

	if (route.id) {
		path = path.replace(':id', route.id)
	}
	return path
}

const SideMenuRoute = props => {
	let {
		loc,
		disabledPopoutTitle,
		className = 'side-menu-group',
		linkDisabled = _.noop,
		alwaysExpanded,
		children,
		MenuItem = DefaultSideMenuItem,
		route,
	} = props
	let pathOpts = {}
	if (_.has(props, 'exact')) pathOpts['exact'] = props.exact
	if (_.has(route, 'exact')) pathOpts['exact'] = route.exact

	const {ownPath, path} = route
	const allPaths = [path, ownPath]
	const isActive = allPaths.some(p => isPathActive(loc.pathname, p, pathOpts))
	const isHighlighted = !!isPathActive(loc.pathname, ownPath, {exact: true})
	const layoutClassName = utils.makeCns([isHighlighted && 'active'])
	const disabledLink = linkDisabled(props)
	const {disabled} = route
	const to = disabled ? {} : {pathname: path, state: loc.state, search: loc.search}
	const wrapperRef = useRef()
	useEffect(() => {
		if (isHighlighted) {
			wrapperRef.current?.scrollIntoView?.({behavior: 'auto', block: 'nearest', inline: 'nearest'})
		}
	}, [isHighlighted])

	const {popoutTitle, popoutBody} = route

	return (
		<PopoutCard hidden={!disabled} title={disabledPopoutTitle} body={popoutBody}>
			<Link className={className} disabled={disabledLink} to={to}>
				<Layout
					ref={wrapperRef}
					width="full"
					className={layoutClassName}
					colorSet={disabled ? 'disabled' : 'grey-bordered'}
					height={44}
					vertical="center"
					horizontal="start"
					padding={12}
					disabled={disabled}
					borderBottom
				>
					<MenuItem {...props} disabled={disabled} text={route.displayName} />
					{popoutTitle && <InfoPopout PopoutComponent={BasicPopout} title={popoutTitle} body={popoutBody} />}
				</Layout>
			</Link>

			{(isActive || alwaysExpanded) && !disabled && children}
		</PopoutCard>
	)
}

const SideMenuRouteGroup = props => {
	const {route, path, search, searchItems} = props
	const {subRoutes, subLinks} = route
	const allSubs = [...subRoutes, ...subLinks]
	const filteredSubs = searchItems(allSubs)
	const mainPath = makePath(path, route)

	return (
		<SideMenuRoute alwaysExpanded={search} {...props} exact={subRoutes.length == 0}>
			{filteredSubs
				.filter(r => r.name)
				.map(r => (
					<SideMenuRouteGroup className="side-menu-item" {...props} path={mainPath} route={r} isSub />
				))}
		</SideMenuRoute>
	)
}

const getTranslation = (route, props) => {
	const {name: namespace} = props
	const {name, noTranslate, label} = route
	if (label) return label
	if (noTranslate) return name
	const transKey = name || route
	const nameTrans = `modals.${namespace}.sidemenu.${transKey}`
	const normalTrans = `sidemenu.${namespace}.${transKey}`
	return name ? t(nameTrans, t(normalTrans, transKey)) : t(normalTrans, transKey)
}

export const buildRoute = (route, props, basePath) => {
	const displayName = getTranslation(route, props)
	let finalPath = makePath(basePath, route)
	let ownPath = finalPath
	const subRoutes = useSubRoutes(route).map(r => buildRoute(r, props, ownPath))
	const subLinks = useSubRoutes({subRoutes: route.subLinks}).map(r => buildRoute(r, props, ownPath))
	finalPath = replaceParams(route, finalPath)
	if (!route.component && route.subRoutes?.length) finalPath = makePath(finalPath, route.subRoutes[0])
	const subNames = [...subRoutes._map('name'), subLinks._map('name')]
	return {
		...route,
		subRoutes,
		subLinks,
		displayName,
		subNames,
		path: finalPath,
		ownPath,
	}
}

export const SideMenu = ({routes, children, basePath, initialRoute = 0, modal, showSearch = routes.length > 5, ...props}) => {
	const loc = useLocation()
	const className = utils.makeCns('side-menu-v2', modal ? 'modal-side-menu' : 'page-side-menu')

	routes = routes.map(r => buildRoute(r, props, basePath))

	const {
		searchInput,
		search,
		searchItems,
		filteredItems = [],
	} = useSearch(routes, {...props, searchField: ['displayName', 'subNames'], showSearch})

	const allRoutes = [...routes, ...routes.map(r => r.subRoutes || [])].flat()
	const matchRoute = allRoutes.find(p => isPathActive(loc.pathname, p.path))

	if ((!matchRoute || matchRoute.disabled) && utils.exists(initialRoute)) {
		const parentRouteMatch = routes.find(r => loc.pathname.includes(r.ownPath))
		const initial = parentRouteMatch || routes[initialRoute] || routes[routes.length - 1]
		// this is to compensate for array indices (starting at 0) not aligning with step progress
		// step progress starts at 0, but if all steps are completed it's array max + 1
		return <Redirect to={initial.path} />
	}

	const inlineStyles = {background: Colors.white, zIndex: 2, position: 'sticky', top: 0}

	return (
		<Layout direction="vertical" gap={0} className={className}>
			{showSearch && (
				<Layout style={inlineStyles} horizontal="center" padding={6} borderBottom>
					{searchInput}
				</Layout>
			)}
			{filteredItems.map((r, idx) => (
				<SideMenuRouteGroup
					{...props}
					search={search}
					searchItems={searchItems}
					index={idx}
					route={r}
					loc={loc}
					routes={routes}
					path={basePath}
				/>
			))}
			{children}
		</Layout>
	)
}

const trailingSlash = /\/$/g

export const goToNext = props => {
	if (props?.linkDisabled?.(props)) return
	const {sideMenuRoutes} = props
	let currentIdx = sideMenuRoutes.findIndex(r => r.name == props.name)
	let nextRoute
	if (currentIdx == -1) {
		// it's a subroute
		const subRoutes = sideMenuRoutes._map('subRoutes').flat()
		const currentSubIdx = subRoutes.findIndex(r => r.name == props.name)
		nextRoute = subRoutes[currentSubIdx + 1]
		if (!nextRoute) {
			const currentSub = subRoutes[currentSubIdx]
			currentIdx = sideMenuRoutes.findIndex(r => r.subRoutes.includes(currentSub))

			// sub group is finished, let's see if main group still has a next route
			nextRoute = sideMenuRoutes[currentIdx + 1]
		}
	} else {
		nextRoute = sideMenuRoutes[currentIdx + 1]
	}

	if (nextRoute) {
		navigateModal(nextRoute.path, {replace: true})
	} else {
		closeModal(props.location)
	}
}

const isPathActive = (currentPath, path, {exact = true} = {}) => {
	// we'll remove the trailing slash from the currentPath
	const sanitizedPath = currentPath.replace(trailingSlash, '')
	path = path.replace(trailingSlash, '')
	return matchPath(sanitizedPath, {path, exact})
}

export default SideMenu
