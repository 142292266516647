import {Message} from 'common/components'
import {User} from 'models'
import {useFormData} from 'hooks'
import {NAMESPACE} from 'cores/firm'
import {t} from 'initializers/i18n'

export const RemoveUsersMessage = ({initialValues}) => {
	const {user_ids: initialUsers} = initialValues
	const {user_ids: newUsers} = useFormData()
	const deletedUserIds = initialUsers.filter(user => !newUsers.includes(user))
	const deletedUsers = User.where(deletedUserIds)
	const deletedAmt = deletedUserIds.length
	const noUsersDeleted = deletedAmt === 0

	if (noUsersDeleted) return null

	const userNames = deletedUsers.map(user => user.full_name).join(', ')
	const warning = t(`${NAMESPACE}.warning.delete_users`, {count: deletedAmt})
	const warningText = `${deletedAmt} ${warning}: ${userNames}.`

	return <Message baseColor="red">{warningText}</Message>
}
