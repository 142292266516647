import {Layout} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'
import {BasicTableHeaderCell} from './cell'
import './index.less'

export const BasicTableHeader = () => {
	const {columns} = useBasicTableContext()

	return (
		<Layout
			className="basic-table-header"
			gap={1} // using gap to create the visual separation without affecting the width of elements
			width="full"
			sticky="0 - - -"
			border
			borderRadius="4px 4px 0 0"
			initialBackground={Colors.mediumBorder}
		>
			{columns.map(column => (
				<BasicTableHeaderCell key={column.id} column={column} />
			))}
		</Layout>
	)
}
