import {Input, Switch} from '@eitje/form-fields-web'
import {AvatarPicker} from 'lib/form/fields/avatar_picker'
import {Environment} from 'models'
import {ListPicker, DocsPopout} from 'common/components'
import {useRoleTeams} from 'hooks'
import {KNOWLEDGE_DOCS} from 'cores/knowledge'

export const TopicFormFields = ({showPublishedField}) => {
	const managerTeams = useRoleTeams('manager')
	const environmentIds = managerTeams._map('environment_id').uniq()
	const managerTeamEnvs = Environment.where(environmentIds)

	const teamFieldProps = managerTeamEnvs.length > 1 && {
		groupField: 'environment_id',
		groupItems: managerTeamEnvs,
		groupLabelField: 'naam',
		groupReadOnly: true,
	}

	return (
		<>
			<Input field="title" required maxLength={35} />
			<Input field="subtitle" required maxLength={140} textarea />
			{showPublishedField && <Switch field="published" tooltip popoutBody PopoutComponent={PublishedPopout} />}
			<ListPicker
				field="team_ids"
				required
				collapseGroups
				hideEasyRemovePopout
				items={managerTeams}
				dropdown
				labelField="naam"
				tooltip
				popoutBody
				PopoutComponent={TeamPopout}
				maxWidth={400}
				{...teamFieldProps}
			/>

			<AvatarPicker field="remote_avatar_url" tooltip popoutBody PopoutComponent={ImagePopout} />
		</>
	)
}

const ns = 'form.topic.fields'
// destructure body from props to prevent it from being passed to the BasicPopout
const ImagePopout = ({body, ...props}) => {
	return <DocsPopout {...props} docsId={KNOWLEDGE_DOCS.write_article} text={`${ns}.remote_avatar_url.popout_body`} />
}

const PublishedPopout = ({body, ...props}) => {
	return <DocsPopout {...props} docsId={KNOWLEDGE_DOCS.create_topic} text={`${ns}.published.popout_body`} />
}

const TeamPopout = ({body, ...props}) => {
	return <DocsPopout {...props} docsId={KNOWLEDGE_DOCS.create_topic} title="teams" text={`${ns}.team_ids.popout_body`} />
}
