import {Text} from 'common/components'
import {useFileUpload} from 'hooks'
import {useArticleContext, Article as ArticleComponent} from 'cores/knowledge'

export const Article = () => {
	const {article, editDisabled, updateArticle} = useArticleContext()
	const {handleUploadClick, inputElement} = useFileUpload({onUpload: article.addPdfContent, accept: 'application/pdf'})

	const dropdownItems = [
		{
			icon: 'arrow-circle',
			disabled: editDisabled,
			element: (
				<>
					<Text t="replacePdf" />
					{inputElement}
				</>
			),
			onClick: handleUploadClick,
		},
	]

	const handleSave = async content => {
		if (content) await updateArticle({content})
	}

	return <ArticleComponent onSave={handleSave} dropdownItems={dropdownItems} />
}
