import {KNOWLEDGE_DOCS} from 'cores/knowledge'
import {useHasRole} from 'hooks'

const titleColumn = {
	accessorKey: 'title',
}

const publishedColumn = {
	accessorKey: 'published',
	cell: 'boolean',
	docsId: KNOWLEDGE_DOCS.create_topic,
	defaultValue: false,
}

const updatedAtColumn = {
	accessorKey: 'updated_at',
	cell: 'date',
}

const topicsColumn = {
	accessorKey: 'topics',
	docsId: KNOWLEDGE_DOCS.activate_training,
}

export const useColumns = () => {
	const hasManagerRole = useHasRole('manager')

	if (hasManagerRole) return [titleColumn, publishedColumn, updatedAtColumn, topicsColumn]

	return [titleColumn, updatedAtColumn]
}
