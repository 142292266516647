import {useEffect} from 'react'
import {Switch} from '@eitje/form-fields-web'
import {useForm} from '@eitje/form'
import {Environment} from 'models'
import {useFormData} from 'hooks'

export const CloseAvailabilityField = () => {
	const {team_ids} = useFormData()
	const {setValues} = useForm()
	const envIds = Environment.where({team_ids})
	const disabled = envIds.length > 1 && 'form.publish_shifts.fields.close_availability.disabled'

	useEffect(() => {
		// reset field when disabled changes
		disabled && setValues({close_availability: null})
	}, [disabled])

	return <Switch field="close_availability" disabled={disabled} />
}
