import {Layout} from 'common/components'
import {ResultField, ResultResource} from '.'
import {useTopicContext, getUserTrainingResult} from 'cores/knowledge'

export const TrainingResults = ({userId}) => {
	const {trainableResources, started, finished, user_answers} = useTopicContext()
	const {results, score, status, completed} = getUserTrainingResult(userId, trainableResources, user_answers, started, finished)
	const scoreValue = completed ? score : status

	return (
		<Layout className="training-results" direction="vertical" gap={0} width="full">
			<ResultField t="score" value={scoreValue} />
			{results.map(({id, title, ...rest}) => (
				<ResultField key={id} label={title}>
					<ResultResource {...rest} />
				</ResultField>
			))}
		</Layout>
	)
}
