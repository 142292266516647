import {useMemo} from 'react'
import {Topic} from 'models'

// topic resources are sorted in this order:
// 	1. id
// 	2. type (infos before quizItems)
// 	3. order attribute in the resource map

const resourcesWithOrder = (resources, resourceMap, resourceKind) => {
	const results = resources
		.map(resource => {
			const topicResource = resourceMap.find(r => r.resource_id === resource.id && r.kind === resourceKind)
			// return is needed for the case when a new resource has just been added to a topic
			// in that case the newly added item is present in the resourceMap, but not yet in the resources array
			if (!topicResource) return null
			const {order, id: topicResourceId} = topicResource

			return {...resource, topicResourceId, order}
		})
		.filter(Boolean)
	return _.sortBy(results, 'id')
}

export const useTopicWithResources = id => {
	const topic = Topic.find(id, {joins: ['infos', 'quizItems']})
	const {resource_map, infos = [], quizItems = []} = topic || {}

	const enhancedInfos = useMemo(() => resourcesWithOrder(infos, resource_map, 'Info'), [infos, resource_map])
	const enhancedQuizItems = useMemo(() => resourcesWithOrder(quizItems, resource_map, 'QuizItem'), [quizItems, resource_map])

	const resources = useMemo(() => _.sortBy([...enhancedInfos, ...enhancedQuizItems], 'order'), [enhancedInfos, enhancedQuizItems])
	const trainableResources = useMemo(
		() => resources.filter(resource => resource.tableName === 'quizItems' || resource.published),
		[resources],
	)

	if (_.isEmpty(topic)) return null

	const trainable = !!topic.trainingType()
	return {
		...topic,
		infos: enhancedInfos,
		quizItems: enhancedQuizItems,
		resources,
		trainableResources,
		trainable,
	}
}
