import {requireProps} from 'helpers'
import {Layout, usePageContext} from 'common/components'
import {BasicTableHeader, BasicTableSectionHeader, BasicTableBody, BasicTableProvider} from 'common/components/basic_table'
import {makeCnVariants} from 'helpers'

const inlineStyles = {backgroundColor: Colors.white}
const headerInlineStyles = {zIndex: 3, backgroundColor: Colors.lightBackground}

export const BasicTable = ({
	name,
	columns,
	data,
	defaultSortField,
	placeholderProps,
	virtual = data.length > 20,
	sectionHeader,
	height = 'full',
	...contextProps
}) => {
	const {floating, headerHeight} = usePageContext()
	const fullHeight = height === 'full'
	const classNames = makeCnVariants('basic-table', fullHeight && 'full-height', height === 'content' && 'content-height')

	requireProps('BasicTable', {name, columns})

	contextProps = {
		name,
		columns,
		data,
		defaultSortField,
		placeholderProps,
		virtual,
		sectionHeader,
		...contextProps,
	}

	return (
		<BasicTableProvider {...contextProps}>
			<Layout className={classNames} width="full" direction="vertical" gap={0} borderRadius grow={fullHeight} style={inlineStyles}>
				<Layout direction="vertical" width="full" sticky={floating && `${headerHeight} - - -`} style={headerInlineStyles}>
					<BasicTableSectionHeader />
					<BasicTableHeader />
				</Layout>
				<BasicTableBody />
			</Layout>
		</BasicTableProvider>
	)
}
