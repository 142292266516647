import {RouteSelect as _RouteSelect} from 'components/routing'
import {Layout, Title} from 'common/components'
import {NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'
import {useParams} from 'hooks'
import {Topic} from 'models'

export const RouteSelect = () => {
	const {id} = useParams()
	const topics = Topic.all()

	const items = topics.map(article => ({
		label: article.title,
		value: article.id,
	}))

	return (
		<Layout>
			<Title>{t(`${NAMESPACE}.topic`)}:</Title>
			<_RouteSelect currentVal={id} baseUrl="/topics" items={items} shouldTranslate={false} trigger="click" maxWidth={250} />
		</Layout>
	)
}
