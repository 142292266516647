import {useEffect, useMemo} from 'react'
import {useHasRole, useShared} from 'hooks'
import {CreateButton} from 'common/components'
import {NAMESPACE, KNOWLEDGE_DOCS} from 'cores/knowledge'
import {KnowledgePage} from '../components'
import {Info, Topic, User} from 'models'
import {useColumns} from './columns'

export const TopicsPage = () => {
	const hasManagerRole = useHasRole('manager')
	const {me} = useShared()
	const columns = useColumns()
	// ensure fresh topics and infos are in the store on page load
	Topic.doIndex()
	Info.doIndex()
	const topics = Topic.all()
	const users = User.all()

	useEffect(() => {
		Topic.trainingOverviewIndex()
	}, [])

	const items = useMemo(
		() =>
			topics.map(({finished, ...topic}) => {
				const trainingType = topic.trainingType()
				const data = {...topic, trainingType}

				if (hasManagerRole) {
					if (!trainingType) return data

					const teamUserIds = users.filter(user => user.team_ids.some(teamId => topic.team_ids.includes(teamId))).ids()
					const teamUserCount = teamUserIds.length
					const finishedUserCount = teamUserIds.filter(userId => finished?.includes(userId)).length
					return {...data, finishedUserCount, teamUserCount}
				}

				const trained = trainingType ? finished?.includes(me.id) : null
				return {...data, trained}
			}),
		[topics, users, hasManagerRole],
	)

	const placeholderProps = {
		name: `${NAMESPACE}.no_topics`,
		animation: 'book-with-glasses',
		subtitle: hasManagerRole,
		...(hasManagerRole && {
			docsId: KNOWLEDGE_DOCS.collection,
			children: <CreateButton modalLink="/topics/new" name={NAMESPACE} t="create_topic" />,
		}),
	}

	return (
		<KnowledgePage
			name="topics"
			items={items}
			createLabel={`${NAMESPACE}.create_topic`}
			columns={columns}
			placeholderProps={placeholderProps}
		/>
	)
}
