import React from 'react'
import utils from '@eitje/web_utils'
import {Layout} from '@eitje/web_components'
import PlanningProvider from './planning_provider'
import EnvsProvider from './envs_provider'
import PageHeader from './page_header'
import EnvTable from './env_table/index'
import {useEnvsContext, FilterFooterProvider} from 'cores/planning'
import withScrolling from 'react-dnd-scrolling'
import {MultiAction} from './multi_action'
import {DeleteDropZone} from 'cores/planning/components/delete_dropzone'
import {PrivateRoute} from 'components/private_route'
import PerMember from './per_member'
import {usePlanningRedirect} from 'cores/planning/hooks/use_planning_redirect'
import RevenueProvider from './revenue_provider'
import AmountWorkedProvider from './amount_worked_provider'
import {WeekTableDaysHeader} from './week_table_days_header'
import SearchProvider from 'contexts/search'
import {useFilterFooterContext, PageLayout} from 'common/components'
import './styles/index.less'

const ScrollingComponent = withScrolling(Layout)

const Index = () => {
	const {conflictFilter} = useEnvsContext()
	const {FilterFooter} = useFilterFooterContext()
	const classNames = utils.makeCns(conflictFilter && 'show-conflicts')

	return (
		<PageLayout
			name="planning"
			className={classNames}
			headers={<PageHeader />}
			footers={<FilterFooter />}
			BodyComponent={ScrollingComponent}
		>
			<WeekTableDaysHeader />
			<PrivateRoute path="/planning/per_team" component={EnvTables} />
			<PrivateRoute path="/planning/per_member" component={PerMember} />
			<MultiAction />
			<DeleteDropZone />
			<PlanningRedirect />
		</PageLayout>
	)
}

const PlanningRedirect = () => {
	usePlanningRedirect()
	return null
}

const EnvTables = React.memo(() => {
	const {envs} = useFilterFooterContext()
	return envs.map(e => <EnvTable key={e.id} item={e} />)
})

const Inner = () => {
	return (
		<RevenueProvider>
			<AmountWorkedProvider>
				<EnvsProvider>
					<SearchProvider>
						<FilterFooterProvider>
							<Index />
						</FilterFooterProvider>
					</SearchProvider>
				</EnvsProvider>
			</AmountWorkedProvider>
		</RevenueProvider>
	)
}

const Wrapper = () => {
	return (
		<PlanningProvider>
			<Inner />
		</PlanningProvider>
	)
}

export default React.memo(Wrapper, areEqual)

function areEqual() {
	return true
}
