import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getEnvSettings, roleSelector} from 'selectors/new_settings'
import {currentEnvSelector, currentOrgSelector, orgEnvsSelector, selfSelector} from 'selectors/records'

export function useShared() {
	const env = useSelector(currentEnvSelector)
	const envSettings = useSelector(getEnvSettings)
	const org = useSelector(currentOrgSelector)
	const orgEnvs = useSelector(orgEnvsSelector) || []
	// orgEnvs is the same as `myOrgEnvs` because we only get envs from the BE you're a part of..
	// we'd like to fix this later (that you get all envs), then this distinction will say something but for now they're equal
	const me = useSelector(selfSelector)

	const multiEnvOrg = useMemo(() => orgEnvs.length > 1, [orgEnvs.length])

	const orgEnvIds = useMemo(() => org.active_environment_ids || org.environment_ids || [], [org])
	const multiOrg = useMemo(() => me.environment_ids?.some(envId => !orgEnvIds.includes(envId)) || [], [me.environment_ids, orgEnvIds])

	const myOrgEnvs = useMemo(
		() => (me.environment_ids ? orgEnvs.filter(e => me.environment_ids.includes(e.id)) : []),
		[me.environment_ids, orgEnvs],
	)

	const myOrgEnvIds = useMemo(() => myOrgEnvs.map(e => e.id), [myOrgEnvs])

	const multiActiveEnvOrg = (orgEnvIds || []).length > 1
	const hasManagerRole = useSelector(state => roleSelector(state, 'manager'))
	const hasAdminRole = useSelector(state => roleSelector(state, 'admin'))
	const hasLeaveRole = useSelector(state => roleSelector(state, 'verlof'))
	const hasContractRole = useSelector(state => roleSelector(state, 'contracten'))
	const hasWriterRole = useSelector(state => roleSelector(state, 'uren_schrijven'))
	const hasAccordHoursRole = useSelector(state => roleSelector(state, 'uren_accorderen'))
	const hasFinancialRole = useSelector(state => roleSelector(state, 'financieel'))

	const isOrg = useMemo(() => orgEnvs.length > 1, [orgEnvs.length]) // Should be 'imPartOfMultipleEnvs'
	const isMultiOrg = useMemo(() => orgEnvIds.length > 1, [orgEnvIds.length]) // Should be 'isOrg'

	// Memoize the returned object
	const sharedData = useMemo(
		() => ({
			env,
			myOrgEnvIds,
			envSettings,
			singleEnvOrg: !multiEnvOrg,
			multiEnvOrg,
			multiActiveEnvOrg,
			org,
			isOrg,
			isMultiOrg,
			multiOrg,
			myOrgEnvs,
			orgEnvs,
			orgEnvIds,
			hasManagerRole,
			hasAdminRole,
			me,
			envId: env.id,
			orgId: env.organisation_id,
			hasLeaveRole,
			hasContractRole,
			hasWriterRole,
			hasAccordHoursRole,
			hasFinancialRole,
		}),
		[
			env,
			myOrgEnvIds,
			envSettings,
			multiEnvOrg,
			org,
			isOrg,
			isMultiOrg,
			multiOrg,
			myOrgEnvs,
			orgEnvs,
			orgEnvIds,
			hasManagerRole,
			hasAdminRole,
			me,
			env.id,
			env.organisation_id,
			hasLeaveRole,
			hasContractRole,
			hasWriterRole,
			hasAccordHoursRole,
			hasFinancialRole,
		],
	)

	return sharedData
}

export default useShared
