import {saveSettingGroup} from 'actions/settings'
import {Message, Layout, DocsPopoutButton, ListPicker} from 'common/components'
import {NewModalForm} from 'components/form/new_modal_form'
import {useModalContext} from 'components/ui'
import {useEnvSettingGroup, useFormData} from 'hooks'
import {Switch} from '@eitje/form-fields-web'
import {BasicPopout} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import {ArrayField} from 'components/form/index'
import utils from '@eitje/web_utils'

export const Breaktime = ({id}) => {
	let initialValues = useEnvSettingGroup('vloer', id)
	const {namespace} = useModalContext()

	const submit = data => {
		const {pauze_rules} = data
		if (pauze_rules.some(p => !p.hours || !p.pauze_amt)) {
			return utils.errorNotif({content: t(`${namespace}.breaktime.pauze_rules_error`)})
		}
		data.pauze_rules = data.pauze_rules.map(rule => _.omit(rule, 'id'))

		return saveSettingGroup('vloer', data, {env_id: id})
	}
	initialValues.pauze_rules = initialValues.pauze_rules.filter(p => p.hours).sortBy('hours')

	return (
		<>
			<NewModalForm
				initialValues={initialValues}
				onSubmit={submit}
				modalProps={{width: 600, buttons: [<BreakExplainButton namespace={namespace} />]}}
			>
				<Body />
			</NewModalForm>
		</>
	)
}

const Body = () => {
	const {namespace} = useModalContext()
	const {allow_break_select} = useFormData()

	const hoursItems = [...Array(9).keys()].map(i => i + 4).map(i => ({id: i, name: `${i} ${t('common.hours')}`}))
	const minutesItems = [15, 30].map(i => ({id: i, name: `${i} ${t('common.minutes')}`}))

	return (
		<>
			{allow_break_select && <Message colorSet="color-bordered-outline" t={`${namespace}.breaktime.submit_own`} />}
			<Switch tooltip popoutBody PopoutComponent={BasicPopout} field="allow_break_select" />
			<BreakMessage />
			<ArrayField
				addItemLabel={`${namespace}.breaktime.add_rule`}
				minItems={1}
				maxItems={3}
				field="pauze_rules"
				fields={[
					{Component: ListPicker, noSort: true, field: 'hours', single: true, dropdown: true, items: hoursItems},
					{Component: ListPicker, field: 'pauze_amt', single: true, dropdown: true, items: minutesItems},
				]}
			/>
		</>
	)
}

const BreakMessage = () => {
	const {namespace} = useModalContext()
	let {pauze_rules = []} = useFormData()
	pauze_rules = pauze_rules.filter(rule => rule.hours && rule.pauze_amt)
	const maxBreakRule = pauze_rules.find(p => p.hours == pauze_rules.max('hours'))
	const lastBreakMinutes = maxBreakRule?.pauze_amt
	const breakRule = maxBreakRule?.hours
	const totalBreakMinutes = pauze_rules._map('pauze_amt').sum()

	const keySuffix = pauze_rules.length < 2 ? pauze_rules.length : 'more'
	const messageKey = `${namespace}.breaktime.example.${keySuffix}`

	return (
		<Layout borderTop>
			<Message t={{key: messageKey, breakRule, lastBreakMinutes, totalBreakMinutes}} grow />
		</Layout>
	)
}

const BreakExplainButton = ({namespace}) => {
	const base = `${namespace}.breaktime.explained`
	return <DocsPopoutButton t={`${base}.button_text`} title={`${base}.title`} body={t(`${base}.body`)} />
}
