import {Message} from 'common/components'
import {NewModalForm} from 'components/form/new_modal_form'
import {NewFormInputNumber} from 'components/form/input_number'
import {useModalContext} from 'components/ui'

export const ConversionFactor = ({item}) => {
	const {namespace} = useModalContext()
	return (
		<NewModalForm initialValues={item} onSubmit={data => item.update(data)}>
			<Message form t={`${namespace}.messages.conversion_factor`} />
			<NewFormInputNumber field="conversion_factor" maxDigits={4} required />
		</NewModalForm>
	)
}
