import React, {useState} from 'react'
import {history} from 'index'
import {useForm, ContextForm} from '@eitje/form'
import LoadForm from './form'
import ConflictForm from './conflict_form'
import {loadPlanningTemplate, usePlanningDates} from 'cores/planning/index'
import './styles/index.less'
import {EitjeModal} from 'components/common'
import {t} from 'initializers/i18n'
import {useQueryParams} from 'hooks'
import {PlanningTemplate} from 'models'
import dateFn from 'initializers/date'

const Load = ({conflictData, kind}) => {
	const {submit, getData} = useForm()
	const {id} = getData()
	const title = conflictData ? 'planning.conflict_modal.title' : 'planning.load_template.title'
	const className = conflictData ? 'load-template-modal conflict' : 'load-template-modal'
	return (
		<EitjeModal name="load_template" title={t(title)} form onOk={submit} className={className} width={400}>
			{!conflictData && <LoadForm id={id} kind={kind} />}
			{conflictData && <ConflictForm data={conflictData} />}
		</EitjeModal>
	)
}

export const LoadPlanningTemplate = props => {
	const [conflictData, setConflictData] = useState(null)
	const {year, week} = usePlanningDates()

	let kind, weekday
	const {date, isWeek, planning_template_id} = useQueryParams()
	if (date) {
		kind = 'day'
		weekday = dateFn(date).weekday()
	}

	if (isWeek) kind = 'week'

	const temps = PlanningTemplate.all()

	const _loadTemplate = async data => {
		const temp = temps.find(t => t.id == data.id)
		const isWeek = data.week_or_month == 'week'
		if (temp.isDay()) {
			data = _.omit(data, ['periods', 'week_or_month'])
		} else {
			data = _.omit(data, 'days')
		}

		const keepKeys = Object.keys(data).filter(k => k.includes('schedule-') && data[k])
		const keepIds = keepKeys.map(k => k.split('-')[1]).map(Number)
		const removeIds = conflictData?.map(d => ({id: d.shift_id, date: d.date})).filter(shift => !keepIds.includes(shift.id))

		const payload = {
			...data,
			force: !!conflictData,
			remove_user_conflict_shifts: removeIds,
			year,
			week,
			is_week: isWeek,
		}

		const res = await loadPlanningTemplate(payload)
		if (res.ok && res.data.issues) {
			setConflictData(res.data.issues)
			return
		}
		return res
	}

	return (
		<ContextForm
			afterSubmit={history.goBack}
			onSubmit={_loadTemplate}
			submitInitialValues
			initialValues={{
				id: planning_template_id,
				ignore_users: false,
				days: weekday ? [weekday] : [],
			}}
			transNamespace="load_template"
			className="eitje-form-2-use-borders"
		>
			<Load kind={kind} conflictData={conflictData} {...props} />
		</ContextForm>
	)
}
