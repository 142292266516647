import {Text, ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'
import {useRoleEnvs, useShared} from 'hooks'
import {Team} from 'models'

// little hack to override the default chevron-small-down iconRight
// that is added to the trigger button by the ListPicker with the dropdown prop
const iconRightProps = {name: ''}

export const TeamButton = () => {
	const {orgEnvs} = useShared()
	const {team_ids, ...topic} = useTopicContext()
	const managerEnvs = useRoleEnvs('manager')
	const managerTeams = Team.where({environment_id: managerEnvs.ids()})
	const managerTeamIds = managerTeams.ids()

	// Filter topic team_ids on the teams the user is manager
	const accessibleTeamIds = team_ids.filter(teamId => managerTeamIds.includes(teamId))

	const handleAddTeam = async data => await topic.addRemoveAssoc({team_ids: data}, {team_ids: accessibleTeamIds})

	const groupTeamListPickerProps = managerEnvs.length > 1 && {
		groupField: 'environment_id',
		groupItems: orgEnvs,
		groupLabelField: 'naam',
		groupReadOnly: true,
		collapseGroups: true,
	}

	// title as a function to make sure a fixed label is shown in the LP trigger, instead of default behaviour
	const titleFn = ({count}) => <Text countSuffix={count}>{t('records.team.name', {count})}</Text>

	const items = managerTeams.map(team => ({
		...team,
		// topics must have at least one team, so if only 1 team is selected, make sure this team cannot be removed
		disabled: accessibleTeamIds.length === 1 && team.id === accessibleTeamIds[0] && t(`${NAMESPACE}.team_list_disabled_message`),
	}))

	return (
		<ListPicker
			dropdown
			raw
			iconLeft="link"
			items={items}
			labelField="naam"
			valueField="id"
			value={accessibleTeamIds}
			onChange={handleAddTeam}
			showSearch
			title={titleFn}
			iconRightProps={iconRightProps}
			showClearAll={false}
			showToggleSelectAll={false}
			placeholder={t('records.team.name')} // shown in LP trigger when value length equals zero
			{...groupTeamListPickerProps}
		/>
	)
}
