import {ListPicker, Message} from 'common/components'
import {NewModalForm} from 'components/form/new_modal_form'
import {useModalContext} from 'components/ui'
import {prettyPrintArray} from 'helpers'

import {useRoleEnvs, useShared} from 'hooks'
import {t} from 'initializers/i18n'
import {Role, User} from 'models'

export const RoleForm = ({name}) => {
	const roles = Role.where({name})
	let initialValues = {}

	roles.forEach(r => (initialValues[r.environment_id.toString()] = r.user_ids))
	const adminEnvIds = useRoleEnvs('admin')._map('id')

	const submit = data => {
		const payload = Object.keys(data)
			.filter(id => adminEnvIds.includes(Number(id)))
			.map(envId => ({
				id: roles.find(r => r.environment_id == envId)?.id,
				user_ids: data[envId],
				resource_id: envId,
				resource_type: 'Environment',
				name,
			}))
		Role.multiUpdate(payload)
	}

	return (
		<NewModalForm onSubmit={submit} initialValues={initialValues}>
			<Body name={name} />
		</NewModalForm>
	)
}

const Body = ({name}) => {
	const {orgEnvs} = useShared()
	const {namespace} = useModalContext()
	const adminEnvIds = useRoleEnvs('admin')._map('id')

	return (
		<>
			<Message t={`${namespace}.messages.${name}`} />
			{orgEnvs.sortBy('naam').map(e => (
				<RoleSelect roleName={name} disabled={!adminEnvIds.includes(e.id)} environment={e} />
			))}
		</>
	)
}

const RoleSelect = ({environment, roleName, disabled}) => {
	const {id, naam} = environment
	const {namespace} = useModalContext()
	const {me} = useShared()

	const users = User.where({environment_ids: [id]})
	const adminIds = Role.find({environment_id: environment.id, name: 'admin'}).user_ids
	const writeHoursIds = Role.find({environment_id: environment.id, name: 'uren_accorderen'}).user_ids
	const itemDisabled = item => {
		if (roleName == 'onroosterbaar') return false
		if (item.id == me.id && roleName == 'admin') return t(`${namespace}.messages.cannot_delete_self_admin`)
		if (roleName != 'admin' && adminIds?.includes(item.id))
			return t(`${namespace}.messages.cannot_add_admin_other_role`, {name: item.voornaam, roleName: t(`rolesEnum.${roleName}`)})
		if (roleName == 'uren_schrijven' && writeHoursIds?.includes(item.id))
			return t(`${namespace}.messages.cannot_add_writer`, {name: item.voornaam})
		return false
	}
	return (
		<ListPicker
			showToggleSelectAll={false}
			allowClear={false}
			multiline
			itemDisabled={itemDisabled}
			disabled={disabled && t(`${namespace}.messages.env_disabled`, {envName: environment.naam})}
			placeholder={disabled ? 'Je hebt niet de juiste rechten' : '...'}
			title={({activeItems}) => prettyPrintArray({array: activeItems._map('full_name'), quotes: false, joinWord: '&'})}
			field={`${environment.id}`}
			dropdown
			label={naam}
			items={disabled ? [] : users}
			labelField="full_name"
		/>
	)
}
