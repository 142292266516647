import {PageLayout} from 'common/components'
import {TopicProvider, useTopicContext} from 'cores/knowledge'
import {PageHeader} from './page_header'
import {ResourceBasicTable, TrainingBasicTable} from './components'

const bodyLayoutProps = {
	height: 'full',
	direction: 'vertical',
	gap: 24,
}

const TopicTables = () => {
	const {hasRole} = useTopicContext()

	return (
		<>
			<ResourceBasicTable />
			{hasRole && <TrainingBasicTable />}
		</>
	)
}

export const TopicPage = () => {
	return (
		<TopicProvider>
			<PageLayout name="topic" bodyLayoutProps={bodyLayoutProps} headers={<PageHeader />} bodyWidth={1000} floating>
				<TopicTables />
			</PageLayout>
		</TopicProvider>
	)
}
