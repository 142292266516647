import {DatePicker, FormRow, Input} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {goBackToForeground} from 'actions'
import {DocsButton, ListPicker, Message} from 'common/components'
import {ModalRedirect, useModalContext} from 'components/common/index'
import {HourMinutePicker} from 'components/form/hour_minute_picker'
import {ModalWithNewForm} from 'components/form/new_modal_form'
import {getBalanceArticleId, kindMap, useUserBalanceInfo} from 'cores/balance'
import {useFormData, useGet, useRoleMessage} from 'hooks'
import dateFn from 'initializers/date'
import {BalanceAdjustment} from 'models'

const plusItems = ['increase', 'decrease']

const validate = value => {
	return {valid: value > 0, message: 'not_zero'}
}

const ModalChildren = ({user, endDate, startDate, isShow, kind, showMessageAtTop}) => {
	const {plus_or_minus, amt, date} = useFormData()
	const {t} = useModalContext()
	const showMessage = !!date
	const _date = dateFn(date)
	const sign = plus_or_minus == 'increase' ? '+' : '-'
	const _amt = sign == '-' ? -amt : amt

	let {balance: initialBalance = 0} = useGet(`users/${user.id}/balance_on_date?date=${_date}&kind=${kind}`, {watch: date})
	if (isShow) initialBalance = initialBalance + -_amt // reset initial state
	const total = initialBalance + _amt
	const calcHelper = t('calculation_helper', {initialBalance, date, amt, total, sign})
	const disabledAfter = endDate && dateFn().format() > endDate ? dateFn(endDate).add(1, 'day') : dateFn()

	return (
		<>
			{showMessageAtTop && showMessage && (
				<FormRow>
					<Message children={calcHelper} />
				</FormRow>
			)}
			<FormRow>
				<ListPicker readOnly dropdown single labelField="full_name" field="user_id" items={[user]} />
				<DatePicker disabledAfter={disabledAfter} disabledBefore={startDate?.clone().subtract(1, 'day')} field="date" />
			</FormRow>
			<FormRow>
				<ListPicker dropdown single field="plus_or_minus" items={plusItems} />
				<HourMinutePicker validate={validate} maxHourDigits={4} field="amt" />
			</FormRow>
			{plus_or_minus && utils.exists(amt) && !showMessageAtTop && showMessage && (
				<FormRow>
					<Message children={calcHelper} />
				</FormRow>
			)}
			<Input textarea field="omschrijving" />
			{isShow && (
				<FormRow>
					<Input field="created_by" />
					<Input field="created_at" />
				</FormRow>
			)}
		</>
	)
}

export const AddAdjustment = ({name, ...props}) => {
	const submitBalance = data => {
		const amt = data['plus_or_minus'] == 'increase' ? data.amt : -data.amt
		return BalanceAdjustment.create({...data, amt, typ: kindMap[props.kind]})
	}
	const {user, kind, isShow} = props
	const insertAtIndex = {1: <DocsButton to={getBalanceArticleId('balance.adjustment')} />}
	const {environment_id, loading, ...rest} = useUserBalanceInfo(user.id)
	const endDate = rest[`${kind}_end_date`]
	const startDate = rest[`${kind}Start`]

	const missesRole = useRoleMessage({env: environment_id, roles: 'uren_accorderen'})
	if (loading) return null
	if (missesRole && !isShow) return <ModalRedirect content={missesRole} />
	const insert = missesRole ? insertAtIndex : Object.assign({}, props.insertAtIndex, insertAtIndex)
	// slight violation, this is because the revert button is passed as insertAtIndex and we don't want to do the check also there..

	return (
		<ModalWithNewForm
			afterSubmit={goBackToForeground}
			onSubmit={submitBalance}
			name={[name, 'add_adjustment']}
			modalProps={{
				hideSubmit: isShow,
				i18nOpts: {kind, user},
				insertAtIndex: insert,
			}}
			{...props}
		>
			<ModalChildren {...props} endDate={endDate} user={user} startDate={startDate} />
		</ModalWithNewForm>
	)
}
