import {PageLayout} from 'common/components'
import {Redirect} from 'components/routing'
import {useUserBalanceInfo, useBalanceStartDate} from 'cores/balance'
import {useGet, useQueryParams, useSetQueryParams, useShow, useLastUpdated, useUpdateArbitrary} from 'hooks'
import {date} from 'initializers/date'
import {
	BalanceAdjustment,
	ContractHolder,
	Environment,
	LeaveRequest,
	PlanningShift,
	TimeRegistrationShift,
	WorkSchedule,
	WorkWeek,
} from 'models'
import {useMemo, useState} from 'react'
import {PageHeader} from './page_header'
import {BasicTable} from './basic_table'
import {LabelContainer} from './label_container'
import {useLastUpdatedCallback} from 'hooks/use_last_updated'

const useAllLastUpdated = callback => {
	const items = [
		useLastUpdated(ContractHolder),
		useLastUpdated(BalanceAdjustment),
		useLastUpdated(WorkSchedule),
		useLastUpdated(WorkWeek),
		useLastUpdated(TimeRegistrationShift),
		useLastUpdated(PlanningShift),
		useLastUpdated(LeaveRequest),
	]
	useLastUpdatedCallback({items, callback})
}

export const BalanceShow = props => {
	const {periodType: defaultPeriodType = 'week'} = useQueryParams()
	const {kind} = props
	const [periodType, setPeriodType] = useState(defaultPeriodType)

	useSetQueryParams({periodType}, periodType)
	const user = useShow('users', {
		idField: 'user_id',
		opts: {joins: ['workSchedules', 'contractHolders', {userEmploymentTypes: 'employmentTypes'}]},
	})

	const dates = useBalanceStartDate()
		.map(d => d.format())
		.join('-')

	let watch = `${periodType}-${user.id}-${dates}`

	const {environment_id, envStartDate, loading, retry: retryBasics, ...rest} = useUserBalanceInfo(user.id, dates)

	const endDate = rest[`${kind}_end_date`]
	const startDate = rest[`${kind}Start`]

	const env = Environment.find(environment_id)
	const {data, retry} = useGet(`users/${user.id}/new_${kind}_balance?period_type=${periodType}`, {
		watch,
		skip: !user.id, // our 'hack' to not call useGet if user.id isn't defined.. can't return before hook and too lazy to split into two comps
		reduxLoad: true,
	})
	const {balance = {}, termination_date} = data

	useAllLastUpdated(() => {
		retry()
		retryBasics()
	})

	const overtimeData = useMemo(() => [{user_id: user.id, inactive: !rest.overtime_start_date}], [rest.overtime_start_date, user.id])
	const leaveData = useMemo(() => [{user_id: user.id, inactive: !rest.leave_start_date}], [rest.leave_start_date, user.id])

	useUpdateArbitrary('overtime_balance', !loading && user.id && overtimeData, 'user_id')
	useUpdateArbitrary('leave_balance', !loading && user.id && leaveData, 'user_id')

	// ensure state that's used for creating dropdown (user switch and switch between type) is always in sync

	if (!user.id) return <Redirect to={`/balance/${kind}`} />
	if (!loading && !environment_id) return <Redirect to={`/balance/${kind}`} />

	const empType = user.userEmploymentType(date())

	const state = {
		user,
		kind,
		balance,
		terminationDate: termination_date,
		empType,
		env,
		endDate,
		setPeriodType,
		periodType,
		environment_id,
		startDate,
		envStartDate,
	}

	return (
		<PageLayout
			bodyWidth={1200}
			state={state}
			name="balance_show"
			headers={[<PageHeader />, <LabelContainer Labels={props.Labels} />]}
			horizontal="center"
			unsetPagePadding
			floating
		>
			<BasicTable />
		</PageLayout>
	)
}
