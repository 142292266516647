import {usePlanningContext, useScheduledShifts} from 'cores/planning/index'
import {filterActive} from 'helpers'
import {t} from 'initializers/i18n'
import {useFilterFooterContext, EitjeButton} from 'common/components'
import {useRoleMessage, useRoleOrgEnvs} from 'hooks'

export const Publish = props => {
	const {dateRange} = usePlanningContext()
	const {
		envs,
		hasFiltered,
		filterValues: {teamIds},
	} = useFilterFooterContext()

	const managerEnvIds = useRoleOrgEnvs('manager')._map('id')

	const {planShifts} = useScheduledShifts({dateRange, teamIds})

	const unpublished = planShifts.filter(p => managerEnvIds.includes(p.team.environment_id) && !p.published)

	const anyUnpublished = unpublished.length > 0
	const notManager = useRoleMessage({roles: 'manager', env: envs})
	const disabled = notManager || (!anyUnpublished && t('planning.disabled.no_unpublished_shifts'))
	const baseLabel = t('planning.environment_table.publish')
	const label = disabled ? baseLabel : `${baseLabel} (${unpublished.length})`

	const queryParams = {
		team_ids: teamIds,
		...filterActive(hasFiltered.team || hasFiltered.environment),
	}

	return (
		<EitjeButton
			{...props}
			modalLink="/planning/publish"
			className="button-publish"
			colorSet="solid"
			iconLeft="paper-plane"
			disabled={disabled}
			children={label}
			queryParams={queryParams}
		/>
	)
}
