import {NewSideMenuModal, EitjeDropdown, DeleteButton, EitjeButton} from 'common/components'
import {useShared, useRoleMessage, useRoleOrgEnvs} from 'hooks'
import {Team} from 'models'
import {ModalLink} from 'components/routing'
import {TeamForm} from './team_form'
import {EnvironmentForm} from './environment_form'
import {pathActive} from 'helpers'
import {history} from 'index'
import {t} from 'initializers/i18n'

export const Firms = ({}) => {
	const {orgEnvs} = useShared()
	const allTeams = Team.all()
	const managerEnvs = useRoleOrgEnvs('manager')
	const isTeamForm = pathActive('teams', false, true)

	const routes = orgEnvs.sortBy('naam').map(e => {
		const teams = allTeams.filter(t => t.environment_id === e.id)
		const subRoutes = teams
			.map(t => ({name: t.naam, environment: e, team: t, path: `teams/${t.id}`, component: TeamForm}))
			.sortBy(t => t.name)
		const disabledMsg = useRoleMessage({roles: 'manager', env: e.id})
		const disabled = !managerEnvs.find(managerEnv => managerEnv.id === e.id) && disabledMsg

		return {name: e.naam, environment: e, path: `/${e.id}`, component: EnvironmentForm, subRoutes, disabled}
	})

	const activeRoute = getActiveRoute(routes)
	const activeSubRoute = getActiveSubRoute(routes)
	const buttons = [<AddButton activeRoute={activeRoute} />, isTeamForm && <DeleteTeamButton activeSubRoute={activeSubRoute} />]

	const sortFn = ({name}) => {
		if (name === 'Algemeen') {
			// Use a very low ASCII code to make sure the locked team always shows first.
			return '\0'
		}
		return name.toLowerCase()
	}

	return (
		<NewSideMenuModal
			buttons={buttons}
			sideMenuProps={{showSearch: true}}
			name="environments"
			basePath="environments"
			width={600}
			height={550}
			routes={routes}
			sortFn={sortFn}
		/>
	)
}

const AddButton = ({activeRoute}) => {
	const env = activeRoute?.environment // may be undefined when redirecting to "/environments" (no id auto selected yet)
	const notAdmin = useRoleMessage({roles: 'admin'})
	const notManager = useRoleMessage({roles: 'manager'})

	let createTeam = '/team/new'
	if (env) createTeam += `?environment_id=${env.id}`
	const item = (to, t, disabled) => ({icon: 'plus', element: <ModalLink to={to} t={`common.${t}`} />, disabled})
	const elements = [
		item('/forms/invite', 'team_members', notManager),
		item(createTeam, 'team', notManager),
		item('/environment/new', 'environment', notAdmin),
	]

	return (
		<EitjeDropdown elements={elements}>
			<EitjeButton iconLeft="plus" t="common.new" />
		</EitjeDropdown>
	)
}

const DeleteTeamButton = ({activeSubRoute}) => {
	if (!activeSubRoute) return null // After deleting a team, the buttons renders once more, by then the activeSubRoute is undefined and would crash
	const {id, environment_id, locked} = activeSubRoute.team
	const disabled = locked && t('disabled.delete_locked_team')
	const envUrl = `/environments/${environment_id}`

	const onClick = async () => {
		const {ok} = await Team.destroy(id)
		if (ok) history.push(envUrl)
	}

	return <DeleteButton onClick={onClick} disabled={disabled} />
}

//////////////////
// TMP: algemeniseren?
//////////////////

// PR comment: heb nu dit gemaakt, maar beetje awkward om altijd opnieuw te doen voor side menu's, hebben we hier al iets voor / anders maar standaardiseren?
const getActiveRoute = routes => {
	const pathname = activePathName()
	const activeSubRoute = routes.find(route => {
		return route.subRoutes.find(sub => {
			return pathname.endsWith(sub.path)
		})
	})
	const activeRoute = routes.find(route => pathname.endsWith(route.path))

	// routes only end with an ID, possible matching subRoutes, thus check subRoutes first
	return activeSubRoute || activeRoute
}

// Dito
const getActiveSubRoute = routes => {
	const activeRoute = getActiveRoute(routes)
	if (!activeRoute) return

	const pathname = activePathName()
	return activeRoute.subRoutes.find(sub => pathname.includes(sub.path))
}

// Dito
const activePathName = () => {
	// remove trailing '/' characters
	return window.location.pathname.replace(/\/+$/, '')
}
