import {t} from 'initializers/i18n'
import {moment, createMoment} from './date'

/**
 * Formats a date into a relative time string (e.g., "5 mins", "2 days").
 * @param {Object} date - The date to format.
 * @returns {string} Relative time string.
 * @example
 * formatRelative(moment().subtract(10, 'minutes')) // "10 m"
 * formatRelative(moment().subtract(2, 'hours'))   // "2 h"
 */
function formatRelative(date) {
	const now = moment()
	const secs = now.diff(date, 'seconds')
	const mins = now.diff(date, 'minutes')
	const hours = now.diff(date, 'hours')
	const days = now.diff(date, 'days')

	const _t = id => t(`times.one_letter.${id}`)

	if (secs < 60) {
		return `${secs} ${_t('seconds')}`
	} else if (mins < 60) {
		return `${mins} ${_t('minutes')}`
	} else if (hours < 24) {
		return `${hours} ${_t('hours')}`
	} else if (days < 7) {
		return `${days} ${_t('days')}`
	} else {
		return formatDayMonth(date)
	}
}

/**
 * Adds a dot to the month abbreviation if the locale is English.
 * @returns {string} The suffix to use.
 */
function monthDotSuffix() {
	// Moment doesn't suffix month abbreviation with a dot in English, while it is desired to do so.
	return moment.locale() === 'en' ? '.' : ''
}

/**
 * Formats a date as "DD MMM" (e.g., "20 Jan.").
 * @param {Object} date - The date to format.
 * @returns {string} Formatted date string.
 * @example
 * formatDayMonth(moment('2024-01-20')) // "20 Jan."
 */
function formatDayMonth(date) {
	return moment(date, 'DD-MM-YYYY').format(`DD MMM${monthDotSuffix()}`)
}

/**
 * Formats a date as "DD MMM YY" (e.g., "20 Jan. '25").
 * @param {Object} date - The date to format.
 * @returns {string} Formatted date string.
 * @example
 * formatDayMonth(moment('2024-01-20')) // "20 Jan. '25"
 */
function formatDayMonthYear(date) {
	return createMoment(date).format(`DD MMM${monthDotSuffix()} YY`)
}

/**
 * Formats a date as "dddd DD MMM 'YY" (e.g., "Monday 20 Jan. '24").
 * @param {Object} date - The date to format.
 * @returns {string} Formatted date string.
 * @example
 * formatDate(moment('2024-01-20')) // "Saturday 20 Jan. '24"
 */
function formatDate(date) {
	const dateStamp = date.format('dddd DD MMM')
	const yearStamp = date.format("'YY")

	return `${dateStamp}${monthDotSuffix()} ${yearStamp}`
}

/**
 * Formats a date and time as "dddd DD MMM 'YY at HH:mm".
 * @param {Object} date - The date to format.
 * @returns {string} Formatted date-time string.
 * @example
 * formatDateTime(moment('2024-01-20T14:30:00')) // "Saturday 20 Jan. '24 at 14:30"
 */
function formatDateTime(date) {
	const dateStamp = formatDate(date)
	if (!date.isDateTime()) return dateStamp

	const timeStamp = date.format('HH:mm')
	return `${dateStamp} ${t('common.at')} ${timeStamp}`
}

/**
 * Formats a date relative to now, with options for including time.
 * @param {Object} date - The date to format.
 * @param {Object} [options] - Formatting options.
 * @param {boolean} [options.includeTime=false] - Whether to include time in the output.
 * @returns {string} Formatted relative date string.
 * @example
 * formatRelativeToDate(moment('2024-01-20')) // "20/01/24"
 * formatRelativeToDate(moment().subtract(5, 'hours'), { includeTime: true }) // "Today at 09:30"
 */
function formatRelativeToDate(date, options = {}) {
	const {includeTime} = options
	const now = moment()
	const secs = now.diff(date, 'seconds')
	const momentDate = moment(date)

	if (secs < 60) {
		return t('times.now')
	} else if (momentDate.isToday()) {
		return momentDate.format('HH:mm')
	} else if (momentDate.isYesterday()) {
		return includeTime ? momentDate.calendar() : t('common.yesterday')
	}

	const format = includeTime ? `DD/MM/YY [${t('common.at')}] HH:mm` : 'DD/MM/YY'
	return momentDate.format(format)
}

/**
 * Formats a date relative to today, returning "Today", "Yesterday", or a formatted date.
 * @param {Object} date - The date to format.
 * @returns {string} Formatted relative date string.
 * @example
 * formatRelativeDate(moment()) // "Today"
 * formatRelativeDate(moment().subtract(1, 'day')) // "Yesterday"
 * formatRelativeDate(moment('2024-01-18')) // "18/01/24"
 */
function formatRelativeDate(date) {
	const momentDate = moment(date)

	if (momentDate.isToday()) {
		return t('common.today')
	} else if (momentDate.isYesterday()) {
		return t('common.yesterday')
	} else {
		return momentDate.format('DD/MM/YY')
	}
}

/**
 * Formats a date as the full month name (e.g., "January").
 * @param {Object} date - The date to format.
 * @returns {string} Month name.
 * @example
 * formatMonth(moment('2024-01-20')) // "January"
 */
function formatMonth(date) {
	return date.format('MMMM')
}

export function ftime(date, format) {
	if (!date) return

	// Accept date strings, Moment instances (which have a object type) and functions which return a Moment object (and
	// still need to be invoked).
	if (typeof date === 'string') date = createMoment(date)
	if (typeof date === 'function') date = date()

	switch (format) {
		case 'relative': // Example: "5 mins ago"
			return formatRelative(date)
		case 'relativeToDate': // Example: "20/01/24"
			return formatRelativeToDate(date)
		case 'relativeToDateTime': // Example: "20/01/24 at 14:30"
			return formatRelativeToDate(date, {includeTime: true})
		case 'relativeDate': // Example: "Yesterday"
			return formatRelativeDate(date)
		case 'dayMonth': // Example: "20 Jan."
			return formatDayMonth(date)
		case 'dayMonthYear': // Example: "20 Jan. '25"
			return formatDayMonthYear(date)
		case 'dateTime': // Example: "Monday 20 Jan. '24 at 14:30"
			return formatDateTime(date)
		case 'date': // Example: "Monday 20 Jan. '24"
			return formatDate(date)
		case 'month': // Example: "January"
			return formatMonth(date)
		default:
			console.error('ftime received invalid format')
	}
}

moment.fn.ftime = function (format) {
	return ftime(this, format)
}
