import {NewModalForm} from 'components/form/index'
import {NewFormInputNumber} from 'components/form/input_number'
import {Switch, FormRow, Input} from '@eitje/form-fields-web'
import {AllowanceMessage} from './message'
import {BasicPopout} from '@eitje/web_components'
import {AllowanceOptionsButton, PayrollComponentPicker} from './components'
import {DocsButton} from 'common/components'
import {TIME_REGISTRATION_DOCS} from 'cores/time_registration/constants'

export const RegularForm = ({item}) => {
	return (
		<NewModalForm
			modalProps={{buttons: [<AllowanceOptionsButton />, <DocsButton to={TIME_REGISTRATION_DOCS.allowances} />], hideCancel: true}}
			fieldProps={{tableName: 'allowances'}}
			onSubmit={data => item.update(data)}
			name={['allowances', 'allowances.regular']}
			initialValues={item}
		>
			<AllowanceMessage item={item} />
			<Input field="name" />
			<FormRow>
				<NewFormInputNumber max={5000} field="amount" maxDigits={0} required />
				<Switch field="active" tooltip popoutBody PopoutComponent={BasicPopout} />
			</FormRow>
			<PayrollComponentPicker kind="allowances" />
		</NewModalForm>
	)
}
