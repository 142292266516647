import {Channel as StreamChatChannel, VirtualizedMessageList, Window} from 'stream-chat-react'
import {Layout} from 'common/components'
import {ftime} from 'helpers'
import {AttachmentPreviewList} from './attachment_preview_list'
import {SendButton, FileUploadButton} from './buttons'
import {JumpToMessage} from './jump_to_message'
import {MessageInput} from './message_input'
import {DateSeparator} from './date_separator'
import {MessageStatus} from './message_status'
import {ChatPlaceholder, EmptyChannelPlaceholder} from './placeholder'

const TIME_BETWEEN_GROUPED_MESSAGES = 3 * 60 * 1000 // 3 minutes in ms
const MESSAGE_ACTIONS = ['quote', 'react', 'edit', 'delete', 'markUnread']

const inputProps = {focus: true, minRows: 2, maxRows: 6}

export const Channel = ({messageInputProps, messageInputLayoutProps, ...props}) => {
	const formatDate = date => ftime(date, 'relativeToDateTime')

	return (
		<Layout {...props} className="channel">
			<StreamChatChannel
				SendButton={SendButton}
				FileUploadIcon={FileUploadButton}
				EmptyPlaceholder={<ChatPlaceholder />}
				AttachmentPreviewList={AttachmentPreviewList}
				EmptyStateIndicator={EmptyChannelPlaceholder}
				DateSeparator={DateSeparator}
				MessageStatus={MessageStatus}
			>
				<JumpToMessage />
				<Window>
					<VirtualizedMessageList
						shouldGroupByUser // prevents each message from getting its own sender + timestamp, when a user sends multiple messages
						disableDateSeparator={false} // prevents disabling of the injection of the horizontal line with date
						maxTimeBetweenGroupedMessages={TIME_BETWEEN_GROUPED_MESSAGES}
						messageActions={MESSAGE_ACTIONS}
						additionalMessageInputProps={inputProps}
						formatDate={formatDate}
						closeReactionSelectorOnClick
					/>
					<MessageInput layoutProps={messageInputLayoutProps} inputProps={messageInputProps} />
				</Window>
			</StreamChatChannel>
		</Layout>
	)
}
