import {t} from 'initializers/i18n'
import {NAMESPACE} from 'cores/knowledge'
import {formatGrade} from './format_grade'

export const getUserTrainingResult = (userId, resources, answers, started, finished) => {
	const userAnswers = answers?.filter(answer => answer.user_id === userId) || []

	let score = 0
	let totalInfos = 0
	let totalQuizItems = 0

	const results = resources.map(({tableName, id, ...resource}) => {
		switch (tableName) {
			case 'infos': {
				totalInfos += 1
				const {title, readers} = resource
				const read = !!readers.find(reader => reader.user_id === userId)
				return {id, tableName, title, read}
			}
			case 'quizItems': {
				const {question, answers, correct_answer} = resource
				const userAnswer = userAnswers.find(({quiz_item_id}) => quiz_item_id === id)?.answer
				const correct = userAnswer === correct_answer

				if (correct) score += 1
				totalQuizItems += 1

				return {
					id,
					tableName,
					title: question,
					answer: answers[userAnswer],
					correct,
					correctAnswer: answers[correct_answer],
				}
			}
		}
	})

	score = totalQuizItems ? formatGrade((score / totalQuizItems) * 10) : t(`${NAMESPACE}.no_resources`)

	const readCount = results.filter(result => result.read).length
	const hasStartedInfos = !!readCount
	const hasFinisheInfos = readCount === totalInfos

	const hasStartedQuiz = started?.some(u => u === userId)
	const hasFinishedQuiz = finished?.some(u => u === userId)

	const hasStartedTraining = hasStartedInfos || hasStartedQuiz
	const hasFinishedTraining = (totalInfos ? hasFinisheInfos : true) && (totalQuizItems ? hasFinishedQuiz : true)

	const {status, completed} = getTrainingStatus(score, hasStartedTraining, hasFinishedTraining)

	return {results, score, status, completed}
}

function getTrainingStatus(score, hasStarted, hasFinished) {
	if (!hasStarted) return {status: t(`${NAMESPACE}.score_not_started`), completed: false}
	if (!hasFinished) return {status: t(`${NAMESPACE}.score_not_finished`), completed: false}
	if (score || hasFinished) return {status: t(`${NAMESPACE}.completed`), completed: true}
	return {status: null, completed: false}
}
