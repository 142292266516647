import {EitjeButton, ListPicker} from 'common/components'
import {CreateButton} from 'common/components/eitje_button/eitje_buttons'
import {useHasRole, useShared} from 'hooks'
import useMeals from 'hooks/use_meals'
import {t} from 'initializers/i18n'

const MealPicker = props => {
	const {envId} = useShared()
	const currentEnvId = props.envId || envId
	const meals = useMeals(currentEnvId)
	const hasAdminRole = useHasRole('admin', currentEnvId)
	const buttonText = `${t('common.manage')} ${t('records.shift.fields.meal')}`

	const buttonProps = {
		width: 'full',
		modalLink: `/settings/meals/${currentEnvId}`,
		state: {simpleBack: true},
		tabIndex: -1,
	}

	const placeholderProps = {
		animation: 'apple',
		name: 'meal',
		subtitle: false,
		children: hasAdminRole && <CreateButton {...buttonProps} t="action_button" />,
	}

	return (
		<ListPicker
			title={t('records.default.fields.meal')}
			dropdown
			field="maaltijd"
			items={meals}
			labelField="name"
			placeholderProps={placeholderProps}
			{...props}
		>
			<EitjeButton {...buttonProps} children={buttonText} />
		</ListPicker>
	)
}

export default MealPicker
