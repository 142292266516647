import {PageHeader as _PageHeader, CreateButton} from 'common/components'
import {RouteToggle} from './route_toggle'
import {useHasRole} from 'hooks'
import {KnowledgeHelpButton} from 'cores/knowledge'

export const PageHeader = ({name, createLabel}) => {
	const hasManagerRole = useHasRole('manager')

	return (
		<_PageHeader
			className={`${name}-header`}
			type="floating"
			left={<RouteToggle />}
			right={
				hasManagerRole && [
					<CreateButton t={createLabel} colorSet="grey-outlined" modalLink={`/${name}/new`} />,
					<KnowledgeHelpButton type={name} />,
				]
			}
		/>
	)
}
