import {useForm} from '@eitje/form'
import {t} from 'initializers/i18n'
import {formatPeriodsPickerLabel} from 'cores/planning'
import {ListPicker} from 'common/components'
import {useFormData} from 'hooks'
import {usePublishShiftsContext} from '../context'
import {useEffect} from 'react'

export const PublishPeriodsField = () => {
	const {setValues} = useForm()
	const {periodType, team_ids} = useFormData()
	const {data, periods} = usePublishShiftsContext(periodType)

	const entries = _.entries(data)
	const [firstPeriod, firstPeriodValue] = entries.length > 0 ? entries[0] : [null, {}]
	const teamCounts = _.entries(firstPeriodValue?.team_count)
	const firstPeriodHasShifts = teamCounts.some(([team_id, count]) => team_ids.includes(Number(team_id)) && count)

	useEffect(() => {
		if (!firstPeriod) return
		const periods = firstPeriodHasShifts ? [firstPeriod] : []
		setValues({periods})
	}, [firstPeriod, firstPeriodHasShifts])

	const items = periods.map(({id, label, value}) => {
		const teamCounts = data?.[value].team_count || {}
		const shiftCount = team_ids.reduce((acc, id) => acc + (teamCounts[id] || 0), 0)

		const labelWithShifts = `${label} - ${shiftCount} ${t('shift', {count: shiftCount})}`

		return {
			id,
			label: labelWithShifts,
			value,
			disabled: !shiftCount,
		}
	})

	return (
		<ListPicker
			dropdown
			required
			field="periods"
			labelField="label"
			valueField="value"
			items={items}
			noSort
			multiline
			minWidth={1} // adding this to prevent the popout to grow with the LP trigger
			title={formatPeriodsPickerLabel}
		/>
	)
}
