import {BaseConnection, EmploymentType} from 'models'
import {useParams} from 'hooks'
import {ConversionFactor} from './conversion_factor'
import {Leave} from './leave'
import {WagePeriod} from './wage_period'
import {ModalEnvHeader} from 'components/common/modal/env_header'
import {NewSideMenuModal} from 'common/components'
import {HrIntegration} from './hr_integration'

const order = ['Nul uren', 'Uren contract', 'Payroll']

export const EmploymentTypes = () => {
	const {environment_id} = useParams()
	const empTypes = EmploymentType.where({environment_id}).filter(e => e.name != 'ZZP')
	const connection = BaseConnection.find({environment_ids: [environment_id], hr: true})

	const routes = empTypes
		.sortBy(e => order.indexOf(e.name))
		.map(e => {
			const sharedProps = {key: environment_id, item: e}
			let subRoutes = [
				{
					name: 'conversion_factor',
					component: ConversionFactor,
					...sharedProps,
				},
				{
					name: 'leave',
					component: Leave,
					...sharedProps,
				},
				{
					name: 'wage_period',
					component: WagePeriod,
					...sharedProps,
				},
			]

			if (e.isFixed())
				subRoutes.push({
					name: 'hr_integration',
					component: HrIntegration,
					disabled: !connection.id && 'modals.settings.employment_types.hr_disabled',
					connection,
					...sharedProps,
				})

			return {name: e.translatedName(), path: e.sanitizedName(), subRoutes}
		})

	return (
		<NewSideMenuModal
			LeftHeader={ModalEnvHeader}
			name="settings.employment_types"
			basePath={`environments/${environment_id}/employment_types`}
			routes={routes}
		/>
	)
}
