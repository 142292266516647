import {Text, Layout} from 'common/components'
import {LeaveList, ShiftList} from 'cores/balance'
import {t} from 'initializers/i18n'
import utils from '@eitje/utils'

const inlineStyles = {hyphens: 'auto'}

const BalancePopoutBody = ({text, children}) => (
	<Layout direction="vertical" gap={20}>
		<Text styles={inlineStyles}>{text}</Text>
		{children}
	</Layout>
)

const UnapprovedLeaveBody = ({user, date, leave}) => {
	return (
		<BalancePopoutBody text={t('balance.unapproved_leave_popout.body', {user, count: leave.length, date})}>
			<LeaveList leave={leave.sortBy('start_datetime', ['desc'])} />
		</BalancePopoutBody>
	)
}

const UnapprovedHoursBody = ({user, shifts, date}) => {
	const totalHours = shifts._map('total_mins').sum()
	return (
		<BalancePopoutBody text={t('balance.unapproved_popout.body', {user, hours: totalHours, date})}>
			<ShiftList shifts={shifts.sortBy('date', ['desc'])} />
		</BalancePopoutBody>
	)
}

export const columns = [
	{
		accessorKey: 'full_name',
		tooltip: true,
	},
	{
		id: 'balance',
		accessorFn: item => utils.minToTimeString(item.balance, true),
		tooltip: true,
		popoutPropsFn: (item, props) => {
			const {date} = props
			const {shifts = [], leave = []} = item

			if (shifts.length > 0) {
				return {
					title: t('balance.unapproved_popout.title'),
					iconName: 'exclamation-mark',
					body: <UnapprovedHoursBody date={date} user={item} shifts={shifts} />,
				}
			}

			if (leave.length > 0) {
				return {
					title: t('balance.unapproved_leave_popout.title'),
					iconName: 'exclamation-mark',
					body: <UnapprovedLeaveBody user={item} date={date} leave={leave} />,
				}
			}
		},
	},
	{
		id: 'active',
		tooltip: true,
		accessorFn: item => {
			const value = item.active
			if (value === 3) return t('common.never')
			return value === 1 ? t('yes') : t('no')
		},
	},
]
