import {createContext, useContext, useState} from 'react'
import {useShared, useQueryParams} from 'hooks'
import {t} from 'initializers/i18n'
import {navigate} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {useTopicContext} from './topic_context'
import {NAMESPACE, finishTrainingStep, useCurrentTrainingStep} from 'cores/knowledge'

const TrainingContext = createContext({})
const {Provider} = TrainingContext

export const TrainingProvider = ({children}) => {
	const {test_run: testRun} = useQueryParams()
	const {id, trainableResources, title: topicTitle, trainable, finished, fetchTrainingOverview} = useTopicContext()
	const initialStep = useCurrentTrainingStep(id)
	const {me} = useShared()
	const [step, setStep] = useState(testRun ? 0 : initialStep)
	const [stepContext, setStepContext] = useState({user_id: me.id})
	const hasFinished = finished?.some(userId => userId === me.id)

	if (!testRun && (!trainable || hasFinished)) return navigate(`/topics/${id}`)

	const stepCount = trainableResources.length
	const activeResource = trainableResources[step]

	const handleNext = async () => {
		if (!testRun) await finishTrainingStep(activeResource, stepContext)

		const isLastStep = step === stepCount - 1
		if (!isLastStep) return setStep(step + 1)

		navigate(`/topics/${id}`)

		if (!testRun) {
			// fetches latest training results and adds them to the topic in the store
			await fetchTrainingOverview()
			navigateModal(`/topics/${id}/train/results`)
		}
	}

	const setSelectedAnswer = answer => {
		setStepContext(prev => ({...prev, answer}))
	}

	const title = t(`${NAMESPACE}.train_title`, {
		type: t(`${NAMESPACE}.${testRun ? 'test_run' : 'train'}`),
		title: topicTitle,
		step: step + 1, // Increment the step index by 1 to make it start from 1 (human-readable format)
		stepCount,
	})

	const value = {
		title,
		activeResource,
		handleNext,
		selectedAnswer: stepContext.answer,
		setSelectedAnswer,
		testRun,
	}

	return <Provider value={value}>{children}</Provider>
}

export const useTrainingContext = () => useContext(TrainingContext)
