import {PrivateRoute} from 'components/private_route'

import {Insert, Settings} from 'cores/revenue/index'
import {RevenueGroups} from './pages/groups'
import {NewRevenueGroup} from './pages/groups/new'
import {RevenueTeams} from './pages/teams'

const revenueBackgroundRoutes = [
	<PrivateRoute path="/environment_revenue/:envId/revenue/insert" component={Insert} />,
	<PrivateRoute path="/environment_revenue/:envId/revenue/settings" component={Settings} />,
	<PrivateRoute path="/revenue_groups/new" roleInAnyEnv="admin" component={NewRevenueGroup} />,
	<PrivateRoute path="/revenue_groups" roleInAnyEnv="admin" component={RevenueGroups} />,
	<PrivateRoute path="/revenue_teams" roleInAnyEnv="admin" component={RevenueTeams} />,
]

export {revenueBackgroundRoutes}
