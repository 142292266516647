import {RouteToggle as BaseRouteToggle} from 'components/ui'
import {t} from 'initializers/i18n'

export const RouteToggle = () => {
	const items = [
		{name: t('header.topics'), path: '/topics'},
		{name: t('header.articles'), path: '/articles'},
	]

	return <BaseRouteToggle items={items} />
}
