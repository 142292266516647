import {NewSideMenuModal} from 'common/components'
import {Devices} from './devices'
import {Rounding} from './rounding'
import {Breaktime} from './breaktime'
import {useRoleOrgEnvs} from 'hooks'

export const CheckInSettings = () => {
	const envs = useRoleOrgEnvs('admin')

	const routes = envs.map(e => {
		const subRoutes = [
			{
				name: 'devices',
				id: e.id,
				component: Devices,
			},
			{
				name: 'rounding',
				id: e.id,
				component: Rounding,
			},
			{
				name: 'breaktime',
				id: e.id,
				component: Breaktime,
			},
		]
		return {name: e.naam, path: e.id, subRoutes}
	})

	return <NewSideMenuModal name="settings.check_ins" basePath="settings/check_ins" width={800} height={500} routes={routes} />
}
