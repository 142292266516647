export const STATUSSES = {
	unapproved: ['open', 'in_progress', 'check_in'],
	approved: ['worked', 'ziek', 'verlof', 'bijzonder_verlof'],
}

// When displaying ungrouped we use a different sorting to display verlof and bijzonder_verlof after each other
export const STATUS_NAMES = [...STATUSSES.unapproved, ...STATUSSES.approved]
export const FILTER_NAME = 'timeRegStatus'

export const baseColorMapping = {
	open: 'blue',
	in_progress: 'orange',
	check_in: 'yellow',
	worked: 'green',
	exported: 'grey',
	ziek: 'pink',
	verlof: 'purple',
	bijzonder_verlof: 'pink',
}

export const TIME_REGISTRATION_DOCS = {
	allowances: 6020103,
}
