import {useRoleOrgEnvs} from '../../../hooks/use_role_org_envs'
import {Team} from 'models'

export const useManagerTeams = teamIds => {
	const roleOrgEnvs = useRoleOrgEnvs('manager')

	const filter = {
		...(teamIds && {id: teamIds}),
		environment_id: roleOrgEnvs.ids(),
	}

	return Team.where(filter)
}
