import {useWhere} from '@eitje/easy_api'
import {Input} from '@eitje/form-fields-web'
import {Layout, ListPicker, Message, Spinner} from 'common/components'
import {useModalContext} from 'components/common'
import {HourMinutePicker} from 'components/form/hour_minute_picker'
import {CompositeField, ModalForm} from 'components/form/index'
import {kindMap, useAllUserBalanceBasics, useBalanceStartDateEnv} from 'cores/balance'
import {BalanceAdjustment} from 'models'
import './setup_balance.less'
import {hooklessRoleMessage, useFormData, useRoleOrgEnvs, useShared} from 'hooks'
import utils from '@eitje/utils'

const plusItems = ['increase', 'decrease']

const saveBalance = (data, typ, date, ids) => {
	const balanceUsers = data.start_balance
		.filter(u => utils.exists(u.amt) && ids.includes(u.id))
		.map(d => {
			const amt = d['plus_or_minus'] == 'increase' ? d.amt : -d.amt
			return {date, ...d, amt, initial: true, typ}
		})
	console.log(balanceUsers.length)
	return BalanceAdjustment.multiUpdate(balanceUsers)
}

export const SetupBalance = props => {
	const typ = kindMap[props.kind]
	const {orgEnvs} = useShared()
	const {t} = useModalContext()
	const {item} = props
	const filters = {initial: true, typ}
	const date = useBalanceStartDateEnv(item.id)

	BalanceAdjustment.doIndex({filters})

	const adjustments = BalanceAdjustment.where(filters)

	const roleEnvs = useRoleOrgEnvs('uren_accorderen')

	const users = useWhere('users', {environment_ids: [item.id]})

	const basics = useAllUserBalanceBasics()
	const fieldName = `${props.kind}_start_date`

	if (!utils.exists(basics)) return <Spinner loading />

	const sortedUsers = users
		.map(u => {
			const basicItem = basics.find(u2 => u2.user_id == u.id)
			const hasBalance = !!basicItem[fieldName]

			const missesRole = hooklessRoleMessage({orgEnvs, envId: basicItem.environment_id, roleEnvs, roles: ['uren_accorderen']})

			const sortValue = !hasBalance || missesRole ? 2 : 1
			return {...u, disabled: !hasBalance ? `modals.balance/setup.start_balance.balance_disabled` : missesRole, sortValue}
		})
		.sortBy(['sortValue', 'full_name'])

	const initial = sortedUsers.map(u => {
		const adjustment = adjustments.find(a => a.user_id == u.id)
		let obj = {...u, full_name: u.full_name, user_id: u.id, plus_or_minus: 'increase', id: null}
		if (!adjustment) return obj

		return {
			...obj,
			amt: Math.abs(adjustment.amt),
			id: adjustment.id,
			plus_or_minus: adjustment.amt > 0 ? 'increase' : 'decrease',
		}
	})

	return (
		<ModalForm
			initialValues={{start_balance: initial}}
			modalProps={{confirmSubmit: true}}
			onSubmit={data => saveBalance(data, typ, date, initial.filter(u => !u.disabled)._map('id'))}
			submitInitialValues
			transNamespace="setup_balance.start_value"
			className="setup-balance-modal"
		>
			<Message children={t('start_balance.message')} />
			<CompositeField
				formRowProps={{width: '550'}}
				compositeName="start_balance"
				fields={['full_name', 'plus_or_minus', 'amt', 'id', 'user_id']}
			>
				<Fields initial={initial} />
			</CompositeField>
		</ModalForm>
	)
}

///////////////////////////////////
// This uses a legacy HourMinutePicker, because form-3 does not support forms with CompositeFields
// yet. Once it does, we have to replace the Field, so that we can throw away the old code after the
// other legacy instances.
///////////////////////////////////

const Fields = ({number, initial, ...rest}) => {
	const data = useFormData()
	const me = initial[number - 1]
	const base = 'balance/setup.start_balance'
	let {disabled} = me
	return (
		<Layout width="full" disabled={disabled}>
			<Input field={`full_name-${number}`} readOnly />
			<ListPicker required dropdown single field={`plus_or_minus-${number}`} items={plusItems} />
			<HourMinutePicker maxHourDigits={4} field={`amt-${number}`} />
		</Layout>
	)
}
