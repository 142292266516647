import utils from '@eitje/utils'
import {setArbitrary} from 'actions'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'

export const useArbitrary = (key, def) => {
	const data = useSelector(state => state.arbitrary[key])
	return data || def
}

export const useUpdateArbitrary = (key, data, id) => {
	const currentData = useArbitrary(key)
	useEffect(() => {
		if (utils.exists(data)) {
			const newData = utils.findAndReplace({oldItems: currentData, newItems: data, oldIden: id, newIden: id})
			setArbitrary(key, newData)
		}
	}, [data])
}

export default useArbitrary
