import {NewSideMenuModal} from 'common/components'
import {useRoleEnvs} from 'hooks'
import {RevenueTeam, Team} from 'models'
import {RevenueTeamForm} from './form'

export const RevenueTeams = () => {
	const envs = useRoleEnvs('admin')
	const teams = Team.all()

	const routes = envs.map(e => {
		const envTeams = teams.filter(t => t.environment_id == e.id).sortBy(t => t.naam.toLowerCase())
		const subRoutes = envTeams.map(t => ({
			name: t.naam,
			path: t.id,
			team: t,
			component: RevenueTeamForm,
		}))

		return {name: e.naam, path: e.id, subRoutes}
	})

	RevenueTeam.doIndex()

	return <NewSideMenuModal hideCancel name="revenue_teams" basePath="revenue_teams" routes={routes} />
}
