import {history} from 'index'
import {PlanningShift} from 'models'
import {useQueryParams} from 'hooks'
import {usePlanningDates} from 'cores/planning'
import {ModalWithNewForm} from 'components/form/index'
import {PublishForm} from './publish_form'
import {PublishShiftsProvider} from './context'

const modalProps = {okButtonProps: {t: 'planning.environment_table.publish', iconLeft: 'paper-plane'}, docsId: '6009484'}

export const PublishPlanning = () => {
	const {team_ids} = useQueryParams()
	const {year, week} = usePlanningDates()

	const handleSubmit = ({periodType, periods, team_ids, close_availability}) => {
		const payload = {
			team_ids,
			periods,
			year,
			week,
			close_availability,
			return_year_week: `${year}-${week}`,
			[`year_${periodType}s`]: periods,
		}

		return PlanningShift.arbitrary('new_publish', payload)
	}

	// prefill team field if there is only one option
	const initialValues = {periodType: 'week', team_ids: team_ids.length === 1 ? team_ids : []}

	return (
		<PublishShiftsProvider>
			<ModalWithNewForm
				name="publish_shifts"
				width={450}
				onSubmit={handleSubmit}
				afterSubmit={history.goBack}
				modalProps={modalProps}
				initialValues={initialValues}
			>
				<PublishForm />
			</ModalWithNewForm>
		</PublishShiftsProvider>
	)
}
