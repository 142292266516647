const possibleIntegs = [
	{name: 'Staffable', fields: ['external_id'], id: 'tigris', docsLink: '6600741', type: 'hr', export: {hide: ['meals', 'allowances']}},
	{
		name: 'Nmbrs',
		id: 'nmbrs',
		type: 'hr',
		hasPush: true,
		oauth: true,
		docsLink: '6022146',
		hasSettings: true,
		export: {
			showImproductive: true,
		},
		scopes: [
			'offline_access',
			'employee.employment.read',
			'employee.info.read',
			'employee.payment',
			'company.info.read',
			'employee.orgstructure',
			'company.document.read',
			'company.payrollsettings.read',
			'user.info.read',
		],
	},
	{
		name: 'Lightspeed - L series',
		id: 'lightspeed',
		website: ' https://www.lightspeedhq.nl',
		docsLink: '6022397',
		hasSalesAreas: true,
		type: 'pos',
	},

	{
		name: 'Lightspeed - K series',
		id: 'lightspeed_k',
		website: ' https://www.lightspeedhq.nl',
		docsLink: '8227617',
		hasSalesAreas: false,
		type: 'pos',
	},

	{
		name: 'Untill',
		id: 'untill',
		fields: ['api_url', 'api_password', 'api_username', 'api_port'],
		initialValues: {api_username: 'Eitje', api_port: 3063},
		hasSalesAreas: true,
		docsLink: '6022463',
		issuesAnchor: '#h_f5994fd9e7',
		type: 'pos',
		hasOnlyPaid: true,
	},
	{
		name: 'MPlusKASSA',
		fields: ['api_port', 'api_password', 'api_username'],
		id: 'mplus',
		docsLink: '6022438',
		initialValues: {api_username: 'eitje'},
		issuesAnchor: '#h_57d17e4f8d',
		type: 'pos',
	},
	{
		name: 'Kassatellen',
		fields: ['api_username', 'api_password'],
		id: 'kassatellen',
		docsLink: '6022452',
		issuesAnchor: '#h_2bf96a45ab',
		type: 'pos',
	},
	{name: 'Mr. Winston', id: 'mr_winston', docsLink: '8038544', type: 'pos', fields: ['api_password']},
	{name: 'Unitouch', id: 'unitouch', docsLink: '9027438', type: 'pos', fields: ['api_username', 'api_password', 'api_url']},
	{name: 'Twelve', id: 'twelve', fields: ['api_username'], docsLink: '6336809', type: 'pos'},
	{name: 'Employes', id: 'employes', fields: ['api_password'], docsLink: '6816651', type: 'hr'},
	{name: 'Loket', id: 'loket', docsLink: '6022271', type: 'hr', hasPush: true},
	{name: 'Tebi', id: 'tebi', fields: ['external_id'], docsLink: '8034483', type: 'pos'},
	{name: 'Spont', id: 'spont', fields: ['api_password'], docsLink: '8047199', type: 'pos'},
	{name: 'Bork', id: 'bork', fields: ['api_username'], docsLink: '8611823', type: 'pos'},
	{
		name: 'Fooks',
		fields: ['api_password'],
		id: 'fooks',
		hasSettings: true,
		docsLink: '9582473',
		hasPush: true,
		payrollComponents: {
			group: false,
		},
		envs: {
			single: true,
		},
		export: {
			hide: ['meals', 'allowances', 'days'],
			hideContractType: true,
		},
		type: 'hr',
	},
	{
		name: 'Piggy',
		fields: ['api_password'],
		id: 'piggy',
		docsLink: '9448814',
		type: 'loyalty',
		envs: {
			single: true,
		},
	},
	{name: 'Scanfie', id: 'scanfie', fields: ['api_username', 'api_password'], docsLink: '9344469', type: 'pos'},
]

export {possibleIntegs}
