import {User} from 'models'
import {formatGrade, NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'

export const useTrainingStats = ({started, finished, user_answers, quizItems = [], team_ids}) => {
	const teamUsers = User.where({team_ids})

	const startedUsers = teamUsers.filter(user => started?.includes(user.id))
	const finishedUsers = teamUsers.filter(user => finished?.includes(user.id))
	const notStartedUsers = teamUsers.filter(user => !started?.includes(user.id))
	const users = {startedUsers, finishedUsers, notStartedUsers}

	if (!finishedUsers?.length) {
		return {...users, averageScore: t(`${NAMESPACE}.score_not_finished`)}
	}

	const finishedUserIds = finishedUsers.ids()
	const finishedUserAnswers = user_answers?.filter(({user_id}) => finishedUserIds.includes(user_id))

	const quizItemsResults = getQuizItemResults(quizItems, finishedUserAnswers)
	const averageScore = getAverageScore(quizItemsResults)
	const {bestQuestion, worstQuestion} = getBestAndWorstQuestions(quizItemsResults)

	return {...users, averageScore, worstQuestion, bestQuestion}
}

function getQuizItemResults(quizItems, answers) {
	const quizItemsResults = quizItems.map(({id, question, correct_answer}) => {
		const quizItemAnswers = answers?.filter(({quiz_item_id}) => quiz_item_id === id) || []

		if (!quizItemAnswers.length) return {question, score: null}

		const correctAnswerCount = quizItemAnswers.filter(({answer}) => answer === correct_answer)
		const score = correctAnswerCount.length / quizItemAnswers.length

		return {question, score}
	})

	return quizItemsResults
}

function getAverageScore(quizItemsResults) {
	if (!quizItemsResults.length) return t(`${NAMESPACE}.no_resources`)
	const scores = quizItemsResults.map(({score}) => score * 10)
	const averageScore = formatGrade(scores.reduce((acc, curr) => acc + curr, 0) / scores.length)
	return averageScore
}

function getBestAndWorstQuestions(quizItemsResults) {
	if (!quizItemsResults.length) return {}

	const scaledResults = _.sortBy(
		quizItemsResults.map(item => ({
			...item,
			score: Math.round(item.score * 100),
		})),
		'score',
	)

	const worstQuestion = scaledResults[0]
	const bestQuestion = _.last(scaledResults)

	return {bestQuestion, worstQuestion}
}
