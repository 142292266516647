import Modal from 'components/general/modal'
import {t} from 'initializers/i18n'
import React, {useState} from 'react'
import useAsyncEffect from 'hooks/use_async_effect'
import useMergeState from 'hooks/use_merge_state'
import {backend} from '@eitje/easy_api'
import {Horizontal} from 'components/ui'
import useCountdown from 'hooks/use_countdown'
import utils from '@eitje/web_utils'
import {Button, UrlCopy} from 'components/ui'
import {useIncrementState} from '@eitje/react-hooks'
import {saveValue} from 'actions/general'
import {Redirect} from 'react-router-dom'
import './index.less'
import useBillingInfo from 'cores/billing/hooks/use_billing_info'
import {Title, DocsButton} from 'common/components'

const MollieSetupModal = () => {
	const [state, setState] = useMergeState({})
	const [resets, increment] = useIncrementState()
	const [loaded, setLoaded] = useState(false)
	// use get! and handle error!
	useAsyncEffect(async () => {
		const res = await backend.get(`billing/mollie_consent_payment_url`, {doLoad: true})
		if (res.ok) {
			setState(res.data)
			setLoaded(true)
			saveValue('mollieId', res.data.payment_id)
		}
	}, [resets])

	const billingInfo = useBillingInfo()
	const {needs_mandate} = billingInfo

	if (!needs_mandate) return <Redirect to="/billing/sign" />
	if (!billingInfo.person_name) return <Redirect to="/billing/setup" />
	return (
		<Modal insertAtIndex={{1: <DocsButton to={8765642} />}} width={720} className="mollie height-unset">
			<Horizontal>
				<LeftContainer />
				{loaded && <RightContainer billingInfo={billingInfo} resetTimer={increment} {...state} />}
			</Horizontal>
		</Modal>
	)
}

const RightContainer = ({expires_at, billingInfo, resetTimer, payment_url}) => {
	const secondsLeft = useCountdown(expires_at)
	const [mins, seconds] = utils.secondsToTimeString(secondsLeft).split(':')
	const {env_names} = billingInfo

	const billingEnvsStr = `${t('billingEnvsConsent')}: ${env_names.join(', ')} `
	if (!billingInfo) return null
	return (
		<div className="mollie-right-container">
			<div>
				<div className="mollie-heading">
					<Title popoutTitle={billingEnvsStr}>{billingInfo.company_name}</Title>
				</div>

				{secondsLeft > 0 && (
					<div className="row-with-justify-center gap-xss">
						<div className="secondCounter">
							<p>{mins} </p>
						</div>
						<div className="secondCounter">
							<p>{seconds}</p>
						</div>
					</div>
				)}

				{secondsLeft < 1 && (
					<div style={{paddingTop: 48}}>
						<Button style={{width: 258}} onClick={resetTimer}>
							{t('createNewCode')}
						</Button>
					</div>
				)}

				<div className="mollie-sub-heading-container">
					<h5>{t('timeout1')}</h5>
					<h5 className="underline pointer" onClick={resetTimer}>
						{t('timeout2')}
					</h5>
				</div>
			</div>

			{secondsLeft > 0 && (
				<div className="mollie-footer-container">
					<a href={payment_url}>
						<Button className="setup-bank-account-button" type="primary" disabled={secondsLeft < 1}>
							{t('setupMollie')}
						</Button>
					</a>

					<UrlCopy url={payment_url} />
					<h5>
						{t('setupMollie2')} <b>{billingInfo.company_name}</b>? {t('setupMollie3')}
					</h5>
				</div>
			)}
		</div>
	)
}

const LeftContainer = () => {
	return (
		<div className="mollie-left-container">
			<img src="/images/setupBank@1.5x.png" />
			<h3 className="margin-bottom-sm">
				{t('needApproval')}
				<br />
				{t('needApproval2')}
			</h3>
			<h5 className="font-size-sm">{t('needApproval3')}</h5>
		</div>
	)
}

export default MollieSetupModal
