import {EnvironmentPicker, Message} from 'common/components'

import {ModalWithNewForm} from 'components/form/index'
import {Input} from '@eitje/form-fields-web'
import {saveSettingGroup} from 'actions/settings'
import {useFormData, useOrgEnvsSettingGroup, useQueryParams} from 'hooks'
import {navigateModal} from 'actions'
import {t} from 'initializers/i18n'
import {BaseConnection} from 'models'
import utils from '@eitje/web_utils'

export const NewMeal = () => {
	const {environment_id} = useQueryParams()
	const settings = useOrgEnvsSettingGroup('maaltijd')

	const createMeal = data => {
		const envSettings = settings[data.environment_id]

		if (envSettings.maaltijd_opts.includes(data.name)) {
			return utils.errorNotif({content: t('modals.settings.meals.new.already_taken')})
		}

		return saveSettingGroup(
			'maaltijd',
			{
				maaltijd_opts: [...envSettings.maaltijd_opts, data.name],
				maaltijd_selected: [...envSettings.maaltijd_selected, data.name],
			},
			{env_id: data.environment_id},
		)
	}

	const goToMeal = ({params}) => {
		return navigateModal(`/settings/meals/${params.environment_id}/${params.name}`, {replace: true})
	}

	return (
		<ModalWithNewForm afterSubmit={goToMeal} onSubmit={createMeal} initialValues={{environment_id}} name="settings.meals.new">
			<MealMessage />

			<EnvironmentPicker />
			<Input field="name" required />
		</ModalWithNewForm>
	)
}

const MealMessage = () => {
	const {environment_id} = useFormData()
	const connection = BaseConnection.find({environment_ids: [environment_id], hr: true})
	const base = 'modals.settings.meals.messages'
	return (
		<Message>
			{t(`${base}.new`)}
			{connection.id && <>. {t(`${base}.new_hr`, {name: connection.name})}</>}
		</Message>
	)
}
