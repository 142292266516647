import {ListPicker, Message} from 'common/components'
import {NewModalForm} from 'components/form/index'
import {useEnvSettingGroup, useFormData, useParams} from 'hooks'
import {t, i18n} from 'initializers/i18n'
import {BasicPopout} from '@eitje/web_components'
import {saveSettingGroup} from 'actions/settings'
import date from 'initializers/date'
import {useModalContext} from 'components/ui'

const items = ['36', '38', '40', '42']
export const HrIntegration = ({connection}) => {
	const {environment_id} = useParams()
	const {namespace} = useModalContext()

	const settings = useEnvSettingGroup('uren', environment_id)

	return (
		<NewModalForm
			initialValues={settings}
			name="employment_types"
			onSubmit={data => saveSettingGroup('uren', data, {env_id: environment_id})}
		>
			<Message form t={{key: `${namespace}.messages.hr_workweek`, name: connection.name}} />
			<ListPicker
				tooltip
				popoutBody
				PopoutComponent={BasicPopout}
				i18nOpts={{connectionName: connection.name}}
				single
				dropdown
				required
				field="hvl_tijd"
				items={items}
				buildLabel={item => `${item} ${t('common.hours')}`}
			/>
		</NewModalForm>
	)
}
