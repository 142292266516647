import {useMemo} from 'react'
import {NewSideMenuModal} from 'common/components'
import {useParams, useAsyncEffect} from 'hooks'
import {Topic, User} from 'models'
import {ResultsOverview} from './results_overview'
import {ResultsUser} from './results_user'
import {TopicProvider} from 'cores/knowledge'
import {t} from 'initializers/i18n'

const sideMenuProps = {showSearch: true, alwaysExpanded: true}

export const Results = () => {
	const {id} = useParams()
	const topic = Topic.find(id)
	const {title, team_ids, started} = topic
	const users = User.where({team_ids})
	const usersIds = users.ids()
	const formerUserIds = started?.filter(id => !usersIds.includes(id))
	const formerUsers = User.where({id: formerUserIds})

	useAsyncEffect(async () => {
		// fetches training results and adds them to the topic in the store
		await topic.fetchTrainingOverview()
	}, [])

	const {routes, links} = useMemo(() => {
		const overviewPath = 'overview'
		const userCount = users.length + formerUsers.length
		return {
			routes: [
				{
					path: overviewPath,
					component: ResultsOverview,
					exact: true,
					routeProps: {basePath: overviewPath, userCount},
				},
				{path: `users/:userId`, component: ResultsUser, exact: true},
				{path: `former_users/:userId`, component: ResultsUser, exact: true},
			],
			links: [{name: 'overview'}, ...getUserRoutes('users', users), ...getUserRoutes('former_users', formerUsers)],
		}
	}, [users, formerUsers])

	const modalTitle = t('modals.training_results.title', {title})

	return (
		<TopicProvider>
			<NewSideMenuModal
				sideMenuProps={sideMenuProps}
				title={modalTitle}
				name="training_results"
				width={600}
				height={600}
				routes={routes}
				links={links}
				hideCancel
			/>
		</TopicProvider>
	)
}

function getUserRoutes(name, users) {
	if (!users.length) return []
	return [
		{
			name,
			subRoutes: users.map(({id, full_name}) => ({
				name: full_name,
				path: `${id}`,
				noTranslate: true,
			})),
		},
	]
}
