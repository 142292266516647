import {useGet} from 'hooks'
import {usePlanningDates, generatePeriodList} from 'cores/planning'

export const useDateCounts = ({team_ids}) => {
	const {startDate} = usePlanningDates()

	const periods = {
		week: generatePeriodList('week', startDate),
		month: generatePeriodList('month', startDate),
	}

	const params = {
		planning_shift: {
			year_weeks: periods.week._map('value'),
			year_months: periods.month._map('value'),
			team_ids,
			draft: true,
		},
	}

	const {data} = useGet('planning_shifts/date_counts', {params, watch: team_ids?.join()})

	return {data, periods}
}
