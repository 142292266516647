import {EnvironmentPicker} from 'common/components'
import {ModalWithNewForm} from 'components/form/index'
import {useQueryParams} from 'hooks'
import {Input} from '@eitje/form-fields-web'
import {NewFormInputNumber} from 'components/form/input_number'
import {BasicPopout} from '@eitje/web_components'
import {RevenueGroup} from 'models'
import {navigateModal} from 'actions'

export const NewRevenueGroup = () => {
	const {environment_id, returnUrl, id} = useQueryParams()

	const afterSubmit = ({resData}) => {
		const {item} = resData
		const {environment_id, id} = item
		const finalReturnUrl = returnUrl || `/revenue_groups/${environment_id}/${id}`

		return navigateModal(finalReturnUrl, {replace: true})
	}
	return (
		<ModalWithNewForm
			initialValues={{environment_id}}
			afterSubmit={afterSubmit}
			onSubmit={data => RevenueGroup.create(id ? {...data, id} : data)}
			name={['revenue_groups.new', 'settings.revenue_groups']}
		>
			<EnvironmentPicker />
			<Input required field="naam" />
			<NewFormInputNumber max={100} maxDigits={0} field="streef_pct" tooltip popoutBody PopoutComponent={BasicPopout} />
		</ModalWithNewForm>
	)
}
