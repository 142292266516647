import {ListPicker, Message} from 'common/components'
import {NewModalForm} from 'components/form/new_modal_form'
import {NewFormInputNumber} from 'components/form/input_number'
import {useModalContext} from 'components/ui'
import {useFormData} from 'hooks'
import {BasicPopout} from '@eitje/web_components'

export const Leave = ({item}) => {
	return (
		<NewModalForm name="employment_types" initialValues={item} onSubmit={data => item.update(data)}>
			<Body item={item} />
		</NewModalForm>
	)
}

const fullTimeHoursPerYear = 2080
const hoursPerDay = 8

const Body = ({item}) => {
	const {namespace} = useModalContext()
	const {leave_registration, leave_factor} = useFormData()
	const noLeave = leave_registration == 'no_leave'
	const leaveKey = noLeave ? 'inactive' : 'active'
	const hours = (leave_factor * fullTimeHoursPerYear).toFixed(0)
	const days = (hours / hoursPerDay).toFixed(1).replace('.0', '')

	return (
		<>
			<Message
				form
				t={{key: `${namespace}.messages.leave.${leaveKey}`, factor: leave_factor, days, hours, contractType: item.translatedName()}}
			/>
			<ListPicker
				tooltip
				popoutBody
				PopoutComponent={BasicPopout}
				single
				dropdown
				required
				field="leave_registration"
				items={item.leaveOptions()}
			/>
			{!noLeave && <NewFormInputNumber tooltip popoutBody PopoutComponent={BasicPopout} required field="leave_factor" max={1} />}
		</>
	)
}
