import utils from '@eitje/web_utils'
import {Layout, Text, usePageContext} from 'common/components'
import {LeaveList, ShiftList} from 'cores/balance'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'

const UnapprovedLeaveBody = ({user, item}) => {
	const {leave} = item
	return (
		<Layout direction="vertical">
			<Text>{t('balance.unapproved_leave_user_popout.body', {user, period: item.label, count: leave.length, date})}</Text>
			<LeaveList leave={leave.sortBy('start_datetime', ['desc'])} />
		</Layout>
	)
}

const UnapprovedHoursBody = ({item, user}) => {
	const {shifts} = item
	const totalHours = shifts._map('total_mins').sum()
	return (
		<Layout direction="vertical">
			<Text>{t('balance.unapproved_user_popout.body', {user, hours: totalHours, period: item.label})}</Text>
			<ShiftList shifts={shifts.sortBy('date', ['desc'])} />
		</Layout>
	)
}

const PeriodCell = ({value, item}) => {
	const {endDate} = usePageContext()
	return (
		<Text truncate>
			{value} {!endDate && item.isLast && `(${t('common.current_period')})`}
		</Text>
	)
}

export const columns = [
	{
		accessorKey: 'label',
		cell: PeriodCell,
		tooltip: true,
	},
	{
		id: 'diff',
		tooltip: true,
		accessorFn: item => {
			const {balanceActive, diff} = item
			if (!balanceActive) return t('balance.no_active_contract')
			return utils.minToTimeString(diff, true)
		},
	},
	{
		id: 'balance',
		tooltip: true,
		accessorFn: item => utils.minToTimeString(item.balance, true),
		popoutPropsFn: (item, props) => {
			const {date} = props
			const {shifts = [], leave = []} = item

			if (shifts.length > 0) {
				return {
					title: t('balance.unapproved_popout.title'),
					iconName: 'exclamation-mark',
					body: <UnapprovedHoursBody date={date} user={item} item={item} shifts={shifts} />,
				}
			}

			if (leave.length > 0) {
				return {
					title: t('balance.unapproved_leave_popout.title'),
					iconName: 'exclamation-mark',
					body: <UnapprovedLeaveBody user={item} date={date} item={item} leave={leave} />,
				}
			}
		},
	},
]
