import {useMemo} from 'react'
import {EitjeDropdown} from 'common/components'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'
import {ModalLink} from 'components/routing'
import {SendTrainingElement} from './send_training_element'

const wrapperLayoutProps = {height: 'full'}

export const RowWrapper = ({item, ...rest}) => {
	const {createTopicPrompt} = useTopicContext()
	const {id} = item

	const elements = useMemo(() => {
		return [
			{
				icon: 'histogram',
				element: <ModalLink to={`training_results/users/${id}`} name={NAMESPACE} t="view_answers" />,
			},
			{
				icon: 'ping-pong',
				element: <SendTrainingElement />,
				onClick: () => createTopicPrompt(item),
			},
		]
	}, [id])

	return (
		<EitjeDropdown
			{...rest}
			className="trainign-dropdown"
			placement="bottom-start"
			trigger="click"
			elements={elements}
			wrapperLayoutProps={wrapperLayoutProps}
			layoutProps={wrapperLayoutProps}
		/>
	)
}
