import {Fragment} from 'react'
import utils from '@eitje/web_utils'
import {requireProps} from 'helpers'
import {PopoutCard, Layout} from 'common/components'
import {BasicTableCell, useBasicTableContext} from 'common/components/basic_table'
import './index.less'

export const BasicTableRow = ({item, columns, index}) => {
	const context = useBasicTableContext()
	const props = {...context, item, columns}
	const {tooltip, textRows, layoutProps, RowWrapper = Fragment} = context
	const {message, typeId} = item
	const customRow = !!typeId
	const sharedProps = {height: 40, className: 'basic-table-row', hasHover: !!RowWrapper}
	const Wrapper = message || tooltip ? PopoutCard : Fragment

	const _tooltip = utils.funcOrVal(tooltip, props)
	const _layoutProps = utils.funcOrVal(layoutProps, props) || {}
	const title = _tooltip || utils.funcOrVal(message, props)

	if (customRow) {
		const Component = textRows.find(r => r.id == typeId)?.Component
		requireProps('BasicTableRow', {Component})

		return (
			<Wrapper title={title}>
				<Layout {...sharedProps} width="full" {..._layoutProps}>
					<Component {...props} />
				</Layout>
			</Wrapper>
		)
	}

	return (
		<Wrapper title={title}>
			<Layout {...sharedProps} width="full" gap={0} colorSet={!!RowWrapper && 'grey-bordered-solid'} {..._layoutProps}>
				<RowWrapper item={item} index={index}>
					{columns.map(column => (
						<BasicTableCell key={column.id} column={column} item={item} itemIndex={index} />
					))}
				</RowWrapper>
			</Layout>
		</Wrapper>
	)
}
