import {useMemo} from 'react'
import {Topic} from 'models'
import {DeletePopout, EitjeDropdown, Text} from 'common/components'
import {Link, ModalLink} from 'components/routing'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'

const wrapperLayoutProps = {height: 'full'}

export const RowWrapper = ({item, index, ...rest}) => {
	const {resources, hasRole, ...topic} = useTopicContext()
	const {id, tableName} = item

	const isArticle = tableName === 'infos'
	const to = isArticle ? `/articles/${id}` : `/quiz_items/${id}`
	const LinkComponent = isArticle ? Link : ModalLink

	const elements = useMemo(() => {
		const {id, published, publish, topicResourceId} = item

		const showPublish = isArticle && !published
		const removeTopicResource = () => Topic.removeTopicResource(topicResourceId)

		const isFirstResource = index === 0
		const isLastResource = index === resources.length - 1

		return [
			isArticle && {
				icon: 'eye-open',
				element: <LinkComponent t={{key: 'common.view', kind: `${NAMESPACE}.article`}} to={`/articles/${id}`} />,
			},
			{
				icon: 'pencil',
				element: (
					<LinkComponent
						t={{key: 'common.edit_type', type: `${NAMESPACE}.${isArticle ? 'article' : 'quiz'}`}}
						to={isArticle ? `${to}/editor` : to}
					/>
				),
			},
			showPublish && {
				icon: 'paper-plane',
				element: <Text t="publish" />,
				onClick: publish,
			},
			{
				Popout: DeletePopout,
				popoutProps: {onDelete: removeTopicResource, placement: 'right-start', trigger: 'click', nonDestructive: isArticle},
				trigger: {
					icon: 'trash',
					element: <Text t={isArticle ? `${NAMESPACE}.remove_from_topic` : 'delete'} />,
				},
			},
			!isFirstResource && {
				icon: 'arrow-up',
				element: <Text name={NAMESPACE} t="move_up" />,
				onClick: () => topic.moveResourceUp(index, resources),
			},
			!isLastResource && {
				icon: 'arrow-down',
				element: <Text name={NAMESPACE} t="move_down" />,
				onClick: () => topic.moveResourceDown(index, resources),
			},
		]
	}, [item, index])

	if (!hasRole) {
		if (!isArticle) return <>{rest.children}</>
		return <LinkComponent to={to} {...rest} />
	}

	return (
		<EitjeDropdown
			{...rest}
			className="resource-dropdown"
			placement="bottom-start"
			trigger="click"
			elements={elements}
			wrapperLayoutProps={wrapperLayoutProps}
			layoutProps={wrapperLayoutProps}
		/>
	)
}
