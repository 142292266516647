import {useGet} from 'hooks'
import {t} from 'initializers/i18n'
import {BaseConnection} from 'models'

export const usePayrollComponents = ({envId, connectionId}) => {
	const connection = BaseConnection.find(connectionId)
	const {data, loading} = useGet(`environments/${envId}/payroll_components`, {params: {connection_id: connectionId}})
	const {components = []} = data
	const mappedComponents = components.map(c => ({
		...c,
		category: t(`integrations.hr.payroll_components.${c.category}`, c.category),
		name: `${c.name} - code: ${c.code}`,
	}))

	return {loading, mappedComponents, components, groupField: connection.payrollComponents?.group == false ? null : 'category'}
}
