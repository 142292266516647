import {EitjeButton, EitjeDropdown, Text, DeletePopout, UserListPopout} from 'common/components'
import {ModalLink, navigate, Link} from 'components/routing'
import {useActiveUsers} from 'hooks'
import {NAMESPACE, useArticleContext} from 'cores/knowledge'

export const OptionsButton = () => {
	const {article, editDisabled} = useArticleContext()
	const {id, published, readers, publish, unpublish} = article
	const activeReaders = useActiveUsers({id: readers?._map('user_id')})

	const onDelete = async () => {
		const res = await article.destroy()
		if (res.ok) navigate('/topics')
	}

	const elements = [
		{
			icon: 'pencil',
			element: <ModalLink to={`/articles/${id}/edit`} name={NAMESPACE} t="update_title" />,
		},
		{
			icon: published ? 'eye-closed' : 'eye-open',
			element: <Text t={published ? 'unpublish' : 'publish'} />,
			onClick: published ? unpublish : publish,
		},
		{
			icon: 'magnifying-glass',
			element: <Link to={`/articles/${id}`} name={NAMESPACE} t="show_article_preview" />,
		},
		{
			Popout: UserListPopout,
			popoutProps: {users: activeReaders, t: 'chat.read_by'},
			trigger: {
				element: <Text t="chat.read_by" countSuffix={activeReaders.length} />,
				icon: 'glasses',
			},
		},
		{
			Popout: DeletePopout,
			popoutProps: {onDelete, placement: 'right-start', trigger: 'hover'},
			trigger: {
				icon: 'trash',
				element: <Text t="delete" />,
			},
		},
	]

	return (
		<EitjeDropdown elements={elements} trigger="click" placement="bottom-start" disabled={!!editDisabled}>
			<EitjeButton t="common.options" iconLeft="three-sliders" disabled={editDisabled} />
		</EitjeDropdown>
	)
}
