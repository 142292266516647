import {PageLayout} from 'common/components'
import {PageHeader} from './page_header'
import {TrainingStep} from './components'
import {TopicProvider, TrainingProvider} from 'cores/knowledge'

export const TrainPage = () => {
	return (
		<TopicProvider>
			<TrainingProvider>
				<PageLayout name="train" headers={<PageHeader />} unsetPagePadding bodyWidth={1000}>
					<TrainingStep />
				</PageLayout>
			</TrainingProvider>
		</TopicProvider>
	)
}
