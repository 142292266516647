import {FilterTrigger} from '@eitje/web_components'
import {EnvironmentFilter, ListPicker, TeamFilter, useFilterFooterContext} from 'common/components'
import {useSearchContext} from 'contexts/search'
import {filterItems, setFilter, usePrevious} from 'hooks'
import {Association, Team} from 'models'
import {useEffect} from 'react'

const order = {environment: 1, team: 2}

const sortFilters = data =>
	_.sortBy(data, item => {
		if (typeof item === 'string') {
			return order[item] || 3
		}
		return 4
	})

export const FooterFilters = () => {
	const {filters, unformattedValues, handleSetFilterValue, currentEnvs} = useFilterFooterContext()
	const currentEnvIds = currentEnvs.ids()

	return sortFilters(filters).map(filter => {
		const {name} = filter
		const value = unformattedValues[name]

		const props = {
			raw: true,
			dropdown: true,
			Trigger: FilterTrigger,
			noSort: true,
			onChange: val => handleSetFilterValue(name, val),
			value,
			...(_.isObject(filter) && filter),
		}
		if (name === 'team') {
			const {environment} = unformattedValues
			const filterTeamFunction = team =>
				(!environment?.length || environment.includes(team.environment_id)) &&
				currentEnvIds.includes(team.environment_id) &&
				filter.filterFunction(team)

			return <TeamFilter {...props} filter filterFunction={filterTeamFunction} />
		}
		if (name === 'environment') {
			return currentEnvIds.length > 1 && <EnvironmentFilter filter environments={currentEnvs} {...props} />
		}
		if (_.isObject(filter)) {
			return <ListPicker filter {...props} />
		}
		return null
	})
}

const getFilteredTeamIds = ({teamIds, filters}) => {
	const teamFilter = filters.find(f => f.name === 'team')
	if (teamFilter) {
		teamIds = teamIds.filter(teamId => teamFilter.filterFunction({id: teamId}))
	}
	return teamIds
}

// To update the team filter when the environment filter is updated
export const useSyncTeamsWithEnvs = ({environment, team, handleSetFilterValue, envs, filters}) => {
	const prevEnvironment = usePrevious(environment)

	let envTeamIds = Team.where({environment_id: envs.ids()}).ids()
	const teamIds = getFilteredTeamIds({teamIds: envTeamIds, filters})

	useEffect(() => {
		if (team?.length) {
			if (environment?.length > prevEnvironment?.length) {
				handleSetFilterValue('team', [])
			} else {
				handleSetFilterValue('team', team?.filter(teamId => teamIds.includes(teamId)) || [])
			}
		}
	}, [environment])
}

export const useClearFilters = ({currentFilters}) => {
	const {clearSearch} = useSearchContext()

	const clearFilters = () => {
		clearSearch?.()

		// clear the filters that are managed in the store
		Object.keys(currentFilters).forEach(filter => setFilter(filter, []))
	}
	return clearFilters
}

const builtInFilters = ['environment', 'team']

export const formatFilterValue = ({filterValues, filters, envs}) => {
	const envsTeamIds = Team.where({environment_id: envs.ids()}).ids()

	const newFilterValues = Object.entries(filterValues).reduce((acc, [key, value]) => {
		acc[builtInFilters.includes(key) ? key + 'Ids' : key] = value
		return acc
	}, {})

	if (filters.map(f => f.name || f).includes('team') && _.isEmpty(filterValues.team)) {
		const teamIds = getFilteredTeamIds({teamIds: envsTeamIds, filters})
		newFilterValues.teamIds = teamIds
	}

	return newFilterValues
}

export const useFilteredItems = props => {
	let {items, searchField, search, filters, filterValues, filterEnviromentsKey, hasFiltered, includeEmptyTeamItems} = props

	const {teamIds} = filterValues || {}
	if (teamIds?.length) {
		const teamFilterActive = hasFiltered?.team

		items = items.filter(
			i =>
				teamIds.includes(i.team_id) ||
				(i.team_ids && teamIds.some(tId => i.team_ids.includes(tId))) ||
				(!i.team_ids?.length && !teamFilterActive && includeEmptyTeamItems),
		)
	}

	const {environmentIds} = filterValues || {}
	if (environmentIds?.length) {
		items = items.filter(item => {
			const envId = item.environment_id || item.environment?.id || item.team?.environment_id

			let envIds
			if (envId) {
				envIds = [envId]
			} else if (filterEnviromentsKey) {
				// Beware: this if clause must come for before the next, especially in the cases of the deleted environments filter on
				// the out of service users page. Whenever a user would be out of the current organisation, but still be active in another
				// organisation, it would filter incorrectly, since item.environment_ids would not be empty.
				envIds = item[filterEnviromentsKey]
			} else {
				envIds = item.environment_ids || []
			}

			return envIds.some(itemId => environmentIds.includes(itemId))
		})
	}

	if (search) {
		searchField = searchField || 'user.full_name'
		items = filterItems(items, search, {searchField})
	}

	// We filter to only keep the custom filters, which are objects
	filters
		.filter(f => !builtInFilters.includes(f.name))
		.forEach(filter => {
			const filterValue = filterValues[filter.name]
			if (filterValue) {
				items = items.filter(item => filter.filterFunction(item, filterValue))
			}
		})

	return new Association(items)
}
