import {EitjeButton, EitjeDropdown, HelpButton, PageHeader as _PageHeader, ListPicker, Text} from 'common/components'
import {ModalLink, RouteSelect} from 'components/routing'
import {useEnvsSetting, useQueryParams, useShared} from 'hooks'
import createDate from 'initializers/date'
import {t} from 'initializers/i18n'
import {Environment} from 'models'
import {EitjeDatePicker} from 'components/date'
import {useBalanceStartDateLowest} from '../../hooks'
import {useHistory} from 'react-router-dom'
import {getBalanceArticles} from 'cores/balance/constants'
import {Modal} from 'antd'

export const PageHeader = ({date, setDate, kind}) => {
	const history = useHistory()
	const {out_of_service} = useQueryParams()

	const items = [
		{label: t('overtime'), value: `/balance/overtime`, query: {date, out_of_service}},
		{label: t('common.leave_balance'), value: `/balance/leave`, query: {date, out_of_service}},
	]

	let minDate = useBalanceStartDateLowest()
	if (minDate) minDate = minDate.clone().subtract(1, 'day')
	const maxDate = createDate().add(1, 'year')

	const setQueryParam = val => {
		history.replace({search: `?out_of_service=${val == 'out_of_service'}`})
	}

	const serviceItems = ['in_service', 'out_of_service'].map(i => ({id: i, name: t(`common.${i}`)}))

	const specificArticle = kind == 'leave' ? 'balance.leave_101' : 'balance.overtime_101'
	const docsLinks = getBalanceArticles([specificArticle, 'balance.setup', 'balance.adjustment'])

	return (
		<_PageHeader
			type="floating"
			left={[
				<RouteSelect trigger="click" shouldTranslate={false} currentVal={`/balance/${kind}`} items={items} />,
				<ListPicker
					data-intercom-target="in_service_picker"
					trigger="click"
					single
					value={out_of_service ? 'out_of_service' : 'in_service'}
					dropdown
					raw
					items={serviceItems}
					onChange={setQueryParam}
				/>,
				<EitjeDatePicker
					showArrow
					value={date}
					allowClear={false}
					onChange={setDate}
					disabledBefore={minDate}
					disabledAfter={maxDate}
					showToday={false}
				/>,
			]}
			right={[
				<StartBalanceButton />,
				kind == 'leave' && <LeaveBuildupButton />,
				<HelpButton tourId={581836} docsLinks={docsLinks} collection={3362955} />,
			]}
		/>
	)
}

const types = ['fixed', 'flex', 'payroll']
const LeaveBuildupButton = () => {
	const {envId} = useShared()
	const elements = types.map(type => (
		<ModalLink to={`/environments/${envId}/employment_types/${type}/leave`}>
			<Text>{t(`common.${type}_contract`)}</Text>
		</ModalLink>
	))

	return (
		<EitjeDropdown elements={elements} trigger="click">
			<EitjeButton iconLeft="cog-wheel" t="balance.leave_index.buildup" />
		</EitjeDropdown>
	)
}

const StartBalanceButton = () => {
	let props = {iconLeft: 'cog-wheel'}
	const balanceActiveObj = useEnvsSetting('balance', 'active')
	const values = Object.values(balanceActiveObj)
	const doneCount = values.filter(Boolean).length
	const totalCount = values.length
	const allDone = doneCount == totalCount

	if (!allDone) props = {...props, colorSet: 'solid'}

	const envIds = Object.keys(balanceActiveObj)

	const children = (
		<>
			{t('balance.initial')} {!allDone && `(${doneCount}/${totalCount})`}
		</>
	)

	if (envIds.length == 1)
		return (
			<EitjeButton
				data-intercom-target="start-balance-btn"
				modalLink={`/balance/setup/${envIds[0]}/start_date`}
				{...props}
				children={children}
			/>
		)

	const elements = envIds.map(e => ({
		icon: balanceActiveObj[e] && 'checked',
		enableRole: 'uren_accorderen',
		roleEnv: Number(e),
		element: <EnvRow id={e} done={balanceActiveObj[e]} />,
	}))

	return (
		<EitjeDropdown wrapperLayoutProps={{'data-intercom-target': 'start-balance-btn'}} elements={elements} trigger="click">
			<EitjeButton {...props} children={children} />
		</EitjeDropdown>
	)
}

const EnvRow = ({id}) => {
	const env = Environment.find(id)
	return <ModalLink to={`/balance/setup/${id}/start_date`}> {env.naam} </ModalLink>
}
