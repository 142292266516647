import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import SearchProvider from 'contexts/search'
import {PageHeader} from './page_header'
import {t} from 'initializers/i18n'
import {KnowledgeBasicTable} from './knowledge_basic_table'

const FILTERS = ['environment', 'team']

export const KnowledgePage = ({name, items, createLabel, ...basicTableProps}) => {
	return (
		<SearchProvider searchFieldName={t('title')}>
			<FilterFooterProvider id={name} items={items} filters={FILTERS} searchField="title" includeEmptyTeamItems>
				<PageLayout
					name={name}
					headers={<PageHeader name={name} createLabel={createLabel} />}
					footers={<FilterFooter />}
					bodyWidth={1000}
					unsetPagePadding
					state={{name}}
					floating
				>
					<KnowledgeBasicTable {...basicTableProps} />
				</PageLayout>
			</FilterFooterProvider>
		</SearchProvider>
	)
}
