import utils from '@eitje/utils'
import _ from 'lodash'
import {useState} from 'react'

const getValue = (item, {sortField, nested}) => {
	let val = _.get(item, sortField)
	if (nested) val = val.value
	if (_.isString(val)) val = val.toLowerCase()
	return val
}

export const sortItems = (items, props = {}) => {
	const {sortField, asc, sortingFn} = props

	if (!sortField) return items

	const order = asc ? 'asc' : 'desc'
	if (sortingFn) return _.orderBy(items, sortingFn, order)
	const falsyItems = items.filter(i => !utils.exists(getValue(i, props)))
	const truthyItems = items.filter(i => utils.exists(getValue(i, props)))
	const sortedTruthyItems = _.orderBy(truthyItems, item => getValue(item, props), order)

	return [...sortedTruthyItems, ...falsyItems]
}

export const useSort = ({items, fields, defSortField, defOrder, sortingFns, sortMethod = sortItems, ...rest}) => {
	const [sortField, setSortField] = useState(defSortField)
	const [asc, setAsc] = useState(defOrder === 'desc' ? false : true)
	let sortedItems = items
	const changeSort = newField => {
		if (sortField === newField) {
			setAsc(!asc)
			return
		}
		setSortField(newField)
		setAsc(true)
	}

	if (!fields || fields.length > 0) {
		sortedItems =
			!fields || fields.includes(sortField) ? sortMethod(items, {sortField, asc, sortingFn: sortingFns?.[sortField], ...rest}) : items
	}

	return {changeSort, sortDirection: asc ? 'asc' : 'desc', sortedItems, asc, sortField}
}

export default useSort
