import utils from '@eitje/utils'
import {ListPicker} from 'common/components'
import {useModalContext} from 'components/ui'
import {usePayrollComponents} from 'cores/integrations'
import {listPickerPrettyPrint, prettyPrintArray} from 'helpers'
import {BaseConnection} from 'models'
import {ModalLink} from 'components/ui'
import {DeletePopout, EitjeButton, EitjeDropdown, Text} from 'common/components'
import {useGet} from 'hooks'
import {useLocation} from 'react-router-dom'

export const PayrollComponentPicker = ({envId, kind, ...rest}) => {
	const {environment_id} = useModalContext()
	const environmentId = envId || environment_id

	const connection = BaseConnection.find({environment_ids: [environmentId], hr: true})
	if (!connection.id || !connection.hasExportOption(kind)) return null

	return <Inner connection={connection} environment_id={environmentId} {...rest} />
}

const Inner = ({connection, environment_id, ...rest}) => {
	const {mappedComponents, groupField} = usePayrollComponents({envId: environment_id, connectionId: connection.id})
	return (
		<ListPicker
			required={false}
			field="integration_payroll_component_id"
			groupField={groupField}
			i18nOpts={{name: connection.name}}
			items={mappedComponents}
			single
			dropdown
			collapseGroups
			{...rest}
		/>
	)
}

export const AllowanceWeekDaysPicker = () => {
	const items = utils.weekdays.map((w, idx) => ({
		name: w,
		id: idx,
	}))
	return <ListPicker minWidth={150} dropdown multiline noSort field="relevant_days" items={items} title={listPickerPrettyPrint} />
}

export const AllowanceOptionsButton = () => {
	const {environment_id, allowance} = useModalContext()
	const loc = useLocation()
	const {data, isLoading} = useGet(`allowances/${allowance?.id}/shifts`, {skip: !allowance?.id, watch: loc.pathname})
	const {shift_count = 0} = data

	const elements = [
		{
			icon: 'pencil',
			element: (
				<ModalLink to={`/allowances/new?environment_id=${environment_id}&kind=${allowance?.kind}`}>
					<Text t="modals.allowances.new.title" />
				</ModalLink>
			),
		},
		!isLoading && {
			icon: 'trash',
			disabled: shift_count > 0 && 'modals.allowances.messages.delete_disabled',
			element: (
				<DeletePopout onDelete={() => allowance.destroy()}>
					<Text t="common.delete" />
				</DeletePopout>
			),
		},
	]
	return (
		<EitjeDropdown elements={elements}>
			<EitjeButton t="common.options" dropdown />
		</EitjeDropdown>
	)
}
