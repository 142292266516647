import {displayNoticationPrompt, handleUserOneSignalDisconnent, notificationRouting, saveOneSignalId} from 'actions/push_notifcations'
import {store} from 'index'
import {t} from 'initializers/i18n'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {selfSelector} from 'selectors/records'

const ONE_SIGNAL_KEY = process.env.REACT_APP_ONE_SIGNAL_ID
const ONE_SIGNAL_SAFARI_ID = process.env.REACT_APP_ONE_SIGNAL_SAFARI_ID

export const useSignalInit = () => {
	const handleNoticationClick = () => {
		window.OneSignal.push([
			'addListenerForNotificationOpened',
			function (data) {
				notificationRouting(data.data)
				handleNoticationClick() // function needs to be called again, else only the first notif click executes
			},
		])
	}

	useEffect(() => {
		if (window.OneSignal && ONE_SIGNAL_KEY && ONE_SIGNAL_SAFARI_ID) {
			window.OneSignal.push(function () {
				window.OneSignal.init({
					appId: ONE_SIGNAL_KEY,
					safari_web_id: ONE_SIGNAL_SAFARI_ID,
					promptOptions: {
						slidedown: {
							prompts: [
								{
									// this prompt is for safari and edge
									type: 'push',
									autoPrompt: false,
									text: {
										actionMessage: t('slideDownNotificationMessage'),
										acceptButton: t('Ok'),
										cancelButton: t('cancel'),
									},
								},
							],
						},
					},
					notifyButton: {
						enable: false,
					},
					allowLocalhostAsSecureOrigin: true,
					welcomeNotification: {disable: true},
					notificationClickHandlerMatch: 'origin',
					notificationClickHandlerAction: 'focus', // currently no effect on safari
				})
			})

			handleNoticationClick()
		}
	}, [])
}

export const useOneSignal = () => {
	const [notifState, setNotifState] = useState(false)
	const user = useSelector(selfSelector)

	const handleUserOneSignalConnect = () => {
		window.OneSignal.push(() => {
			window.OneSignal.getUserId(async onesignalid => {
				store.dispatch({type: 'ONE_SIGNAL_ID', id: onesignalid})
				await saveOneSignalId(user.id, onesignalid)
			})
		})
	}

	useEffect(() => {
		if (user.id && window.OneSignal) {
			window.OneSignal.push(function () {
				// Event listener when the user's subscription changes to a new value
				window.OneSignal.on('subscriptionChange', function (isSubscribed) {
					setNotifState(isSubscribed)
					if (isSubscribed) {
						handleUserOneSignalConnect()
					} else {
						handleUserOneSignalDisconnent()
					}
				})
			})
			window.OneSignal.push(() => {
				window.OneSignal.isPushNotificationsEnabled(isEnabled => {
					setNotifState(isEnabled)
					if (isEnabled) {
						handleUserOneSignalConnect()
						return
					}
				})
			})
		}
	}, [user.id])

	const handleOneSignalPrompt = e => {
		if (notifState) return handleUserOneSignalDisconnent()
		window.OneSignal.push(() => {
			window.OneSignal.isPushNotificationsEnabled(isEnabled => {
				// check for browser permissions
				if (isEnabled) {
					return handleUserOneSignalConnect() // execute the connect, event listener will hand the state change
				}
				return displayNoticationPrompt() // trigger the browser permission
			})
		})
	}

	return {handleOneSignalPrompt, notifState}
}

export const OneSignalConnector = ({children}) => {
	const {handleOneSignalPrompt, notifState} = useOneSignal()

	useEffect(() => {
		if (window?.OneSignal?.showNativePrompt || window?.OneSignal?.showSlidedownPrompt) {
			return displayNoticationPrompt()
		}
	}, [])

	return <div></div>
}
