export const androidUrl = 'https://play.google.com/store/apps/details?id=com.eitjeopdevloer'
export const iOSUrl = 'https://apps.apple.com/nl/app/eitjeopdevloer/id1446451276'
export const webUrl = 'https://vloer.eitje.app/'

export const CHECK_INS_TRANS = {
	android: androidUrl,
	ios: iOSUrl,
	web: webUrl,
}

export const CHECK_INS_DOCS = {
	setup: 6037228,
}
