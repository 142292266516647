import {useMergeState} from '@eitje/react-hooks'
import {Icon} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {DocsButton, Layout, Text, Title} from 'common/components'
import {EitjeModal} from 'components/common/index'
import {SimpleContextWrapper, useSimpleContext} from 'contexts/simple'
import {formatDateForBalance} from 'cores/balance/helpers'
import {childrenWithClonedProps} from 'helpers'
import {useGet, useParams, useShow} from 'hooks'
import makeDate from 'initializers/date'
import {t} from 'initializers/i18n'
import './styles/index.less'

const CONTAINER_PADDING = [24, 24, 32, 24]

export const Bill = ({kind, ...rest}) => {
	const {period_type, period_id} = useParams()
	const user = useShow('users', {idField: 'user_id'})

	const {balance, loading} = useGet(`users/${user.id}/balance/${kind}/detail?period_id=${period_id}&period_type=${period_type}`)

	return (
		<EitjeModal loading={loading} insert name="balance_bill" i18nOpts={{kind, user}} width={450}>
			<Layout direction="vertical" padding={CONTAINER_PADDING} gap={24}>
				<PeriodBlock {...balance} {...rest} />
				<CalculateBlock {...balance} {...rest} />
				<WarningBlock {...balance} {...rest} />
			</Layout>
		</EitjeModal>
	)
}

const ROW_PADDING = [2, 0]

export const Row = ({label, id, labelProps = {}, sub, value, sign, children}) => {
	const {setExpanded, expanded: allExpanded = {}} = useSimpleContext()
	const expanded = allExpanded[id]

	const bold = !!children && expanded

	const sharedProps = {bold, darkGrey: sub}
	children = children && children.filter(c => utils.exists(c))
	let condOpts = {}
	if (utils.exists(children)) {
		condOpts['onClick'] = () => setExpanded(id, !expanded)
	}

	const canExpand = utils.exists(children)

	return (
		<>
			<Layout colorSet={!sub} className="bill-row" {...condOpts} vertical="end" width="100%" padding={ROW_PADDING}>
				<Text truncate {...sharedProps} {...labelProps}>
					{label}
				</Text>
				<Layout className="bill-row-dotted-line" grow />
				<Layout className="bill-row-right-side" width={86}>
					<Layout horizontal={sign ? 'spaceBetween' : 'end'} grow>
						{sign && (
							<Text {...sharedProps} monospace>
								{sign}
							</Text>
						)}
						<Text {...sharedProps} monospace>
							{value}
						</Text>
					</Layout>
					<Layout width={20}>{canExpand && <Icon open={expanded} name="chevron-small-down" />}</Layout>
				</Layout>
			</Layout>
			{expanded && childrenWithClonedProps({children, props: {sub: true}})}
		</>
	)
}

export const SignedRow = ({value, ...rest}) => {
	const sign = value == 0 ? null : value > 0 ? '+' : '-'
	return <Row sign={sign} value={utils.minutesToString(value, {sign: false})} {...rest} />
}

const PeriodBlock = props => {
	const {period_type, period_id} = useParams()
	const periodLabel = formatDateForBalance(period_id, period_type)
	const {PeriodExtra, start_date, total_days, end_date} = props

	const title = `${t('common.period')}: ${periodLabel} `

	return (
		<Layout direction="vertical" width="100%" gap={0}>
			<Title children={title} />
			<Row label={t('common.start_period')} value={makeDate(start_date).format('DD-MM-YYYY')} />
			<Row label={t('common.end_period')} value={makeDate(end_date).format('DD-MM-YYYY')} />
			<Divider />

			<Row label={t('common.days')} value={total_days} />
			{PeriodExtra && <PeriodExtra {...props} />}
		</Layout>
	)
}

const MARGIN = [6, 0]

export const Divider = () => {
	return <Layout className="bill-divider" width="100%" height={1} margin={MARGIN} />
}

const CalculateBlock = props => {
	const {start_balance, CalculateExtra, CalculateTopExtra, adjustments = [], diff, start_date, end_date, end_balance} = props
	const totalAdjusted = adjustments.sum(i => i.amt)

	const [expanded, setExpanded] = useMergeState({})

	const _setExpanded = (id, val) => {
		setExpanded({[id]: val})
	}

	const adjustmentsLabel = `${t('adjustments')} (${adjustments.length})`

	const hasAdjustmentsRow = utils.exists(adjustments)
	return (
		<SimpleContextWrapper expanded={expanded} setExpanded={_setExpanded}>
			<Layout direction="vertical" width="100%" gap={0}>
				<Title t="common.calculation" />
				<SignedRow label={t('balance.bill.start_period', {date: start_date})} value={start_balance} />
				{CalculateTopExtra && <CalculateTopExtra {...props} />}

				<Divider />

				<CalculateExtra {...props} />

				{expanded && hasAdjustmentsRow && <Divider />}

				{hasAdjustmentsRow && (
					<SignedRow id="adjustments" label={adjustmentsLabel} value={totalAdjusted}>
						{adjustments.map(a => (
							<AdjustmentRow {...a} />
						))}
					</SignedRow>
				)}

				<Divider />
				<SignedRow label={t('common.diff_in_period')} value={diff} />

				<SignedRow label={t('balance.bill.end_period', {date: end_date})} value={end_balance} />
			</Layout>
		</SimpleContextWrapper>
	)
}

const AdjustmentRow = ({amt, date, ...rest}) => {
	const label = makeDate(date).formatDate({year: true})
	return <SignedRow value={amt} label={label} {...rest} />
}

const WarningBlock = ({hasWarning, WarningExtra, ...rest}) => {
	if (!hasWarning(rest)) return null
	return (
		<Layout direction="vertical" width="100%" gap={0}>
			<Title t="common.warning" />
			<WarningExtra {...rest} />
		</Layout>
	)
}

export * from './leave'
export * from './overtime'
