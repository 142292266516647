import {EitjeModal} from 'components/common/index'
import {TrainingResults} from '../../results/components'
import {useShared} from 'hooks'
import {TopicProvider} from 'cores/knowledge'

export const TrainResults = () => {
	const {me} = useShared()

	return (
		<TopicProvider>
			<EitjeModal name="train_results" goBackToForeground width={450}>
				<TrainingResults userId={me.id} />
			</EitjeModal>
		</TopicProvider>
	)
}
