import {ProtectedRoute, Route} from 'components/private_route'
import {Firms, CreateTeam, CreateEnvironment, UsersInService, UsersOutOfService} from '.'
import {AllowanceIndex} from 'cores/time_registration/pages/allowances/index'
import {EmploymentTypes} from 'cores/settings/pages/employment_types'

export const firmRoutes = [
	<ProtectedRoute path="/environments/:environment_id/employment_types" roleInAnyEnv="admin" component={EmploymentTypes} />,

	<ProtectedRoute roleInAnyEnv="admin" path="/environments/:environment_id/allowances" component={AllowanceIndex} />,
	<ProtectedRoute roleInAnyEnv="manager" path="/environments" component={Firms} />,
	<ProtectedRoute roleInAnyEnv="manager" path="/team/new" component={CreateTeam} />,
	<ProtectedRoute roleInAnyEnv="admin" path="/environment/new" component={CreateEnvironment} />,
]

export const userForegroundRoutes = [
	<Route path="/users/in_service" component={UsersInService} />,
	<ProtectedRoute roleInAnyEnv="manager" path="/users/out_of_service" component={UsersOutOfService} />,
]
