import {PopoutCard} from '@eitje/web_components'
import {t as _t} from 'initializers/i18n'
import {Layout, Title, titleWithCount, SearchInput} from 'common/components'
import {UserList} from './user_list'
import useSearch from 'hooks/use_search'

export const UserListPopout = ({users, showSearch = users.length >= 20, ...rest}) => {
	if (showSearch) return <UserListPopoutWithSearch users={users} {...rest} />

	return <UserListPopoutInner users={users} {...rest} />
}

const UserListPopoutWithSearch = ({users, ...rest}) => {
	const {filteredItems, search, setSearch, emptyElement} = useSearch(users, {
		showSearch: true,
		searchField: 'full_name',
		searchEmptyProps: {layoutProps: {width: 'full'}},
	})

	const handleSearch = e => setSearch(e.target.value)
	const handleClear = () => setSearch(null)

	return (
		<UserListPopoutInner
			users={filteredItems}
			searchInput={<SearchInput onChange={handleSearch} onClear={handleClear} value={search} />}
			body={emptyElement}
			{...rest}
		/>
	)
}

const UserListPopoutInner = ({users, t = 'users', onItemClick, hideTitle, searchInput, body, ...rest}) => {
	const title = !hideTitle && titleWithCount(_t(t), users.length)

	return (
		<PopoutCard
			bodyPadding={0}
			placement="right-start"
			maxHeight={400}
			width={300}
			{...rest}
			body={body || <UserList users={users} onItemClick={onItemClick} />}
			header={
				<>
					{title && (
						<Layout padding="12" width="full" borderBottom>
							<Title t={title} />
						</Layout>
					)}
					{searchInput && (
						<Layout padding="8" width="full" borderBottom>
							{searchInput}
						</Layout>
					)}
				</>
			}
		/>
	)
}
