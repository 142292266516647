import {useRoleEnvs} from 'hooks'
import {RevenueGroup} from 'models'
import {RevenueGroupForm} from './form'
import {NewSideMenuModal} from 'common/components'

export const RevenueGroups = () => {
	const envs = useRoleEnvs('admin')
	const groups = RevenueGroup.all()
	const routes = envs.map(e => {
		const envGroups = groups.filter(g => g.environment_id == e.id)

		const subRoutes = envGroups.map(group => ({
			name: group.naam,
			id: group.id,
			envGroups,
			group,
			path: group.id,
			component: RevenueGroupForm,
		}))
		return {name: e.naam, path: e.id, subRoutes}
	})

	return <NewSideMenuModal hideCancel name="settings.revenue_groups" basePath="revenue_groups" routes={routes} />
}
