import {ModalWithNewForm} from 'components/form/new_modal_form'
import {Input} from '@eitje/form-fields-web'
import {URIInput} from 'lib/form/fields'
import {NAMESPACE} from 'common/components/rich_text_editor'

export const LinkFormModal = ({initialValues, onClose, onSubmit}) => {
	const ns = `${NAMESPACE}.link`
	const modalProps = {
		goBack: onClose,
		onCancel: onClose,
	}

	return (
		<ModalWithNewForm name={ns} width={450} initialValues={initialValues} onSubmit={onSubmit} modalProps={modalProps}>
			<URIInput field="url" required />
			<Input field="text" required />
		</ModalWithNewForm>
	)
}
