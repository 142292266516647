import {EitjeButton} from 'common/components'
import {useQueryParams, useShared} from 'hooks'
import utils from '@eitje/utils'
import {useBalanceStartDateRaw} from 'cores/balance'

export const usePlaceholderProps = ({kind, hasLeave}) => {
	const {out_of_service} = useQueryParams()
	const dates = useBalanceStartDateRaw()
	const {envId} = useShared()

	if (out_of_service) {
		return {
			animation: 'waving-people',
			name: 'users_out_of_service',
		}
	}

	if (kind == 'leave' && !hasLeave) {
		return {
			name: 'balance.no_leave',
			docsId: '6019469',
			children: [
				<EitjeButton iconLeft="three-sliders" modalLink={`/environments/${envId}/employment_types/fixed/leave`} t="setup_fixed_leave" />,
				<EitjeButton iconLeft="three-sliders" modalLink={`/environments/${envId}/employment_types/flex/leave`} t="setup_flex_leave" />,
			],
			animation: 'calendar',
		}
	}

	if (!utils.exists(dates)) {
		return {
			name: 'balance.no_date',
			docsId: '6019469',
			children: <EitjeButton iconLeft="three-sliders" colorSet="solid" modalLink="/balance/setup" t="setup" />,
			animation: 'calendar',
		}
	}
}
