import {useEffect, Suspense} from 'react'
import {checkAuth} from 'actions/auth'
import utils from '@eitje/web_utils'
import {Spin} from 'antd'
import ErrorBoundary from 'components/error_boundary'
import {PrivateRoute, ProtectedRoute} from 'components/private_route'
import AuthProvider from 'components/providers/auth_provider'
import {setGlobalData} from 'helpers/window_helper'
import {useSignalInit} from 'hooks/use_one_signal'
import {ENV} from 'initializers/api'
import {t} from 'initializers/i18n'
import {setTimezone} from 'initializers/date'
import 'initializers/pluralize'
import 'legacy.js'
import 'moment/locale/nl'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/nl'
import ReactGA from 'react-ga'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import exportRoutes from 'routes/exports'
import dynamicExportRoutes from 'cores/exports/routes'
import {backgroundRoutes as billingRoutesBackground, foregroundRoutes as billingRoutesForeground} from 'cores/billing/routes'
import {chatForegroundRoutes, chatBackgroundRoutes} from 'cores/chat/routes'
import {ChatWindow} from 'cores/chat'
import {devBackgroundRoutes, devForegroundRoutes} from 'cores/dev/routes'
import {avForegroundRoutes, avBackgroundRoutes} from 'routes/availability'
import {planningForegroundRoutes, planningBackgroundRoutes} from 'cores/planning/routes'
import {onboardingForegroundRoutes, onboardingBackgroundRoutes} from 'cores/onboarding/routes'
import {timeRegistrationForegroundRoutes, timeRegistrationBackgroundRoutes} from 'cores/time_registration/routes'
import {integrationForegroundRoutes, integrationBackgroundRoutes} from 'cores/integrations/routes'
import {leaveForegroundRoutes, leaveBackgroundRoutes} from 'cores/leave/routes'
import {supportBackgroundRoutes, supportForegroundRoutes} from 'cores/support'
import {exportForegroundRoutes, exportBackgroundRoutes} from 'cores/exports2/routes'
import {authRoutes, afterAuthRoutes, alwaysAvailableAuthRoutes, useRedirectWhenOffline} from 'cores/auth'
import {balanceForegroundRoutes, balanceBackgroundRoutes} from 'cores/balance'
import {revenueBackgroundRoutes} from 'cores/revenue/routes'
import {settingsBackgroundRoutes} from 'cores/settings/routes'
import {knowledgeForegroundRoutes, knowledgeBackgroundRoutes} from 'cores/knowledge/routes'
import {envSelector} from 'selectors/entities'
import {currentEnvSelector, currentOrgSelector} from 'selectors/records'
import {useExportTranslations} from 'cores/exports2'
import 'styles/app/html_styles.less'
import './styles/app/app.less'
import 'styles/app/styles/spacing/legacy/paddings.less'
import 'styles/app/styles/spacing/legacy/margins.less'
import 'styles/app/styles/spacing/legacy/gaps.less'
import 'styles/app/components/avatar.less'
import 'styles/app/styles/containers/legacy/dynamic_container_classes.less'
import 'styles/app/styles/helpers/tmp.less'
import {isProd} from 'constants/general'
import useEnvSetting from 'hooks/use_env_setting'
import 'styles/form/index.less'
import {commonBackgroundRoutes} from 'common/routes'
import {useGlobalDebug} from 'hooks/use_global_debug'
import {DebugProvider} from 'contexts/debug'
import {InboxModal} from 'cores/firm'
import {Spinner} from 'common/components/spinner_overlay'
import {useCatchCssChunkError} from 'hooks/use_catch_css_chunk_error'
import InviteModal from 'components/forms/invite_form'
import Bedrijf from 'pages/bedrijf'
import Lightspeed from 'pages/lightspeed'
import EventForm from 'pages/modals/event'
import NewContract from 'pages/modals/new_contract'
import PostForm from 'pages/modals/post'
import ScheduledPosts from 'pages/modals/scheduled_posts'
import SkillSetForm from 'pages/modals/skill_set'
import UserPref from 'pages/modals/user_prefs'
import UserSkills from 'pages/modals/user_skills'
import MollieConsent from 'pages/mollie/consent'
import Availability from 'cores/availability/pages/index/page'
import UserUren from 'pages/rooster/user_uren'
import NotFound from 'pages/system/not_found'
import NewNews from 'pages/v3/news/index'
import Skills from 'pages/v3/skills/index'
import ContractModal from 'pages/v3/user/contracts/modal/base'
import User from 'pages/v3/user/index'
import {firmRoutes, userForegroundRoutes} from 'cores/firm'
import {NotificationsContainer, EitjeSpinner} from 'common/components'
import {useHomePage} from 'hooks/use_home_page'
import 'initializers/version_check'
import {checkInBackgroundRoutes} from 'cores/check_ins/routes'

const EnvSwitch = () => {
	return (
		<div>
			<div className="loadingDudes">
				<img className="logoLoading" style={{marginTop: 160}} src="/images/logoCircle.png" />

				<p>{t('switchEnv')}</p>
				<span>{t('switchEnv2')}</span>

				<Spin style={{position: 'absolute', top: '58%', left: '50%'}} spinning={true}></Spin>
			</div>
		</div>
	)
}

const noAuthRoutes = <Suspense fallback={<Spinner delay={500} />}>{authRoutes}</Suspense>

const getRoutes = loc => {
	const hasAuth = checkAuth()
	if (hasAuth) {
		return noAuthRoutes
	} else {
		return (
			<Suspense fallback={<Spinner delay={500} />}>
				<Switch location={loc}>
					{authRoutes}
					<Redirect to="/" />
					{devForegroundRoutes}
				</Switch>
				<Switch>{devBackgroundRoutes}</Switch>
			</Suspense>
		)
	}
}

const WindowSetter = () => {
	const env = useSelector(envSelector)
	const org = useSelector(currentOrgSelector)

	useEffect(() => {
		setGlobalData({envName: env?.naam, isMultiEnv: org?.environment_ids?.length > 1})
	}, [env?.id])

	return null
}

const App = () => {
	const location = useLocation()
	const env = useSelector(currentEnvSelector)

	let background = location.state && location.state.background
	if (background) background = {...background, isBackground: true, foreground: _.omit(location, 'state')}

	useEffect(() => {
		ENV === 'production' && ReactGA.initialize('UA-70766051-4')
	}, [])

	useEffect(() => {
		setTimezone(env.timezone)
	}, [env?.timezone])

	useGlobalDebug()

	useExportTranslations()

	useSignalInit()

	useCatchCssChunkError()

	const avDisabled = useEnvSetting('beschikbaarheid', 'disabled')

	const homePageLink = useHomePage()
	const HomePage = () => <Redirect to={homePageLink} />
	const {NotificationProvider} = utils

	useRedirectWhenOffline()

	return (
		<ErrorBoundary>
			<DebugProvider>
				<NotificationProvider>
					<NotificationsContainer />
					<EitjeSpinner>
						<div id="auth_page">{getRoutes(background || location)}</div>
						<Switch>{alwaysAvailableAuthRoutes}</Switch>
						<AuthProvider>
							<Suspense fallback={<Spinner delay={500} />}>
								<WindowSetter />
								<Switch location={background || location}>
									<PrivateRoute path="/home_page" component={HomePage} exact />
									<PrivateRoute path="/news" component={NewNews} exact />
									<PrivateRoute path="/" component={NewNews} exact />
									{knowledgeForegroundRoutes}
									<ProtectedRoute path="/skills" component={Skills} exact role="manager" />
									<PrivateRoute path="/user/:id" component={User} />
									{!avDisabled && <ProtectedRoute path="/availability" roleInAnyEnv="manager" component={Availability} exact />}
									<PrivateRoute path="/user_uren" component={UserUren} exact />
									<PrivateRoute path="/env_switch" component={EnvSwitch} exact />
									<ProtectedRoute path="/bedrijf" component={Bedrijf} exact role="admin" />
									<PrivateRoute path="/oauth/lightspeed" component={Lightspeed} exact />
									<PrivateRoute path="/mollie_consent" component={MollieConsent} exact />
									{balanceForegroundRoutes}
									{avForegroundRoutes}
									{afterAuthRoutes}
									{planningForegroundRoutes}
									{timeRegistrationForegroundRoutes}
									{billingRoutesForeground}
									{integrationForegroundRoutes}
									{leaveForegroundRoutes}
									{supportForegroundRoutes}
									{exportForegroundRoutes}
									{!isProd && devForegroundRoutes}
									{userForegroundRoutes}
									{onboardingForegroundRoutes}
									{chatForegroundRoutes}
									<PrivateRoute path="/notif_opened" component={NewNews} />
									<Route component={NotFound} />
								</Switch>

								<Switch>
									{billingRoutesBackground}
									{firmRoutes}
									<ProtectedRoute roleInAnyEnv={['manager', 'uren_accorderen', 'financieel']} path="/inbox" component={InboxModal} />
									<PrivateRoute path="/forms/user_preferences/:id" component={UserPref} />
									<PrivateRoute path="/forms/events/:id" component={EventForm} />
									<PrivateRoute path="/forms/events" component={EventForm} />
									<PrivateRoute path="/forms/user_skills" component={UserSkills} />
									<PrivateRoute path="/forms/skill_set" component={SkillSetForm} />
									<PrivateRoute path="/forms/posts/:id" component={PostForm} />
									<PrivateRoute path="/forms/new_contract/:id" component={NewContract} role="contracten" />
									<PrivateRoute path="/forms/contracts/:id" component={ContractModal} role="contracten" />
									<PrivateRoute path="/news/scheduled_posts" component={ScheduledPosts} exact />
									<ProtectedRoute path="/forms/invite" component={InviteModal} roleInAnyEnv="manager" />
									{checkInBackgroundRoutes}
									{supportBackgroundRoutes}
									{dynamicExportRoutes}
									{exportRoutes}
									{avBackgroundRoutes}
									{planningBackgroundRoutes}
									{onboardingBackgroundRoutes}
									{timeRegistrationBackgroundRoutes}
									{revenueBackgroundRoutes}
									{integrationBackgroundRoutes}
									{leaveBackgroundRoutes}
									{settingsBackgroundRoutes}
									{commonBackgroundRoutes}
									{exportBackgroundRoutes}
									{balanceBackgroundRoutes}
									{chatBackgroundRoutes}
									{knowledgeBackgroundRoutes}
									{!isProd && devBackgroundRoutes}
								</Switch>
							</Suspense>
							<ChatWindow />
						</AuthProvider>
					</EitjeSpinner>
				</NotificationProvider>
			</DebugProvider>
		</ErrorBoundary>
	)
}

export default App
