import {ListPicker} from 'common/components'
import {NewModalForm} from 'components/form/new_modal_form'
import {CheckInExplainButton} from './components'
import {saveSettingGroup} from 'actions/settings'
import {useEnvSettingGroup, useFormData} from 'hooks'
import {BasicPopout} from '@eitje/web_components'
import {t} from 'initializers/i18n'

const roundTypes = ['round', 'floor', 'ceil']
const roundOptions = [{id: 0, name: '0'}, 5, 10, 15]
const formBase = 'form.settings.check_ins.fields'

const fieldProps = {noSort: true, dropdown: true, single: true, allowClear: false}

export const Rounding = ({id}) => {
	const submit = data => saveSettingGroup('vloer', data, {env_id: id})
	const initialValues = useEnvSettingGroup('vloer', id)

	return (
		<NewModalForm
			fieldProps={fieldProps}
			initialValues={initialValues}
			onSubmit={submit}
			modalProps={{buttons: [<CheckInExplainButton />], width: 600}}
		>
			<Body />
		</NewModalForm>
	)
}

const Body = () => {
	const {roundAmt, end_round_amt} = useFormData()
	const roundOptionsStart = [...roundOptions, {id: 'planning', name: 'planning', tooltip: t(`${formBase}.roundAmt.planning_tooltip`)}]
	const roundOptionsEnd = [...roundOptions, {id: 'planning', name: 'planning', tooltip: t(`${formBase}.end_round_amt.planning_tooltip`)}]
	const disabled = roundAmt == 'planning' && end_round_amt == 'planning' && `${formBase}.roundType.disabled`
	return (
		<>
			<ListPicker
				disabled={disabled}
				tooltip={!disabled}
				popoutBody={!disabled}
				dropdown
				items={roundTypes}
				field="roundType"
				PopoutComponent={BasicPopout}
			/>
			<ListPicker minWidth={200} items={roundOptionsStart} field="roundAmt" />
			<ListPicker minWidth={200} items={roundOptionsEnd} field="end_round_amt" />
		</>
	)
}
