import {useEffect} from 'react'
import {EitjeButton} from 'common/components'
import {useModalContext} from 'components/common/index'
import {useParams} from 'hooks'
import {useTopicContext, NAMESPACE} from 'cores/knowledge'
import {TrainingResults} from './components'
import {User} from 'models'

export const ResultsUser = () => {
	const {userId} = useParams()
	const user = User.find(userId)
	const {setModalProps} = useModalContext()
	const {createTopicPrompt} = useTopicContext()
	const {disabled, tooltip} = useTrainAgainDisabledMessage(userId)

	useEffect(() => {
		const handleClick = () => createTopicPrompt(user)

		const buttons = [
			<EitjeButton
				name={NAMESPACE}
				t="train_again"
				colorSet="solid"
				iconLeft="gym-barbell"
				disabled={disabled}
				tooltip={tooltip}
				onClick={handleClick}
			/>,
		]
		setModalProps({buttons})
	}, [userId, tooltip, disabled])

	return <TrainingResults userId={userId} />
}

function useTrainAgainDisabledMessage(userId) {
	const {team_ids, finished} = useTopicContext()
	const isCurrentTeammember = !!User.where({id: userId, team_ids}).first()

	if (!isCurrentTeammember) return {disabled: true, tooltip: `${NAMESPACE}.train_again_tooltip_disabled_former_users`}

	const hasFinished = finished?.some(s => s === userId)
	const tooltip = hasFinished ? `${NAMESPACE}.train_again_tooltip` : `${NAMESPACE}.train_again_tooltip_disabled`

	return {disabled: !hasFinished, tooltip}
}
