import {NewSideMenuModal} from 'common/components'
import {ROLES} from 'cores/settings/constants'
import {useRoleOrgEnvs} from 'hooks'
import {RoleForm} from './form'
import {t} from 'initializers/i18n'

export const Roles = () => {
	const routes = ROLES.map(r => ({
		name: r,
		label: t(`rolesEnum.${r}`),
		component: RoleForm,
	}))

	return <NewSideMenuModal sideMenuProps={{showSearch: false}} name="settings.roles" basePath="settings/roles" routes={routes} />
}
