import utils from '@eitje/web_utils'
import {getIntegrationResources} from 'cores/integrations'
import {possibleIntegs} from 'cores/integrations/constants'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import ActiveRecord from 'models/active_record/base'

class BaseConnection extends ActiveRecord {
	static defaultJoins = ['integrationExtEnvironments']
	static tableName = 'integrationBaseConnections'

	constructor(props) {
		super(props)
		const data = possibleIntegs.find(int => int.id == this.sanitized_type) || {}
		const keys = Object.keys(data).filter(k => k != 'id')
		keys.forEach(prop => (this[prop] = data[prop]))
	}

	hasExportOption(name) {
		const exp = this.export
		if (!exp || !exp.hide) return true
		return !exp.hide.includes(name)
	}

	exportHours(data) {
		return this.resourceReq('export_hours', {...data, method: 'POST'})
	}

	sync = async (_date = date().format()) => {
		const res = await this.resourceReq('sync', {date: _date})
		if (res.ok) {
			const typ = this.type
			utils.successNotif({
				title: t(`integrations.${typ}.syncing_now.title`),
				content: t(`integrations.${typ}.syncing_now.body`),
				duration: 5,
			})
		}
		return res
	}

	hasIssue() {
		return this.authIssue()
	}

	authIssue() {
		return this.last_sync_datetime && !this.authed
	}

	isHr() {
		return this.type == 'hr'
	}

	isPos() {
		return this.type == 'pos'
	}

	isLoyalty() {
		return this.type == 'loyalty'
	}

	exportUser(data) {
		return this.resourceReq('export_user', data)
	}

	hasSettings() {
		return this.sanitized_type == 'nmbrs'
	}

	baseLink = () => `/integrations/${this.sanitized_type}/${this.id}`
	makeLink = suffix => `${this.baseLink()}/${suffix}`

	exportHoursUrl = () => this.makeLink('export_hours')
	issuesUrl = () => this.makeLink('fix_provider_issues')
	reAuthUrl = () => `/integrations/${this.sanitized_type}/activate?connection_id=${this.id}`
	exportUsersUrl = () => `/integrations/fooks/${this.id}/export_users`
	syncHistoryUrl = () => this.makeLink('sync_history')

	setupUrl() {
		if (this.isLoyalty()) return this.makeLink('setup_base')
		const suffix = this.isHr() ? 'hr' : 'revenue'
		return this.makeLink(`setup_${suffix}`)
	}

	// revenue

	updateGroupingType = async typ => {
		const res = await this.resourceReq('update_grouping_type', {grouping_type: typ})
		getIntegrationResources()
		return res
	}

	perSalesArea() {
		return this.grouping_type == 'sales_areas'
	}
}

export default BaseConnection
