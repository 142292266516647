import {BasicPopout, CreateButton, DeleteButton, Layout, Message, Text} from 'common/components'
import {useModalContext, useSetModalPropsRaw} from 'components/ui'
import {CHECK_INS_TRANS} from 'cores/check_ins'
import {mdT, t as baseT} from 'initializers/i18n'
import {AuthorizedDevice} from 'models'
import {CheckInExplainButton, CheckInFilmButton} from './components'

export const Devices = ({id}) => {
	AuthorizedDevice.index()
	const devices = AuthorizedDevice.where({environment_id: id}).sortBy(['authorized', 'updated_at'], ['asc', 'desc'])
	const isAuthorized = devices.some(d => d.authorized)
	const messageKey = !isAuthorized ? 'unauthorized_message' : 'authorized_message'
	const messageProps = !isAuthorized ? {colorSet: 'black-bordered'} : {colorSet: 'color-bordered-outline'}
	const {namespace, t} = useModalContext()

	const onCreate = async () => AuthorizedDevice.create({environment_id: id})

	useSetModalPropsRaw({
		buttons: [<CheckInFilmButton />, <CheckInExplainButton />, <CreateButton onClick={onCreate} t={`${namespace}.devices.add`} />],
	})

	const inlineStyle = {overflowY: 'auto'}
	// width=500 is strange, you can pass any value to width and it works, only if you don't pass a value to width it suddenly breaks.
	return (
		<Layout width={600} direction="vertical" height="full" style={inlineStyle}>
			<Message {...messageProps}>{integrateTranslation(devices[0], messageKey)}</Message>

			<HeaderRow />
			{devices.map(d => (
				<Device showDelete={devices.length > 1} item={d} />
			))}
		</Layout>
	)
}

const integrateTranslation = (device, key = 'unauthorized_message') =>
	mdT(`modals.settings.check_ins.devices.${key}`, {...device, ...CHECK_INS_TRANS})

const Device = ({item, showDelete}) => {
	const {name, vloer_key, vloer_secret, environment_id} = item
	const {namespace, t} = useModalContext()
	const nameText = name || baseT('common.not_integrated')
	let condOpts = {}
	if (!name) {
		condOpts['popoutTitle'] = `${namespace}.integrate_device`
		condOpts['popoutBody'] = integrateTranslation(item)
		condOpts['PopoutComponent'] = BasicPopout
	}
	return (
		<Layout height={48} padding="0 12" borderBottom="1" width="full">
			<Row children={nameText} {...condOpts} />
			<Row className="unset-text-transform" children={vloer_key} />
			<Row className="unset-text-transform" children={vloer_secret} />
			<Row grow children={environment_id} />
			{showDelete && <DeleteButton confirmText={t('devices.delete')} t={null} onDel={() => item.destroy()} />}
		</Layout>
	)
}

const HeaderRow = () => {
	const {t} = useModalContext()
	return (
		<Layout height={48} padding="0 12" border="1" width="full">
			<Row bold children={t('devices.device')} />
			<Row bold>{t('devices.username')}</Row>
			<Row bold>{t('devices.password')}</Row>
			<Row bold grow>
				{t('devices.environment_id')}
			</Row>
		</Layout>
	)
}

const Row = ({width = 140, grow, ...rest}) => {
	return (
		<Layout grow={grow} width={width}>
			<Text {...rest} />
		</Layout>
	)
}
