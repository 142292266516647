import utils from '@eitje/utils'
import {makeRange} from 'helpers/date'
import date from 'initializers/date'
import {t} from 'initializers/i18n'
import ActiveRecord from 'models/active_record/base'

class Allowance extends ActiveRecord {
	static tableName = 'allowances'

	irregularModifier(timeRange) {
		const ranges = this.getRanges(timeRange.start)

		const overlappingMins = ranges.map(r => timeRange.intersect(r)?.diff('minutes') || 0).sum()

		let overlap = overlappingMins / timeRange.minutes()
		if (overlap > 1) overlap = 1
		return overlap * this.amount
	}

	getRanges(date) {
		const {relevant_days, start_time, end_time} = this
		return relevant_days.map(weekday => {
			const newDate = date.clone().weekday(weekday)
			return makeRange(`${newDate.format()}, ${start_time}`, `${newDate.format()} ${end_time}`)
		})
	}

	isFunction() {
		return this.kind == 'regular'
	}

	isIrregular() {
		return this.kind == 'irregular'
	}

	activeOnAnyDay(weekdays) {
		if (!this.start_time) return false
		weekdays = utils.alwaysDefinedArray(weekdays)
		return utils.intersects(this.allDays(), weekdays)
	}

	allDays() {
		return this.all_days || this.relevant_days || []
	}

	label() {
		if (this.name) return `${t('functionAllowance')} "${this.name}"`
		if (this.isIrregular()) {
			const {start_time, end_time, relevant_days} = this
			const weekDays = relevant_days
				.sort()
				.map(r => date().nextWeekday(r).format('dd'))
				.join(', ')

			return `${t('irregAllowance')} ${weekDays} | ${start_time} - ${end_time}`
		}
		return t('holiAllowance')
	}

	shortName() {
		return this.relevant_days
			.sort()
			.map(r => date().nextWeekday(r).format('dd'))
			.join(', ')
	}
}

export default Allowance
