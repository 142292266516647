import {DeletePopout, DocsButton, EitjeButton, EitjeDropdown, Text} from 'common/components'
import {BasicPopout} from '@eitje/web_components'
import {NewModalForm} from 'components/form/index'
import {ModalLink} from 'components/routing'
import {useModalContext} from 'components/ui'
import {t} from 'initializers/i18n'
import {Input} from '@eitje/form-fields-web'
import {NewFormInputNumber} from 'components/form/input_number'
import {RevenueTeam} from 'models'
import {REVENUE_DOCS} from 'cores/revenue/constants'

export const RevenueGroupForm = ({group, envGroups}) => {
	const {namespace} = useModalContext()

	const onDelete = async () => {
		await group.destroy()
		RevenueTeam.index() // need to fetch the revenue teams due to changing fracts
	}

	const elements = [
		{
			icon: 'plus',
			element: (
				<ModalLink to={`/revenue_groups/new?environment_id=${group.environment_id}`}>
					<Text t="common.revenue_group" />
				</ModalLink>
			),
		},
		{
			icon: 'trash',
			disabled: envGroups.length == 1 && `${namespace}.delete_disabled`,
			element: (
				<DeletePopout onDelete={onDelete} title={t(`${namespace}.delete_popout.title`)} body={t(`${namespace}.delete_popout.content`)}>
					<Text t="common.delete" />
				</DeletePopout>
			),
		},
	]

	return (
		<NewModalForm
			initialValues={group}
			onSubmit={data => group.update(data)}
			modalProps={{
				buttons: [<WageCostButton />, <OptionsButton elements={elements} />, <DocsButton to={REVENUE_DOCS.revenue_groups} />],
			}}
		>
			<Input field="naam" required />
			<NewFormInputNumber max={100} maxDigits={0} field="streef_pct" tooltip popoutBody PopoutComponent={BasicPopout} />
		</NewModalForm>
	)
}

const WageCostButton = () => <EitjeButton t="common.wage_costs" modalLink="/revenue_teams" iconLeft="three-sliders" />

const OptionsButton = ({elements}) => (
	<EitjeDropdown elements={elements}>
		<EitjeButton t="common.options" dropdown />
	</EitjeDropdown>
)
