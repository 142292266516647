import {useEffect, useRef, useState} from 'react'
import {Layout, Text, Icon} from 'common/components'
import './styles/index.less'

export const Message = ({margin = 8, t, children, colorSet = 'color-bordered-fill', icon, textProps = {}, ...rest}) => {
	const [messageHeight, setMessageHeight] = useState(0)
	const layoutRef = useRef()
	const multiLine = messageHeight > 32

	useEffect(() => {
		setMessageHeight(layoutRef.current?.clientHeight)
	}, [children])

	return (
		<Layout
			direction={multiLine && icon ? 'vertical' : 'horizontal'}
			ref={layoutRef}
			horizontal="center"
			className="message"
			colorSet={colorSet}
			padding="4 8"
			margin={margin}
			border
			borderRadius
			minHeight={32}
			width={null} // needed, because otherwise layout gets 100% width due to direction prop
			{...rest}
		>
			{icon && <Icon name={icon} />}
			<Text t={t} {...textProps}>
				{children}
			</Text>
		</Layout>
	)
}
