import {useFormData} from 'hooks'
import {ListPicker} from 'common/components'
import {TeamField, PublishPeriodsField, CloseAvailabilityField} from './fields'
import {PublishMessage} from './publish_message'

const periodTypes = ['week', 'month']

export const PublishForm = () => {
	const {team_ids} = useFormData()
	const showPeriodAndAvailabilty = !!team_ids?.length

	return (
		<>
			<PublishMessage />
			<TeamField />
			<ListPicker single field="periodType" dropdown items={periodTypes} required noSort />
			{showPeriodAndAvailabilty && (
				<>
					<PublishPeriodsField />
					<CloseAvailabilityField />
				</>
			)}
		</>
	)
}
