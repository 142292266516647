import {usePageContext} from 'common/components'
import {kindMap} from 'cores/balance'
import {date} from 'initializers/date'
import {BalanceAdjustment} from 'models'
import {getContractItems, transformBalance} from './helpers'

export const useData = () => {
	const {balance, startDate, endDate, terminationDate, envStartDate, environment_id, user, kind, periodType} = usePageContext()
	const _terminationDate = terminationDate && date(terminationDate)

	const empTypeItems = getContractItems(user, environment_id, kind, endDate)
	const transformed = transformBalance(balance, periodType, {empTypeItems, terminationDate})

	const typ = kindMap[kind]
	const adjustments = BalanceAdjustment.doIndex({filters: {user_id: user.id}}, {watch: [user.id]}).where({
		initial: null,
		typ,
		user_id: user.id,
	})

	const initial = BalanceAdjustment.find({typ, user_id: user.id, initial: true})

	const initialAdjustment = {
		...initial,
		date: startDate.format(),
		label: startDate.formatDate({year: true}),
		typeId: 'initial_balance',
		terminatedTooltip: !!terminationDate,
		clickable: true,
	}

	const terminationDateObject = terminationDate && {
		date: _terminationDate.clone().add(20, 'year').format(),
		label: terminationDate,
		typeId: 'termination_date',
	}

	const mappedAdjustments = adjustments
		.filter(a => envStartDate.isSameOrBefore(a.date) && a.amt != 0)
		.map(a => ({...a, date: a.date > endDate ? endDate : a.date, typeId: 'adjustment', clickable: true}))

	const final = [...empTypeItems, initialAdjustment, ...mappedAdjustments, ...transformed, terminationDate && terminationDateObject].filter(
		Boolean,
	)

	return _.orderBy(final, 'date').reverse()
}
