import {EitjeVirtuoso} from '@eitje/web_components'
import {Layout, usePageContext} from 'common/components'
import {BasicTableRow, useBasicTableContext} from 'common/components/basic_table'
import {EndOfContentPlaceholder} from './end_of_content_placeholder'
import {Placeholder} from './placeholder'
import './index.less'

const sharedProps = {
	className: 'basic-table-body',
	direction: 'vertical',
	width: 'full',
	gap: 0,
	borderLeft: true,
	borderRight: true,
	borderBottom: true,
	grow: true,
	borderRadius: '0 0 4px 4px',
	style: {
		alignItems: 'unset',
	},
}

export const BasicTableBody = () => {
	const {floating} = usePageContext()

	const {data, hasData, columns, endOfContentPlaceholder, virtual} = useBasicTableContext()

	if (!hasData) {
		return (
			<Layout {...sharedProps}>
				<Placeholder />
			</Layout>
		)
	}

	const renderRow = (index, item) => <BasicTableRow key={item.id} item={item} columns={columns} index={index} />

	if (!virtual) {
		return (
			<Layout {...sharedProps}>
				{data.map((item, index) => renderRow(index, item))}
				{endOfContentPlaceholder && <EndOfContentPlaceholder />}
			</Layout>
		)
	}

	const virtuosoProps = floating && {
		useWindowScroll: true,
		customScrollParent: '.page-layout',
	}

	return (
		<Layout {...sharedProps}>
			<EitjeVirtuoso
				data={data}
				components={{Footer: endOfContentPlaceholder && EndOfContentPlaceholder}}
				itemContent={renderRow}
				{...virtuosoProps}
			/>
		</Layout>
	)
}
