export const NAMESPACE = 'knowledge'

export const KNOWLEDGE_DOCS = {
	collection: 4588528,
	write_article: 6014511,
	attach_pdf: 6022807,
	create_topic: 6009233,
	create_quiz: 6022818,
	activate_training: 6015044,
}
