import {NAMESPACE, KNOWLEDGE_DOCS} from 'cores/knowledge'

export const columns = [
	{
		accessorKey: 'full_name',
		docsId: KNOWLEDGE_DOCS.create_topic,
	},
	{
		accessorKey: 'status',
		tooltip: true,
	},
	{
		id: 'read_count',
		accessorFn: ({readCount, infoCount}) => {
			if (infoCount) return `${readCount} / ${infoCount}`
		},
		sortingFn: ({readCount}) => readCount,
	},
	{
		accessorKey: 'score',
		popout: {
			body: `${NAMESPACE}.average_score_explanation`,
		},
		docsId: KNOWLEDGE_DOCS.create_topic,
	},
]
