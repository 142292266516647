import {EitjeModal, useSetModalPropsRaw} from 'components/common/index'
import utils from '@eitje/web_utils'
import {Routes} from 'components/routing'
import {SideMenu} from 'common/components'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {buildRoute} from 'common/components'
import {useCallback} from 'react'
import {getStringUpToSubstr} from 'helpers'

// routes - array of route definitions
// links - array of actual links to be shown in the side menu (default is routes for backwards compatibility)
export const NewSideMenuModal = ({routes, links = routes, name, basePath, children, className, sortFn, sideMenuProps = {}, ...rest}) => {
	const loc = useLocation()
	const match = useRouteMatch()
	const {path} = match
	const classNames = utils.makeCns('side-menu-modal', className)
	if (!basePath) basePath = getStringUpToSubstr(loc.pathname, name)
	if (!basePath.startsWith('/')) basePath = `/${basePath}`

	const sideMenuRoutes = routes.map(r => buildRoute(r, sideMenuProps, basePath))
	const menuProps = {...sideMenuRoutes, ...sideMenuProps, basePath, name}

	if (sortFn) {
		routes = routes.sortBy(item => sortFn(item))
		routes.forEach(route => {
			route.subRoutes = route.subRoutes.sortBy(item => sortFn(item))
		})
	}

	const LeftPanel = useCallback(
		() => <SideMenu modal children={children} routes={links} {...menuProps} path={path} />,
		[children, links, menuProps, path],
	)

	return (
		<EitjeModal className={classNames} LeftPanel={LeftPanel} name={name} goBackToForeground={!loc.state?.simpleBack} {...rest}>
			<ModalPropsResetter />
			<Routes routes={routes} path={path} sideMenuRoutes={sideMenuRoutes} {...sideMenuProps} />
		</EitjeModal>
	)
}

const ModalPropsResetter = () => {
	const loc = useLocation()
	useSetModalPropsRaw({}, [loc.pathname])
	return null
}

export default NewSideMenuModal
