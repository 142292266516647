import {NewModalForm} from 'components/form/index'
import {AllowanceOptionsButton, AllowanceWeekDaysPicker, PayrollComponentPicker} from './components'
import {TimePicker, FormRow, Switch} from '@eitje/form-fields-web'
import {NewFormInputNumber} from 'components/form/input_number'
import {AllowanceMessage} from './message'
import {BasicPopout} from '@eitje/web_components'
import {DocsButton} from 'common/components'
import {TIME_REGISTRATION_DOCS} from 'cores/time_registration/constants'

export const IrregularForm = ({item}) => {
	return (
		<NewModalForm
			modalProps={{buttons: [<AllowanceOptionsButton />, <DocsButton to={TIME_REGISTRATION_DOCS.allowances} />], hideCancel: true}}
			fieldProps={{tableName: 'allowances', required: true}}
			name={['allowances', 'allowances.irregular']}
			onSubmit={data => item.update(data)}
			initialValues={item}
		>
			<AllowanceMessage item={item} />
			<AllowanceWeekDaysPicker />
			<FormRow>
				<TimePicker field="start_time" minuteStep={1} />
				<TimePicker field="end_time" minuteStep={1} />
			</FormRow>
			<FormRow>
				<NewFormInputNumber max={5000} field="amount" />
				<Switch field="active" required={false} tooltip popoutBody PopoutComponent={BasicPopout} />
			</FormRow>
			<PayrollComponentPicker kind="allowances" />
		</NewModalForm>
	)
}
