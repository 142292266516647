import {DocsButton, EitjeButton, Layout, Message} from 'common/components'
import {Switch, FormRow} from '@eitje/form-fields-web'
import {NewModalForm} from 'components/form/index'
import {useModalContext} from 'components/ui'
import {t} from 'initializers/i18n'
import {NewFormInputNumber} from 'components/form/input_number'
import {useFormData} from 'hooks'
import {RevenueTeam} from 'models'
import {REVENUE_DOCS} from 'cores/revenue/constants'

export const RevenueTeamForm = ({team}) => {
	const revenueTeams = RevenueTeam.where({team_id: team.id})
	let initialValues = {revenue_active: team.revenue_active}
	revenueTeams.forEach(revTeam => {
		initialValues[`fract-${revTeam.id}`] = revTeam.fract
	})

	const submit = async data => {
		let res
		if (!data.revenue_active || team.revenue_active != data.revenue_active) {
			res = await team.update({revenue_active: data.revenue_active})
		}
		if (!data.revenue_active) return res

		const payload = getFractKeys(data).map(key => {
			const fract = data[key] || 0
			const id = key.split('fract-')[1]
			return {id, fract}
		})

		return RevenueTeam.multiUpdate(payload)
	}

	return (
		<NewModalForm
			onSubmit={submit}
			modalProps={{
				buttons: [
					<EitjeButton t="records.revenue_group.name_plural" modalLink="/revenue_groups" iconLeft="three-sliders" />,
					<DocsButton to={REVENUE_DOCS.distribute_wage_cost} />,
				],
				okButtonProps: {isDisabled},
			}}
			initialValues={initialValues}
		>
			<Switch field="revenue_active" />
			<Body team={team} revenueTeams={revenueTeams} />
		</NewModalForm>
	)
}

const Body = ({revenueTeams, team}) => {
	const {namespace} = useModalContext()
	const {revenue_active, ...formData} = useFormData()
	const onlyHasTotal = revenueTeams.length == 1 && revenueTeams[0].omzetGroep?.naam == 'Totaal'
	let message = revenueTeams.length >= 2 ? 'distribution_example' : 'no_distribution'
	const totalFract = getTotalFract(formData)
	if (totalFract > 100) message = 'more_than_100'
	if (totalFract < 100) message = 'less_than_100'

	if (!revenue_active) return null
	return (
		<>
			<FormRow>
				<Message
					form
					baseColor={totalFract != 100 ? 'red' : 'blue'}
					t={{key: `${namespace}.messages.${message}`, team, total: totalFract}}
				/>
			</FormRow>
			{revenueTeams.length < 2 && <CreateGroupButton id={onlyHasTotal && revenueTeams[0].omzetGroep?.id} envId={team.environment_id} />}

			{!onlyHasTotal &&
				revenueTeams
					.sortBy(r => r.omzetGroep?.naam.toLowerCase())
					.map(revTeam => (
						<RevenueTeamInput disabled={revenueTeams.length == 1 && t(`${namespace}.messages.one_group_disabled`)} revenueTeam={revTeam} />
					))}
		</>
	)
}

const CreateGroupButton = ({envId, id}) => {
	return (
		<FormRow>
			<Layout padding={12}>
				<EitjeButton
					modalLink={`/revenue_groups/new?environment_id=${envId}&returnUrl=${window.location.pathname}&id=${id} `}
					width="full"
					iconLeft="plus"
					t="records.revenue_group.name"
				/>
			</Layout>
		</FormRow>
	)
}

const getFractKeys = data => Object.keys(data).filter(key => key.startsWith('fract-'))

const getTotalFract = data => {
	return Object.values(_.pick(data, getFractKeys(data)))
		.map(Number)
		.filter(Boolean)
		.sum()
}

const isDisabled = data => {
	if (!data.revenue_active) return false
	const totalFract = getTotalFract(data)
	if (totalFract != 100) return t(`form.revenue_teams.errors.not_100`)
}

const RevenueTeamInput = ({revenueTeam, disabled}) => {
	return (
		<NewFormInputNumber
			disabled={disabled}
			max={100}
			label={t(`form.revenue_teams.fields.fract.label`, {name: revenueTeam.omzetGroep?.naam})}
			field={`fract-${revenueTeam.id}`}
			maxDigits={0}
		/>
	)
}
