import {exportT} from 'cores/exports2/index'
import {useState} from 'react'
import {components} from './components'
import utils from '@eitje/web_utils'
import {useRoleEnvs} from 'hooks'
import {useShared} from 'hooks'

const opts = ['organisation', 'environment', 'team', 'user', 'contract_environment']

export const RecordFilters = ({active: act, value, includeGroups = [], excludeGroups = [], setFirmFilter}) => {
	const [active, setActive] = useState(act)
	const roleEnvs = useRoleEnvs('admin')
	let filteredOpts = utils.exists(includeGroups)
		? opts.filter(o => includeGroups.includes(o))
		: opts.filter(o => !excludeGroups.includes(o))
	const {orgEnvIds} = useShared()

	if (roleEnvs.length < orgEnvIds.length) filteredOpts = filteredOpts.filter(f => f != 'organisation')
	if (orgEnvIds.length == 1) filteredOpts = filteredOpts.filter(f => f != 'contract_environment')

	const setFirm = (kind, ids) => {
		const filter = {
			filter: 'firms',
			value: {
				[`${kind}_ids`]: ids,
			},
		}
		setFirmFilter(filter)
	}

	return (
		<div className="table-edit-record-filters">
			{filteredOpts.map(f => (
				<FilterCard
					dataActive={act == active}
					value={value}
					onChange={ids => setFirm(f, ids)}
					setActive={() => setActive(f)}
					active={f == active}
					kind={f}
				/>
			))}
		</div>
	)
}

const FilterCard = ({kind, value, setActive, active, ...rest}) => {
	const classNames = utils.makeCns('table-edit-record-filters-card', active && 'active')
	const FilterComponent = components[kind]
	const roleEnvs = useRoleEnvs('admin')
	return (
		<div onClick={setActive} className={classNames}>
			{/*<EitjeImage src={`exports/${kind}.png`} />*/}
			<h4>{exportT(`records.${kind}_title`)} </h4>
			<h5>{exportT(`records.${kind}_explanation`)} </h5>
			{active && <FilterComponent value={active ? value : null} kind={kind} maxWidth={null} roleEnvs={roleEnvs} {...rest} />}
		</div>
	)
}
