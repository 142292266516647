import {makeNewRegisteredField, useForm} from '@eitje/form'
import utils from '@eitje/utils'
import {Icon} from '@eitje/web_components'
import {useFormData} from 'hooks'
import pluralize from 'pluralize'
import {useEffect, useState} from 'react'
import {t} from 'initializers/i18n'
import {EitjeButton, Layout, Text} from 'common/components'
import {FormRow} from '@eitje/form-fields-web'
import {usePrevious} from '@eitje/react-hooks'

const FIELD_DELIMITER = `--zz--`

const buildItem = (field, item) => {
	const id = item?.id || utils.randomId()
	return {id, isNew: !item?.id, baseField: `arrayField${FIELD_DELIMITER}${field}-${id}`, ...item}
}

const getField = (item, key) => {
	const {baseField} = item
	return `${baseField}-${key}`
}

const getInitial = (data, field, minItems) => {
	if (data[field]) {
		const extraItems = Math.max(0, minItems - data[field].length)
		return [...data[field], ...Array(extraItems)]
	}
	return [...Array(minItems)]
}

let ArrayField = ({minItems = 1, maxItems = 100, fields = [], onRemove = _.noop, field, ...rest}) => {
	const fieldNames = fields._map('field')
	const data = useFormData()
	const {setValues} = useForm()
	const initialItems = getInitial(data, field, minItems).map(item => buildItem(field, item))
	const [items, setItems] = useState(initialItems)
	const fieldKeyStart = `arrayField${FIELD_DELIMITER}${field}-`
	const relevantData = _.pickBy(data, (val, key) => key.startsWith(fieldKeyStart) && val != null && val != undefined)
	const joined = Object.values(relevantData).join('') // expects string values
	const previousJoined = usePrevious(joined)

	useEffect(() => {
		if (data[field]) {
			let initialObject = {}
			fieldNames.forEach(fieldName =>
				items.forEach(item => {
					const key = getField(item, fieldName)
					initialObject[key] = item[fieldName]
				}),
			)

			setValues(initialObject)
		}
	}, [])

	useEffect(() => {
		if (joined == '' && !previousJoined) return // we want to not run this initially, but want to run it later on if joined becomes ''
		const newVals = items
			.map(item => {
				let obj = {id: item.id}
				fieldNames.forEach(fieldName => {
					obj[fieldName] = data[getField(item, fieldName)]
				})
				return obj
			})
			.filter(obj => Object.values(obj).filter(Boolean).length > 1)
		rest.onChange(newVals)
	}, [joined, items])

	const addItem = () => setItems(items => [...items, buildItem(field)])

	const removeItem = id => {
		setItems(items => items.filter(i => i.id != id))
		return onRemove(id)
	}

	const allowRemove = minItems < items.length
	const showAddItemRow = maxItems > items.length
	return (
		<>
			{items.map((i, idx) => (
				<ArrayRow
					{...rest}
					item={i}
					idx={idx}
					fields={fields}
					translationField={pluralize.singular(field)}
					allowRemove={allowRemove}
					remove={e => {
						e.stopPropagation()
						removeItem(i.id)
					}}
				/>
			))}
			{showAddItemRow && <AddItemRow {...rest} onClick={addItem} />}
		</>
	)
}

const ArrayRowDeleteButton = props => <EitjeButton className="delete-row-button" iconLeft="trash" baseColor="red" {...props} />

const ArrayRow = ({id, idx, translationField, remove, fields = [], item, allowRemove, name}) => {
	return (
		<FormRow>
			{fields.map((f, fieldIdx) => (
				<Field
					item={item}
					field={f}
					idx={idx}
					id={id}
					remove={remove}
					allowRemove={allowRemove && fieldIdx == fields.length - 1}
					parentField={translationField}
					name={name}
				/>
			))}
		</FormRow>
	)
}

const Field = ({idx, field, item, name, parentField, allowRemove, remove}) => {
	const {Component, addCountToLabel, ...rest} = field
	let label = t(`form.${name}.fields.${parentField}.${field.field}.label`)
	if (addCountToLabel) label = `${label} ${idx + 1}`

	return (
		<Component
			{...rest}
			field={`${item.baseField}-${field.field}`}
			label={label}
			rightElement={allowRemove && <ArrayRowDeleteButton onClick={remove} />}
		/>
	)
}

const AddItemRow = ({onClick, addItemLabel = 'add row'}) => {
	return (
		<Layout className="add-row-button" block width="full">
			<Layout horizontal="center" width="full" height={40} onClick={onClick} colorSet="color-bordered-fill" gap={6}>
				<Icon name="plus" size={10} />
				<Text small t={addItemLabel} />
			</Layout>
		</Layout>
	)
}

ArrayField = makeNewRegisteredField(ArrayField, {})
export {ArrayField}
