import {NAMESPACE} from 'cores/knowledge'
import {DatePicker, Switch} from '@eitje/form-fields-web'
import {NewFormInputNumber} from 'components/form/input_number'
import {useFormData} from 'hooks'
import {ListPicker, Message} from 'common/components'
import {t} from 'initializers/i18n'
import {ftime} from 'helpers'

export const TrainingFormFields = ({title}) => {
	const {trainable, trainingType} = useFormData()

	const ns = 'form.training.fields'
	const trainingTypeOptions = [
		{id: 'always_trainable', label: t(`${ns}.always_trainable.label`)},
		{id: 'days_to_start', label: t(`${ns}.days_to_start.label`)},
		{id: 'valid_untill', label: t(`${ns}.valid_untill.label`)},
	]

	return (
		<>
			<TrainingMessage title={title} />
			<Switch field="trainable" />
			{trainable && (
				<>
					<ListPicker field="trainingType" required items={trainingTypeOptions} valueField="id" labelField="label" dropdown single noSort />
					<TrainingAvailabilityInput value={trainingType} />
				</>
			)}
		</>
	)
}

const TrainingAvailabilityInput = ({value}) => {
	if (value === 'always_trainable') return null

	let field
	if (value === 'days_to_start') field = <NewFormInputNumber field="days_to_start" type="number" min={0} required />
	if (value === 'valid_untill') field = <DatePicker field="valid_untill" bordered={false} required pastDisabled />

	return field
}

const getTrainingMessageKey = (trainable, trainingType) => {
	if (!trainable) return `${NAMESPACE}.training_not_trainable_message`

	switch (trainingType) {
		case 'always_trainable':
			return `${NAMESPACE}.training_always_trainable_message`
		case 'days_to_start':
			return `${NAMESPACE}.training_days_to_start_message`
		case 'valid_untill':
			return `${NAMESPACE}.training_valid_until_message`
	}
}

const TrainingMessage = ({title}) => {
	const {trainable, trainingType, days_to_start, valid_untill} = useFormData()

	// days_to_start changes from number to string when the user changes the value.
	// in order to have a correct interpolation, we convert days_to_start to a number if it's a valid non-empty value; otherwise, set it to null.
	const daysToStart = days_to_start && _.isInteger(Number(days_to_start)) ? Number(days_to_start) : null
	const validUntill = valid_untill ? ftime(valid_untill, 'date') : '-'

	const key = getTrainingMessageKey(trainable, trainingType)
	const message = {
		key,
		title,
		days_to_start: daysToStart ?? '-',
		count: daysToStart ?? 0, // used for day transkey used in the days_to_start translation
		valid_untill: validUntill,
	}
	return <Message t={message} colorSet="color-bordered-fill" />
}
