import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {ftime} from 'helpers'
import {Text} from 'common/components'

export const DateCell = ({value}) => {
	return <Cell value={value && ftime(value, 'dayMonthYear')} />
}

export const BooleanCell = ({value, column}) => {
	const {defaultValue} = column
	if (value == null && defaultValue == null) return <Cell />
	return <Cell value={value || defaultValue ? t('yes') : t('no')} />
}

export const Cell = ({value, defaultValue = '-'}) => {
	const val = utils.exists(value) ? value : defaultValue
	return <Text truncate>{val}</Text>
}
