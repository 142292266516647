import utils from '@eitje/utils'
import {KNOWLEDGE_DOCS, NAMESPACE, useTopicContext} from 'cores/knowledge'
import {t} from 'initializers/i18n'

const baseColumns = [
	{
		id: 'tableName',
		accessorFn: ({tableName}, index) => {
			const type = tableName === 'infos' ? 'article' : 'quiz_question'
			return `${index + 1}. ${utils.capitalize(t(`${NAMESPACE}.${type}`))}`
		},
		docsId: KNOWLEDGE_DOCS.create_topic,
	},
	{
		id: 'title',
		accessorFn: ({title, question}) => title || question,
	},
]

export const useColumns = () => {
	const {hasRole, published: topicPublished} = useTopicContext()

	if (!hasRole) return baseColumns

	const managerColumns = [
		...baseColumns,
		{
			id: 'published',
			accessorFn: ({published, tableName}) => {
				if (tableName === 'infos') return published
				return topicPublished || null // for quiz items, return true if topic is published, else show default '-'
			},
			cell: 'boolean',
			docsId: KNOWLEDGE_DOCS.create_topic,
			popoutPropsFn: item => {
				if (topicPublished && item.tableName === 'infos' && !item.published) {
					return {
						iconName: 'exclamation-mark',
						hidden: true,
					}
				}
			},
		},
	]
	return managerColumns
}
