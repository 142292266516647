import {useEffect, useState} from 'react'
import {rangeToDateArray, rangeFromStart, rangeForMonth} from 'helpers/date'
import {date as dateFn} from 'initializers/date'
import {EitjeButton, Layout} from 'common/components'
import {EitjeDatePicker} from './eitje_date_picker'

const FixedRangePicker = ({
	length = 7,
	initialValue,
	initialLeading,
	isMonthPicker,
	isWeekPicker = !isMonthPicker && length % 7 == 0,
	onChange,
	height,
	...props
}) => {
	let [date, setDate] = useState(initialValue)

	if (initialLeading) {
		date = initialValue
	}

	date = date.clone()

	if (isWeekPicker) date = date.startOf('week')
	if (isMonthPicker) date = date.startOf('month')

	const dateRange = isMonthPicker ? rangeForMonth({date: date}) : rangeFromStart({start: date, length})
	const {start, end} = dateRange

	useEffect(() => {
		if (!initialLeading) {
			onChange(rangeToDateArray(dateRange))
		}
	}, [date.format('YY-MM-DDDD')]) // we don't trust moments internals to implement isEq properly

	const type = isMonthPicker ? 'month' : isWeekPicker ? 'weeks' : 'day'

	const _setDate = date => {
		date = dateFn(date).startOf(type)
		const _dateRange = isMonthPicker ? rangeForMonth({date: date}) : rangeFromStart({start: date, length})
		if (initialLeading) {
			return onChange(rangeToDateArray(_dateRange))
		}
		setDate(date)
	}

	const amtPeriods = isMonthPicker ? 1 : Math.round(length / (isWeekPicker ? 7 : 1)) || 1

	const incrementDate = () => _setDate(date.add(amtPeriods, type))
	const decrementDate = () => _setDate(date.subtract(amtPeriods, type))

	const startDate = start.format('DD MMM')
	const endDate = end.format('DD MMM')
	const label = startDate == endDate ? start.format('DD MMM YYYY') : `${startDate} - ${endDate}`

	const buttonProps = {height, padding: '0 8'}

	return (
		<Layout>
			<Layout className="eitje-fixed-range-picker" gap={0}>
				<EitjeButton iconLeft="arrow-small-left" onClick={decrementDate} {...buttonProps} />
				<EitjeDatePicker value={date} label={label} onChange={_setDate} height={height} noBorderRadius />
				<EitjeButton iconLeft="arrow-small-right" onClick={incrementDate} {...buttonProps} />
			</Layout>
		</Layout>
	)
}

export default FixedRangePicker
