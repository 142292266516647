import {EitjeButton, PageHeader as _PageHeader} from 'common/components'
import {RouteSelect} from './route_select'
import {KnowledgeHelpButton, useArticleContext} from 'cores/knowledge'
import {AttachmentButton} from './attachment_button'
import {TopicButton} from './topic_button'
import {OptionsButton} from './options_button'

export const PageHeader = ({edit}) => {
	const {article, articleRoleMessage} = useArticleContext()
	const {published, publish, environment_ids} = article

	return (
		<_PageHeader
			className="article-header"
			type="floating"
			left={<RouteSelect />}
			right={[
				!published && (
					<EitjeButton
						t="publish"
						iconLeft="eye-open"
						colorSet="color-bordered-fill"
						onClick={publish}
						enableRole="manager"
						roleEnv={environment_ids}
					/>
				),
				edit && <TopicButton />,
				edit && <AttachmentButton />,
				edit && <OptionsButton />,
				!edit && <EitjeButton t="common.edit" iconLeft="pencil" disabled={articleRoleMessage} link="editor" />,
				<KnowledgeHelpButton type="articles" hideIntroduction />,
			]}
		/>
	)
}
