import {useEffect} from 'react'
import {PageLayout} from 'common/components'
import {useParams, usePathActive} from 'hooks'
import {Info} from 'models'
import {navigate} from 'actions/routing'
import {ArticleProvider, useArticleRoleMessage} from 'cores/knowledge'
import {PageHeader} from './page_header'
import {Article} from './article'

export const ArticlePage = () => {
	const {id} = useParams()
	const edit = usePathActive('edit')
	const article = Info.find(id)
	const articleRoleMessage = useArticleRoleMessage(article)

	useEffect(() => {
		// users without permission to edit, are redirected to the show page
		if (edit && articleRoleMessage) navigate(`/articles/${id}`, {replace: true})
	}, [edit, articleRoleMessage])

	return (
		<ArticleProvider article={article} readOnly={!edit} articleRoleMessage={articleRoleMessage}>
			<PageLayout name="article" headers={<PageHeader edit={edit} />} unsetPagePadding bodyWidth={1000}>
				<Article />
			</PageLayout>
		</ArticleProvider>
	)
}
