import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import {useFind, useAll} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import useList from 'hooks/use_list'
import {likeDateTime} from 'helpers/date'
import {date} from 'initializers/date'
import {EntityCount} from 'common/components/entity_count'
import {InfoCard} from 'common/components'

const AuditsPopout = ({audits, ...props}) => {
	const {list} = useList({items: audits, itemProps: props, valueField: 'created_at', allowDeactivate: true, ListItem: Audit})
	return <div className="audits-popout">{list}</div>
}

const Audits = ({audits, getAuditValue = _.noop, ...props}) => {
	const __getAuditValue = props => _getAuditValue({...props, customFn: getAuditValue})
	return (
		<InfoCard
			className="audits-button"
			icon="pencil"
			popoutBody={<AuditsPopout getAuditValue={__getAuditValue} audits={audits} {...props} />}
			popoutTitle={t('planning.shift_modal.changelog')}
			label={t('planning.shift_modal.changelog')}
			children={<EntityCount name="common.edits" count={audits.length} />}
			bodyPadding={0}
			{...props}
		/>
	)
}

const Audit = ({item, getLeadText = _.noop, isActive, ...props}) => {
	const {name, created_at, comment, changes, action} = item
	const amtChanges = Object.keys(changes).length
	const dateTime = t('onDatetime', {date: created_at})
	const isCreate = action == 'create'
	const isTrade = comment == 'approve_trade'
	let leadText = getLeadText(item)
	const defaultLeadText = isTrade ? (
		t('planning.trade.approved')
	) : isCreate ? (
		t('common.created')
	) : (
		<EntityCount name="common.edits" count={amtChanges} />
	)
	if (!leadText) leadText = defaultLeadText

	return (
		<div className="audit">
			<p className="audit-username"> {name} </p>

			<p className="audit-edit-count">
				{leadText} {dateTime}
			</p>

			<img src="/images/dropDown1.png" className={isActive ? 'expander expanded' : 'expander'} />
			{isActive && <AuditDetails changes={changes} isCreate={isCreate} {...props} />}
		</div>
	)
}

const TradeChange = ({changes}) => {
	const {user_id} = changes
	const initialUser = useFind('users', user_id[0])
	const newUser = useFind('users', user_id[1])
	const keys = Object.keys(changes)
	const Element = keys['shift_date'] ? SwapChange : PickupChange
	const opts = {trader_name: initialUser?.full_name, tradee_name: newUser?.full_name}
	return (
		<div className="audit-change-container">
			<h6 className="audit-details-change">
				<Element initialUser={initialUser} newUser={newUser} opts={opts} changes={changes} />
			</h6>
		</div>
	)
}

const PickupChange = ({initialUser, opts}) => {
	const text = !utils.exists(initialUser) ? 'planning.trade.audits.open_pickup' : `planning.trade.audits.user_pickup`
	return t(text, opts)
}

const SwapChange = ({changes, opts}) => {
	return t(`planning.trade.audits.swap`, {...opts, trader_date: changes['shift_date'], tradee_date: changes['swap_shift_date']})
}

const AuditDetails = ({changes, isCreate, isTrade, tableName = 'shift', ...props}) => {
	return (
		<div className="audit-details">
			{isTrade && <TradeChange changes={changes} />}

			{!isTrade &&
				Object.keys(changes).map(c => <AuditChange isCreate={isCreate} tableName={tableName} value={changes[c]} keyName={c} {...props} />)}
		</div>
	)
}

const AuditChange = ({isCreate, hideZeros, auditElements = {}, ...props}) => {
	const {value, keyName, customValues = {}} = props
	const isBool = _.isArray(value) ? value.some(v => _.isBoolean(v)) : _.isBoolean(value)

	if (_.isArray(value) && value.every(v => !utils.exists(v))) return null // sometimes, fields update from 'null' to 'false' or 'null' to [], we get that out here

	const customValue = customValues[keyName]

	const CustomElement = auditElements[keyName]

	const DefaultElement = isBool ? BooleanAudit : isCreate || !_.isArray(value) ? CreateAudit : UpdateAudit
	const Element = CustomElement || DefaultElement

	if (!utils.exists(value) && isCreate) return null
	if (hideZeros && value == 0) return null

	return (
		<div className="audit-change-container">
			<h6 className="audit-details-change">
				<Element {...props} customValue={customValue} />
			</h6>
		</div>
	)
}

const BooleanAudit = props => {
	const {tableName, displayTableName, customValue, keyName, value} = props
	const val = _.isArray(value) ? value[1] : value
	const type = val ? 'true' : 'false'
	const _keyName = `${keyName}_${type}`

	const auditValue = customValue || getKeyName({...props, keyName: _keyName})
	const _displayTableName = displayTableName || t(`records.${tableName}.name`)
	return (
		<Fragment>
			{_displayTableName} {t('common.is')} {auditValue}
		</Fragment>
	)
}

const CreateAudit = props => {
	let {value, getAuditValue, keyName} = props
	if (_.isArray(value)) value = value.join(', ')
	return (
		<Fragment>
			{getKeyName(props)}: {getAuditValue({keyName, value})}
		</Fragment>
	)
}

const getKeyName = ({tableName, keyName}) => {
	const def = t(`records.default.fields.${keyName}`)
	return t(`records.${tableName}.fields.${keyName}`, def)
}

const UpdateAudit = props => {
	let {getAuditValue, value, keyName} = props
	if (!_.isArray(value)) value = [null, value]
	if (value.some(v => _.isBoolean(v))) return <BooleanAudit />
	let prevValue = getAuditValue({keyName, value: value[0]})
	let currentValue = getAuditValue({keyName, value: value[1]})
	if (_.isArray(prevValue)) prevValue = prevValue.join(', ')
	if (_.isArray(currentValue)) currentValue = currentValue.join(', ')

	return (
		<Fragment>
			{getKeyName(props)} {t('common.changed_from')} {prevValue} {t('common.to')} {currentValue}
		</Fragment>
	)
}

const _getAuditValue = props => {
	const {keyName, value, customFn = _.noop} = props
	const customVal = customFn(props)
	if (customVal) return customVal

	if (keyName == 'pauze_duur') return utils.minToTimeString(value)

	if (utils.likeDate(value)) {
		return date(value.replace('Z', '')).format('DD-MM-YY')
	}

	if (likeDateTime(value)) {
		return date(value.replace('Z', '')).format('DD-MM-YY, HH:mm')
	}

	// replacing the Z's here ensures the timezone isn't fucked. We need to find a scalable solution soon..
	if (!value) return 'n/a'
	return value
}

export default Audits
