import {SearchEmpty} from 'hooks'
import {Placeholder as _Placeholder, useFilterFooterContext} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'

const layoutProps = {width: 'full', height: 'unset', margin: '48px 0 0 0'}

export const Placeholder = () => {
	const {hasData, placeholderProps} = useBasicTableContext()
	const {hasAnyFilter, clearFilters} = useFilterFooterContext()

	if (hasData) {
		return null
	}

	if (hasAnyFilter) {
		return <SearchEmpty action={clearFilters} layoutProps={layoutProps} />
	}

	if (placeholderProps) {
		return <_Placeholder layoutProps={layoutProps} {...placeholderProps} />
	}

	return <_Placeholder layoutProps={layoutProps} name="no_data" subtitle={false} animation="desert" />
}
