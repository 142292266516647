import {BasicPopout} from '@eitje/web_components'
import {DocsButton} from 'common/components/docs_button'
import {Children} from 'react'
import {SupportFilmButton} from '../eitje_button/eitje_buttons'

export const DocsPopout = ({buttons, docsId, collection, supportFilmId, ...rest}) => {
	const _buttons = [
		...Children.toArray(buttons),
		supportFilmId && <SupportFilmButton id={supportFilmId} />,
		(docsId || collection) && <DocsButton to={docsId} collection={collection} />,
	]

	return <BasicPopout eventName="docs-popout" buttons={_buttons} {...rest} />
}
