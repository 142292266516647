import {useSyncAgendaLink} from 'components/general'
import {isProd, isTest} from 'constants/general'
import useSelector from 'hooks/use_selector'
import useShared from 'hooks/use_shared'
import {date} from 'initializers/date'
import I18n from 'initializers/i18n'
import {BaseConnection} from 'models/index'
import {useEffect} from 'react'
import {matchPath} from 'react-router-dom'

const prodId = 'gygy8mtw'
const appId = prodId

const registerIntercom = (data = {}) => {
	if (!window.Intercom) return console.error('Intercom not registered')
	window.Intercom('boot', {
		api_base: 'https://api-iam.intercom.io',
		app_id: appId,
		...data,
	})
}

const supportUrls = ['/support', '/create_account', '/create_environment', '/onboarding/welcome']

export const updateIntercom = (data = {}) => {
	if (!window.Intercom) return
	const pn = window.location.pathname
	const isSupportPage = supportUrls.some(url => matchPath(pn, url))
	window.Intercom('update', {hide_default_launcher: !isSupportPage, ...data})
}

const IntercomRegistrar = () => {
	const integs = BaseConnection.all()
	let integTypes = _.mapValues(_.groupBy(integs, 'type'), v =>
		v
			.map(v1 => v1.sanitized_type)
			.uniq()
			.join(','),
	)
	integTypes = _.mapKeys(integTypes, (v, k) => `${k.toUpperCase()} Integration`)

	const agendaLink = useSyncAgendaLink()
	const {me, env, myOrgEnvs, orgEnvs, org} = useShared()
	const {email, avatar, full_name, phone, date_started, id} = me
	const roles = useSelector(state => state.settings.roles) || {}
	const envRoles = roles[env.id] || []
	let company
	let data

	if (me.id && isProd) {
		company = {
			company_id: org.id,
			user_count: orgEnvs.map(e => e.user_ids).flat().length,
			name: org.name,
			created_at: date(org.created_at).unix(),
			all_locations: orgEnvs.map(e => e.naam).join(', '),
			billing_done: org.billing_done,
			...integTypes,
		}

		data = {
			profile_link: `https://web.eitje.app/user/${id}/profile`,
			calendar_link: agendaLink,
			admin_link: `https://admin.eitje.app/users/${id}`,
			email,
			name: full_name,
			current_location: env.naam,
			current_location_id: env.id,
			all_locations: myOrgEnvs.map(e => e.naam).join(', '),
			created_at: date(date_started).unix(),
			user_id: id,
			avatar: {
				type: 'avatar',
				image_url: avatar,
			},
			name: full_name,
			company,
			phone,
			roles: envRoles.join(', '),
		}
	}

	useEffect(() => {
		registerIntercom(data)
	}, [])

	useEffect(() => {
		if (data) updateIntercom(data)
	}, [env])

	return null
}

export default IntercomRegistrar
