import {createContext, useContext} from 'react'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {useBasicTableSort} from './sort'

const BasicTableContext = createContext({})
const {Provider} = BasicTableContext

export const BasicTableProvider = ({children, columns: _columns, name, data, i18nOpts, defaultSortField, ...rest}) => {
	const columns = _columns.map(column => ({...column, id: column.id || column.accessorKey})) // ensure every column gets an id
	const {sortedItems, sortField, sortDirection, changeSort} = useBasicTableSort({columns, data, defaultSortField})

	const basicTableT = transKey => {
		const inputKeys = utils.alwaysDefinedArray(transKey)
		const transKeys = utils
			.alwaysDefinedArray(name)
			.map(name => inputKeys.map(key => `basic_tables.${name}.${key}`))
			.flat()

		return t(transKeys, i18nOpts)
	}

	const updateSort = column => changeSort(column === sortField ? sortField : column)

	const value = {
		...rest,
		basicTableT,
		name,
		columns,
		data: sortedItems || [],
		hasData: !_.isEmpty(data),
		defaultSortField,
		sortField,
		sortDirection,
		updateSort,
	}

	return <Provider value={value}>{children}</Provider>
}

export const useBasicTableContext = () => useContext(BasicTableContext)
