import {ModalWithNewForm} from 'components/form/new_modal_form'
import {history} from 'index'
import {Info} from 'models'
import {CreateFormFields} from './form_fields'
import {useQueryParams} from 'hooks'

export const NewArticle = () => {
	const {topic_id} = useQueryParams()
	const handleSubmit = async data => await Info.create(data)

	const close = ({res}) => {
		history.replace(`/articles/${res.item.id}/editor`)
	}

	const initialValues = topic_id && {topic_ids: [topic_id]}

	return (
		<ModalWithNewForm name="create_article" width={450} onSubmit={handleSubmit} afterSubmit={close} initialValues={initialValues}>
			<CreateFormFields />
		</ModalWithNewForm>
	)
}
