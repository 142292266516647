import {CreateButton} from 'common/components'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'
import {ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {Info} from 'models'

// little hack to override the default chevron-small-down iconRight
// that is added to the trigger button by the ListPicker with the dropdown prop
const iconRightProps = {name: ''}

const CreateArticleButton = () => {
	const {id, info_ids, hasRole, ...topic} = useTopicContext()
	const articles = Info.all()

	const button = <CreateButton name={NAMESPACE} width="full" modalLink={`/articles/new?topic_id=${id}`} t="create_article.title" />

	const placeholderProps = {
		name: `${NAMESPACE}.no_articles`,
		animation: 'notebook',
		subtitle: false,
		children: hasRole && button,
	}

	const articleTitle = t(`${NAMESPACE}.article`)

	// title as a function to make sure a fixed label is shown in the LP trigger, instead of default behaviour
	const titleFn = () => articleTitle

	const handleChange = data => topic.addRemoveAssoc({info_ids: data}, {info_ids})

	return (
		<ListPicker
			dropdown
			iconLeft="plus"
			items={articles}
			labelField="title"
			placeholderProps={placeholderProps}
			raw
			showSearch
			showClearAll={false}
			title={titleFn}
			value={info_ids}
			iconRightProps={iconRightProps}
			onChange={handleChange}
			placeholder={articleTitle} // shown in LP trigger when value length equals zero
		>
			{button}
		</ListPicker>
	)
}

const CreateQuizItemButton = () => {
	const {id, trainable} = useTopicContext()
	const disabled = !trainable && t(`${NAMESPACE}.create_quiz_disabled`)

	return (
		<CreateButton
			name={NAMESPACE}
			t="quiz_question"
			colorSet="grey-outlined"
			modalLink={`/quiz_items/new?topic_id=${id}`}
			disabled={disabled}
		/>
	)
}

export const createResourceButtons = [<CreateArticleButton />, <CreateQuizItemButton />]
