import {DocsPopoutButton, SupportFilmButton} from 'common/components'
import {useModalContext} from 'components/ui'
import {CHECK_INS_DOCS, CHECK_INS_TRANS} from 'cores/check_ins'
import {mdT} from 'initializers/i18n'

export const CheckInExplainButton = () => {
	const {namespace} = useModalContext()
	return (
		<DocsPopoutButton
			docsId={CHECK_INS_DOCS['setup']}
			title={`${namespace}.explanation_popout.title`}
			body={mdT(`${namespace}.explanation_popout.body`, CHECK_INS_TRANS)}
			supportFilmId={'activate_time_clock'}
			t={`${namespace}.explanation`}
		/>
	)
}

export const CheckInFilmButton = () => <SupportFilmButton id="activate_time_clock" />
