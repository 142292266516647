import {DeleteButton, EitjeButton, Message} from 'common/components'
import {NewModalForm} from 'components/form/index'
import {useModalContext} from 'components/ui'
import {Input, Switch} from '@eitje/form-fields-web'
import {saveSettingGroup} from 'actions/settings'
import {useFormData} from 'hooks'
import {PayrollComponentPicker} from 'cores/time_registration/pages/allowances/index/components'
import {BasicPopout} from '@eitje/web_components'
import {BaseConnection, Environment} from 'models'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

export const MealForm = ({settings, envId, name}) => {
	const env = Environment.find(envId)
	const initialValues = {name, integration_payroll_component_id: env.meal_code, active: settings.maaltijd_selected.includes(name)}
	const {maaltijd_opts, maaltijd_selected} = settings
	const {namespace} = useModalContext()

	const submit = async data => {
		const optsClone = [...maaltijd_opts]
		let selectedClone = [...maaltijd_selected]

		const optsIndex = settings.maaltijd_opts.indexOf(name)
		const selectedIndex = settings.maaltijd_selected.indexOf(name)
		optsClone[optsIndex] = data.name

		if (optsClone.filter(item => item == data.name).length > 1) {
			return utils.errorNotif({content: t('modals.settings.meals.new.already_taken')})
		}

		if (!data.active) {
			selectedClone = selectedClone.filter(s => !s.includes(name))
		} else if (selectedIndex == -1) {
			selectedClone.push(data.name)
		} else {
			selectedClone[selectedIndex] = data.name
		}
		if (data.integration_payroll_component_id != env.meal_code) {
			await env.update({meal_code: data.integration_payroll_component_id})
		}

		return saveSettingGroup('maaltijd', {maaltijd_opts: optsClone, maaltijd_selected: selectedClone}, {env_id: envId})
	}

	const deleteMeal = () => {
		return saveSettingGroup(
			'maaltijd',
			{
				maaltijd_opts: maaltijd_opts.filter(m => m != name),
				maaltijd_selected: maaltijd_selected.filter(m => m != name),
			},
			{env_id: envId},
		)
	}

	return (
		<NewModalForm
			modalProps={{
				buttons: [<AddButton envId={envId} />, <DeleteButton confirmText={`${namespace}.delete_popout.body`} onDelete={deleteMeal} />],
			}}
			name="meals"
			onSubmit={submit}
			initialValues={initialValues}
		>
			<Body envId={envId} />
		</NewModalForm>
	)
}

const AddButton = ({envId}) => <EitjeButton t="common.meal" iconLeft="plus" modalLink={`/settings/meals/new?environment_id=${envId}`} />

const Body = ({envId}) => {
	const {active, integration_payroll_component_id} = useFormData()
	const {namespace} = useModalContext()
	const connection = BaseConnection.find({environment_ids: [envId], hr: true})

	const activeKey = active ? 'active' : 'inactive'
	const hrKey = !active ? 'inactive' : integration_payroll_component_id ? 'integrated' : 'unintegrated'
	return (
		<>
			<Message form>
				{t(`${namespace}.messages.${activeKey}`)}&nbsp;
				{connection?.hasExportOption?.('meals') && t(`${namespace}.messages.hr_integrated.${hrKey}`, {name: connection.name})}
			</Message>
			<Input field="name" required />
			<Switch field="active" />
			{active && <PayrollComponentPicker kind="meals" valueField="code" envId={envId} tooltip popoutBody PopoutComponent={BasicPopout} />}
		</>
	)
}
