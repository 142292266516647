import {t} from 'initializers/i18n'

export const pickerMap = array => array.map(v => ({id: v, name: t(v)}))

export const prettyPrintArray = ({array, quotes = true, unique = true, joinWord = 'or', sort}) => {
	const empty = _.isEmpty(array) || array.length === 0
	if (empty) return ''

	let items = unique ? array.uniq() : array
	const quote = quotes ? "'" : ''
	items = array.map(item => `${quote}${item}${quote}`)
	items = sort ? _.sortBy(items) : items

	const singleItem = array.length === 1
	if (singleItem) return items[0]

	const lastItem = items.pop()
	joinWord = joinWord === '&' ? joinWord : t(`common.${joinWord}`)
	return `${items.join(', ')} ${joinWord} ${lastItem}`
}

export const listPickerPrettyPrint = ({activeItems}) => prettyPrintArray({array: activeItems._map('label'), quotes: false, joinWord: '&'})
