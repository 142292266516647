import {ListPicker} from 'common/components'
import {NewModalForm} from 'components/form/index'
import {useEnvSettingGroup, useFormData, useParams} from 'hooks'
import {t, i18n} from 'initializers/i18n'
import {BasicPopout} from '@eitje/web_components'
import {saveSettingGroup} from 'actions/settings'
import date from 'initializers/date'
import {getPeriods} from 'cores/time_registration'

const dayNums = [...Array(28).keys()].map(i => i + 1)

const map = {flex: 'nul_uren', fixed: 'uren'}

export const WagePeriod = ({item}) => {
	const {environment_id} = useParams()
	const settingsKey = map[item.sanitizedName()] || item.sanitizedName()
	const settings = useEnvSettingGroup(settingsKey, environment_id)

	const submit = data => {
		const verloning_start = date('2025-01-01').date(data.verloning_start || 1)
		return saveSettingGroup(settingsKey, {...data, verloning_start}, {env_id: environment_id})
	}

	const initialValues = {
		...settings,
		verloning_start: settings.verloning_type == 'maandelijks' ? date(settings.verloning_start).date() : 1,
	}

	return (
		<NewModalForm afterSubmit={getPeriods} initialValues={initialValues} name="employment_types" onSubmit={submit}>
			<Body />
		</NewModalForm>
	)
}

const commonProps = {
	noSort: true,
	single: true,
	dropdown: true,
	required: true,
	tooltip: true,
	popoutBody: true,
	PopoutComponent: BasicPopout,
}

const Body = () => {
	const {verloning_type} = useFormData()
	const language = i18n.language
	const toOrdinal = language == 'nl' ? toDutchOrdinal : toEnglishOrdinal
	const buildLabel = item => {
		return `${toOrdinal(item)} ${t('common.day_of_month')}`
	}

	const renumerationTypes = [
		{id: 'wekelijks', name: t('common.week')},
		{id: '4-wekelijks', name: `4 ${t('common.weeks')}`},
		{id: 'maandelijks', name: t('common.month')},
	]

	return (
		<>
			<ListPicker {...commonProps} field="verloning_type" items={renumerationTypes} />
			{verloning_type == 'maandelijks' && (
				<ListPicker showSearch={false} minWidth={200} {...commonProps} buildLabel={buildLabel} items={dayNums} field="verloning_start" />
			)}
		</>
	)
}

const pr = new Intl.PluralRules('en', {type: 'ordinal'})
const suffixes = {one: 'st', two: 'nd', few: 'rd', other: 'th'}

function toEnglishOrdinal(n) {
	// For English: append the correct suffix based on the plural rules
	return n + suffixes[pr.select(n)]
}

// For Dutch, simply add an "e"
function toDutchOrdinal(n) {
	return n + 'e'
}
