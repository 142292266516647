import {date} from 'initializers/date'
import {RevenueDay} from 'models/index'
import Form from './form/index'
import {mdT} from 'initializers/i18n'
import './styles/forecast.less'
import {Message} from 'common/components'

const submitForecast = data => {
	data = data.map(d => ({...d, forecast_amt_in_cents: d.amt * 100}))
	return RevenueDay.arbitrary('multi_update_forecast', {items: data})
}

const Forecast = ({dates, revenueDays, ...rest}) => {
	revenueDays = revenueDays.map(r => ({...r, amt: r.forecastAmt()}))

	return (
		<div className="revenue-forecast">
			<Message>{mdT('revenue.rev_modal.expected_helper')}</Message>
			<Form
				dateDisabled={_date => date(_date).startOf('day') < date().startOf('day')}
				onSubmit={submitForecast}
				initialValue={revenueDays}
				dates={dates}
				{...rest}
			/>
		</div>
	)
}

export default Forecast
