import {DocsPopout, Icon, Layout, Text} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'
import {t} from 'initializers/i18n'

export const inlineStyles = {flex: 1, minWidth: 0}

export const BasicTableHeaderCell = ({column}) => {
	const {updateSort, defaultSortField, basicTableT} = useBasicTableContext()
	const {id, docsId, tooltip, popout} = column
	const hasPopout = tooltip || docsId

	const clickColumn = () => updateSort(id)

	const popoutProps = hasPopout && {
		popoutTitle: popout?.title ? t(popout.title) : basicTableT([`${id}.popout.title`, `${id}.title`]),
		popoutBody: popout?.body ? t(popout.body) : basicTableT(`${id}.popout.body`),
		PopoutComponent: DocsPopout,
	}

	return (
		<Layout
			data-intercom-target={`basic-table-column-${id}`}
			className="basic-table-header-cell"
			onClick={defaultSortField && clickColumn}
			initialBackground={Colors.lightGrey}
			padding={16}
			height={40}
			style={inlineStyles}
		>
			<SortIcon columnId={id} />
			<Layout>
				<Text black truncate semiBold noTextSelect {...popoutProps} docsId={docsId}>
					{basicTableT(`${id}.title`)}
				</Text>
			</Layout>
		</Layout>
	)
}

const SortIcon = ({columnId}) => {
	const {sortDirection, sortField} = useBasicTableContext()
	if (columnId !== sortField) return null
	return <Icon name={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} size={14} />
}
