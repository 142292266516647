import {EnvironmentPicker, ListPicker} from 'common/components'
import {ModalWithNewForm} from 'components/form/index'
import {useFormData, useQueryParams, useRoleEnvs} from 'hooks'
import {Allowance} from 'models'
import {Input, TimePicker, FormRow} from '@eitje/form-fields-web'
import {NewFormInputNumber} from 'components/form/input_number'
import {navigateModal} from 'actions'
import {AllowanceWeekDaysPicker} from '../index/components'

export const NewAllowance = () => {
	const {environment_id, kind} = useQueryParams()

	const afterSubmit = ({resData}) => {
		const {environment_id, kind, id} = resData.item
		navigateModal(`/environments/${environment_id}/allowances/${kind}/${id}`, {replace: true})
	}
	return (
		<ModalWithNewForm
			onSubmit={data => Allowance.create({...data, active: true})}
			afterSubmit={afterSubmit}
			fieldProps={{tableName: 'allowances', required: true}}
			name="allowances.new"
			initialValues={{environment_id, kind}}
		>
			<Form />
		</ModalWithNewForm>
	)
}

const Form = () => {
	const {kind} = useFormData()
	return (
		<>
			<EnvironmentPicker />
			<ListPicker noSort required dropdown single field="kind" items={['regular', 'irregular']} />
			{kind == 'regular' && <Input field="name" />}
			{kind == 'irregular' && <AllowanceWeekDaysPicker />}
			{kind == 'irregular' && (
				<FormRow>
					<TimePicker field="start_time" />
					<TimePicker field="end_time" />
				</FormRow>
			)}
			{!!kind && <NewFormInputNumber max={5000} field="amount" />}
		</>
	)
}
