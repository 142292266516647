import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import {ftime} from 'helpers'

const PERIOD_AMOUNTS = {
	week: 6,
	month: 2,
}

const weekFormatter = period => {
	const weekStart = ftime(period.clone().startOf('week'), 'dayMonth')
	const weekEnd = ftime(period.clone().endOf('week'), 'dayMonth')
	const id = `${t('week')} ${period.format('W')}`
	const suffix = `(${weekStart} - ${weekEnd})`
	const label = `${id} ${suffix}`
	const value = period.format('GGGG-WW')

	return {id, label, value}
}

const monthFormatter = period => {
	const label = ftime(period, 'month')
	const value = period.format('YYYY-MM')

	return {id: label, label, value}
}

export const generatePeriodList = (periodType, startDate) => {
	const amount = PERIOD_AMOUNTS[periodType]

	const periodList = Array.from({length: amount}, (_, i) => {
		const firstPeriodDate = date(startDate).clone()
		const incrementedPeriodDate = firstPeriodDate.add(i, periodType).startOf(periodType)
		if (periodType === 'week') return weekFormatter(incrementedPeriodDate)
		if (periodType === 'month') return monthFormatter(incrementedPeriodDate)
	})

	return periodList
}
