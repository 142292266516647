import {HelpButton} from 'common/components'
import {NAMESPACE, KNOWLEDGE_DOCS} from 'cores/knowledge/constants' // using a direct import to prevent import error in prod

const {collection, write_article, attach_pdf, create_quiz, create_topic, activate_training} = KNOWLEDGE_DOCS

const introductionProps = {
	title: 'common.introduction',
	docsId: collection,
}

const articleDocsLinks = [
	{id: write_article, t: 'write_article'},
	{id: attach_pdf, t: 'attach_pdf'},
]

const topicDocsLinks = [
	{id: create_topic, t: 'create_topic'},
	{id: create_quiz, t: 'create_quiz'},
	{id: activate_training, t: 'activate_training'},
]

const getHelpButtonProps = (type, hideIntroduction) => {
	switch (type) {
		case 'topics':
			return {
				docsLinks: topicDocsLinks,
				introductionProps: !hideIntroduction && {
					...introductionProps,
					body: `${NAMESPACE}.topic_help_popout_body`,
				},
			}
		case 'articles':
			return {
				docsLinks: articleDocsLinks,
				introductionProps: !hideIntroduction && {
					...introductionProps,
					body: `${NAMESPACE}.article_help_popout_body`,
				},
			}
	}
}

export const KnowledgeHelpButton = ({type, hideIntroduction}) => {
	const props = getHelpButtonProps(type, hideIntroduction)
	return <HelpButton collection={collection} {...props} />
}
