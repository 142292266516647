import React, {useEffect} from 'react'
import {Routes, Redirect} from 'components/routing'
import {usePath} from 'actions/routing'
import {getIntegrationResources} from 'cores/integrations/index'
import {BaseConnection} from 'models/index'
import {SideMenu, Text, DefaultSideMenuItem, PageLayout} from 'common/components'
import NewIntegration from './new_integration'
import MyIntegrations from './my_integrations'
import {useRouteMatch} from 'react-router-dom'
import './styles/index.less'

const routes = [
	{
		name: 'new',
		component: NewIntegration,
	},
]

const Decider = ({isSub, ...props}) => (isSub ? <SubItem {...props} /> : <DefaultSideMenuItem {...props} />)

const SubItem = ({route}) => {
	const {name, items = []} = route
	return (
		<Text>
			{name} ({items.length})
		</Text>
	)
}

export const IndexPage = () => {
	useEffect(getIntegrationResources, [])
	const connections = BaseConnection.all()
	const groupedConnections = _.groupBy(connections, 'name')
	const connTypes = Object.keys(groupedConnections)

	let subRoutes = connTypes.map(c => ({
		name: c,
		path: groupedConnections[c][0].sanitized_type,
		component: MyIntegrations,
		items: groupedConnections[c],
	}))

	const errConnections = connections.filter(c => c.hasIssue())
	const errSubRoute = errConnections.length > 0 && {
		name: 'issues',
		component: MyIntegrations,
		items: errConnections,
	}

	subRoutes = [...subRoutes, errSubRoute].filter(Boolean)

	const myIntegrationsRoute = {
		name: 'my',
		component: MyIntegrations,
		items: connections,
		subRoutes,
	}

	const _routes = connections.length > 0 ? [myIntegrationsRoute, ...routes] : routes
	const match = useRouteMatch()
	const {path} = match
	const currentPath = usePath()

	if (connections.length == 0 && !currentPath.includes('new')) return <Redirect to="/integrations/new" />

	return (
		<PageLayout name="integrations">
			{connections.length > 0 && (
				<SideMenu basePath="/integrations" alwaysExpanded MenuItem={Decider} routes={_routes} name="integrations" type="page" path={path} />
			)}
			<Routes routes={_routes} path={path} />
		</PageLayout>
	)
}
