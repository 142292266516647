import {User} from 'models'
import {useTopicContext, getUserTrainingResult} from 'cores/knowledge'

export const useData = () => {
	const {resources, team_ids, user_answers, started, finished, trainable} = useTopicContext()
	const users = User.where({team_ids})

	if (!trainable || !resources.length) return []

	return users.map(({id, full_name}) => {
		const {results, score, status, completed} = getUserTrainingResult(id, resources, user_answers, started, finished)

		const infos = results.filter(result => result.tableName === 'infos')
		const readInfos = infos.filter(info => info.read)

		return {
			id,
			full_name,
			status,
			score: completed ? score : null,
			readCount: readInfos.length,
			infoCount: infos.length,
		}
	})
}
